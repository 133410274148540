<template>
  <div>
    <b-modal id="new-lead-modal" ref="add-leads" title="Add Leads">
      <div v-for="(header, index) in headers" :key="index">
        <div v-if="header.key != 'date_added' && header.key != 'action_time'">
          {{ header.value }}

          <div style="display: flex; justify-content: space-between">
            <b-form-select
              :options="
                header.key == 'lead_source_id'
                  ? spreadsheet_list
                  : header.sub_values
              "
              v-if="
                header.key == 'lead_source_id' ||
                (header.sub_values && header.sub_values.length > 0)
              "
              text-field="text"
              value-field="value"
              v-model="header.new_value"
              :style="{ width: header.key === 'lead_source_id' ? '120%' : '' }"
            >
            </b-form-select>

            <a
              v-if="header.key == 'lead_source_id'"
              class="add-source-btn"
              v-b-modal.source-modal
              ><SvgPlusCircle style="margin: 0px 0px"></SvgPlusCircle
            ></a>
          </div>
          <b-form-input
            v-model="header.new_value"
            v-if="header.key != 'lead_source_id' && !header.sub_values"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex new-lead-modal__footer">
          <b-button variant="secondary" size="sm" @click="hideModal">
            Cancel
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="addLead"
            :disabled="!validateNewLead"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>

    <AddSourceModal @get-sources="getSources"></AddSourceModal>
  </div>
</template>

<script>
import axios from "@/utils/api";
import { fetchSources } from "@/utils/ajax";
import AddSourceModal from "./AddSourceModal.vue";
import SvgPlusCircle from "@/components/images/SvgPlusCircle.vue";

export default {
  name: "AddModal",
  props: ["headers", "sources"],
  data() {
    return {
      spreadsheet_list: [],
    };
  },

  components: { AddSourceModal, SvgPlusCircle },
  methods: {
    displayModal() {
      this.$refs["add-leads"].show();
    },
    hideModal() {
      this.$refs["add-leads"].hide();
    },
    addLead() {
      // var isValid = true;
      // this.headers.forEach((h) => {
      //   if (h["new_value"] && h["new_value"] != "") {
      //     isValid = false;
      //   }
      // });

      // if (!isValid) {
      //   this.$bvToast.toast("Please fill in all the blanks", {
      //     title: "Warning",
      //     autoHideDelay: 3000,
      //     variant: "warning",
      //   });
      //   return;
      // }
      axios({
        method: "post",
        url: "/table/create",
        data: {
          lead: this.headers,
        },
      }).then((response) => {
        if (!response.data.error) {
          this.hideModal();
        }
      });
    },

    getSources: function (force = false) {
      fetchSources(force)
        .then((data) => {
          if (Array.isArray(data)) {
            this.spreadsheet_list = [
              {
                text: "Please select a source",
                value: null,
              },
              ...data.map((item) => {
                if (
                  item.lead_source_name !== undefined &&
                  item.lead_source_id !== undefined
                ) {
                  return {
                    text: item.lead_source_name,
                    value: item.lead_source_id,
                  };
                } else {
                  console.error("Item structure is invalid:", item);
                  return {
                    text: "Unknown Text",
                    value: null,
                  };
                }
              }),
            ];
          } else {
            console.error("Invalid data format:", data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    this.getSources();
  },

  computed: {
    validateNewLead() {
      var isValid = true;
      // var mandatory_fields = ["email", "name", "phone", "lead_source_id"];
      var mandatory_fields = ["phone", "lead_source_id"];

      for (var i = 0; i < this.headers.length; i++) {
        if (
          (!this.headers[i]["new_value"] ||
            this.headers[i]["new_value"] == undefined ||
            this.headers[i]["new_value"] == "") &&
          mandatory_fields.includes(this.headers[i]["key"])
        ) {
          isValid = false;
          break;
        }
      }

      return isValid;
    },
  },

  watch: {
    sources(current) {
      if (current.length > 0) {
        var spreadsheet = [
          {
            text: "Please select a source",
            value: null,
          },
        ];

        for (var i = 0; i < current.length; i++) {
          spreadsheet.push({
            text: current[i].lead_source_name,
            value: current[i].lead_source_id,
          });
        }
        this.spreadsheet_list = spreadsheet;
      }
    },
  },
};
</script>
<style scoped>
.modalInput {
  margin: auto;
  text-align: left;
  margin-top: 20px;
}

.btn {
  width: 100%;
}

.new-lead-modal__footer {
  gap: 10px;
}

.add-source-btn {
  height: 35px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  padding-left: 4px;
  text-align: center;
  justify-content: center;
  width: 50px;
  margin-left: 5px;
}
</style>
