<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 10px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      "
    >
      <!-- <div
        @click="changeTab('automatic')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'automatic' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'automatic' ? 'white' : 'black' }"
          >Automatic</a
        >
      </div> -->

      <!-- <div
        @click="changeTab('sources')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'sources' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'sources' ? 'white' : 'black' }"
          >Sources</a
        >
      </div> -->

      <div
        @click="changeTab('integration')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'integration' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'integration' ? 'white' : 'black' }"
          >Integration</a
        >
      </div>

      <div
        @click="changeTab('history')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'history' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'history' ? 'white' : 'black' }"
          >History</a
        >
      </div>
    </div>

    <!-- <div v-if="activeTab === 'automatic'">
      <Automatic />
    </div>

    <div v-if="activeTab === 'sources'">
      <Sources />
    </div> -->

    <div v-if="activeTab === 'integration'">
      <Integration />
    </div>

    <div v-if="activeTab === 'history'">
      <History />
    </div>

    <!-- Other components go here -->
  </div>
</template>

<script>
// import Automatic from "./Distribution/Automatic";
import History from "./Distribution/History";
import Integration from "./Integration";
// import Sources from "./Sources";

export default {
  name: "Automation",
  components: {
    // Automatic,
    History,
    Integration,
    // Sources,
  },
  data() {
    return {
      activeTab: "integration",
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
</style>
