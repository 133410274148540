<template>
  <div class="text-center" style="max-width: 100%; overflow-x: hidden">
    <h2 class="h2">Raion Calendar</h2>
    <v-calendar
      class="custom-calendar"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <div
            class="box"
            variant="outline-dark"
            @click="clickDate(attributes, day)"
          >
            <span class="align-baseline">
              {{ day.day }}
            </span>
            <hr />
            <b-button
              v-for="(attribute, index) in attributes"
              :key="attribute.key"
              class="text-xs leading-tight rounded-sm p-0 mt-0 mb-0 meeting"
              v-show="index < 3"
              @click="(e) => clickMeeting(e, attribute)"
            >
              {{ attribute && 3 > index ? attribute.customData.title : "" }}
            </b-button>
            <br />
          </div>

          <b-modal
            ref="meeting-modal"
            class="meeting-modal"
            :title="selectedTitle"
            hide-footer
            centered
            scrollable
            size="xl"
          >
            <b-form-group
              label="Topic"
              label-for="meeting_topic"
              v-show="isNewEvent"
              v-model="eventForm.topic"
            >
              <b-input id="meeting_topic" v-model="topic" />
            </b-form-group>

            <b-form-group
              label="Zoom Link"
              label-for="meeting_url"
              v-show="!isNewEvent"
            >
              <b-input id="meeting_url" v-model="url" />
            </b-form-group>

            <b-form-group label="Meeting Date" label-for="meeting_date">
              <b-form-datepicker
                id="meeting_date"
                v-model="eventForm.date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Meeting Time" label-for="meeting_time">
              <b-form-timepicker
                id="meeting_time"
                v-model="eventForm.time"
                locale="en"
              ></b-form-timepicker>
            </b-form-group>

            <b-button-group
              style="display: flex; justify-conftent: space-between"
            >
              <b-button style="flex: 0" variant="danger">Delete</b-button>
              <div style="flex: 2; text-align: end">
                <b-button style="margin: 0 5px 0 5px" @click="closeMeeting()"
                  >Close</b-button
                >
                <b-button
                  style="margin: 0 5px 0 5px"
                  variant="success"
                  @click="postEvent()"
                  >Save</b-button
                >
              </div>
            </b-button-group>
          </b-modal>
          <b-modal
            ref="calendar-modal"
            class="calendar-modal"
            hide-footer
            :title="selectedDate"
            centered
            scrollable
            size="xl"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(attribute, index) in events"
                :key="index"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <p>
                    {{ attribute.customData.fullTitle }} <br />
                    {{ attribute.customData.timeStr }} <br /><a
                      :href="attribute.customData.url"
                      target="blank"
                      >{{ attribute.customData.url.substring(0, 50) }} ...
                    </a>
                  </p>
                  <b-button-group vertical style="display: inline-block">
                    <b-button @click="(e) => clickMeeting(e, attribute)">
                      Edit</b-button
                    ></b-button-group
                  >
                </div></b-list-group-item
              >
            </b-list-group>
            <br />
            <b-button-group style="display: block; text-align: end">
              <b-button style="margin: 5px" @click="closeCalendar()"
                >Close</b-button
              >
              <b-button
                style="margin: 5px"
                variant="success"
                @click="addEvent()"
                >Add New Event</b-button
              >
            </b-button-group>
          </b-modal>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import axios from "./../utils/api";

export default {
  data() {
    return {
      choice: [],
      choiceDate: "",
      selectedDate: "",
      selectedTitle: "",
      newEvent: "",
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      url: "",
      date: null,
      time: null,
      events: [],
      topic: "",
      isNewEvent: false,
      eventForm: { date: null, time: null, topic: "" },
    };
  },
  methods: {
    clickMeeting: function (event, attr) {
      this.selectedTitle = attr.customData.fullTitle;
      this.url = attr.customData.url;
      this.date = attr.customData.date;
      this.time = attr.customData.time;
      this.isNewEvent = false;
      this.$refs["meeting-modal"].show();
      event.stopPropagation();
    },
    clickDate: function (attr, day) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      this.events = attr;
      this.selectedDate = day.date.toLocaleDateString("en-US", options);
      this.$refs["calendar-modal"].show();
    },
    closeCalendar: function () {
      this.$refs["calendar-modal"].hide();
    },
    closeMeeting: function () {
      this.$refs["meeting-modal"].hide();
    },
    addEvent: function () {
      this.topic = "";
      this.date = null;
      this.time = null;
      this.isNewEvent = true;
      this.selectedTitle = "Add New Event";
      this.$refs["meeting-modal"].show();
    },
    postEvent: function () {
      axios({
        method: "post",
        url: "/zoom/create",
        data: this.eventForm,
      }).then((response) => {
        console.log(response);
      });
    },
    convertTZ: function (date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: tzString }
        )
      );
    },
  },
  computed: {
    // selectedDate() {
    //   return this.choiceDate;
    // },
  },
  mounted: function () {
    axios({
      method: "get",
      url: "/zoom/list",
    }).then((response) => {
      if (response.data.meetings) {
        response.data.meetings.map((m, index) => {
          const converted = this.convertTZ(m.startAt, "Asia/Singapore");
          var options = {
            hour: "2-digit",
            minute: "2-digit",
          };
          const minute =
            converted.getMinutes() == 0 ? "00" : converted.getMinutes();
          const time = converted.getHours() + ":" + minute;
          var timeStr = converted.toLocaleTimeString("en-US", options);

          this.attributes.push({
            key: index,
            customData: {
              title: m.topic.substring(0, 10) + "... " + time,
              fullTitle: m.topic,
              url: m.url,
              date: converted,
              time: time,
              timeStr: timeStr,
              class: "bg-red-600 text-dark",
            },
            dates: new Date(converted),
          });
        });

        this.attributes.sort((a, b) =>
          a.dates > b.dates ? 1 : b.dates > a.dates ? -1 : 0
        );
      }
    });
  },
};
</script>
