<template>
  <b-modal id="source-modal" centered title="Add New Source"
    ><b-input placeholder="Enter Source Name..." v-model="source"></b-input
    ><template #modal-footer>
      <b-button
        class="btn-primary"
        @click="addSource()"
        :disabled="source == '' || submitting"
        ><span v-show="!submitting">Add</span
        ><Spinner :show="submitting"></Spinner>
      </b-button>
      <b-button class="btn-plain" @click="$bvModal.hide('source-modal')"
        >Close</b-button
      >
    </template></b-modal
  >
</template>

<script>
import { postSource } from "@/utils/ajax";
import Spinner from "@/components/Spinner";

export default {
  name: "AddSourceModal",
  props: {},
  components: { Spinner },
  data: function () {
    return {
      source: "",
      submitting: false,
    };
  },
  methods: {
    addSource: function () {
      const { source } = this;
      this.submitting = true;

      postSource(source).then((res) => {
        if (res.status === 200) {
          this.submitting = false;
          this.source = "";
          this.$bvModal.hide("source-modal");
          this.$emit("get-sources", true);
        }
      });
    },
  },
  computed: {},
  created() {},
  mounted: function () {},
};
</script>
