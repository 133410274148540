<template>
  <div>
    <!-- For small screens (3 rows) -->
    <b-container class="container">
      <b-row>
        <b-col
          class="custom-col d-flex align-items-center"
          cols="5"
          v-if="!isGearIconClicked"
        >
          <div class="d-flex align-items-center w-100">
            <div class="profilepic">
              <img
                src="@/assets/whatsapp_pp.png"
                style="height: 50px; width: 80px"
              />
            </div>

            <div class="search-bar d-flex align-items-center w-100">
              <div class="d-flex align-items-center w-100">
                <b-icon icon="search" class="search-icon" />
                <b-form-input
                  class="searchinput"
                  style="width: 100%"
                  placeholder="Search or Start New Chat"
                  v-model="searchText"
                />
              </div>
            </div>

            <div
              class="sortingbut"
              v-if="!isGearIconClicked"
              style="margin-left: 10px; margin-right: 10px"
            >
              <a class="filterbar">
                <SvgFilterBar />
              </a>
            </div>

            <div style="margin-left: 10px; margin-right: 10px">
              <a @click="toggleGearIcon" v-if="!isGearIconClicked">
                <b-icon
                  icon="gear-wide-connected"
                  style="height: 25px; width: 25px; margin-top: 5px"
                />
              </a>
            </div>
          </div>
        </b-col>

        <b-col
          class="custom-col d-flex align-items-center"
          cols="5"
          v-if="isGearIconClicked"
          style="padding: 14px"
        >
          <div>
            <a @click="toggleBackIcon">
              <b-icon
                icon="arrow-left"
                style="height: 25.8px; width: 25px; margin-top: 6px"
              />
            </a>
          </div>

          <div class="textSettings">Settings</div>
        </b-col>

        <b-col class="custom-col" cols="7" v-if="!isGearIconClicked">
          <div>
            <div
              style="
                display: grid;
                grid-template-columns: 80px auto;
                align-items: center;
              "
            >
              <div>
                <img
                  src="@/assets/whatsapp_pp.png"
                  style="height: 50px; width: 80px; margin-left: 0px"
                />
              </div>

              <div>
                <!--Row 1-->
                <div class="user-details">
                  <div class="user-name">
                    {{ selectedUser ? selectedUser.name : "John Doe" }}
                  </div>
                  <div class="icons">
                    <b-icon icon="search" class="search-icon-chat" />
                    <b-icon
                      icon="chevron-right"
                      rotate="90"
                      @click="toggleModal"
                    />
                  </div>
                </div>

                <!--Row 2-->
                <div class="lastseen">
                  {{
                    selectedUser
                      ? `last seen ${selectedUser.lastSeen}`
                      : "last seen today at 3:45pm"
                  }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="customcolchat" cols="5" v-if="!isGearIconClicked">
          <div
            class="chat"
            v-for="(person, index) in chatData"
            :key="index"
            @click="handleChatClick(person)"
          >
            <div style="display: grid; grid-template-columns: 80px auto">
              <div>
                <img
                  src="@/assets/whatsapp_pp.png"
                  style="height: 50px; width: 80px; margin-left: 0px"
                />
              </div>

              <div>
                <!-- Row 1 -->
                <div class="user-details">
                  <div class="user-name">{{ person.name }}</div>
                  <div class="message-time">{{ person.latestChatTime }}</div>
                </div>

                <!-- Row 2 -->
                <div class="user-details">
                  <div style="display: flex">
                    <b-icon icon="check2-all" />
                    <div class="message-preview">
                      {{ person.latestMessage }}
                    </div>
                  </div>

                  <div
                    v-if="person.unreadMessages > 0"
                    class="unread-indicator"
                  >
                    {{ person.unreadMessages }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col class="customcolchat" cols="5" v-if="isGearIconClicked">
          <!-- Loop through chat data for 12 people -->
          <div class="chat">
            <div style="display: grid; grid-template-columns: 80px auto">
              <img
                src="@/assets/whatsapp_pp.png"
                style="height: 80px; width: 130px; margin-left: 0px"
              />

              <div style="margin-top: 20px; margin-left: 40px">
                <div class="user-name">Name</div>
                <div class="message-preview">Bio</div>
              </div>
            </div>
          </div>

          <div class="chat" @click="openAddAccount">
            <div style="padding: 9px">
              <div class="user-name">Add/Change Account</div>
            </div>
          </div>

          <div class="chat">
            <div style="padding: 9px">
              <div class="user-name">Edit Profile</div>
            </div>
          </div>

          <div class="chat">
            <div style="padding: 9px">
              <div class="user-name">Log Out</div>
            </div>
          </div>
        </b-col>

        <b-col
          class="custom-col"
          cols="7"
          v-if="!isGearIconClicked"
          style="position: relative"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="{
              'grid-container': true,
              sentcontainer: message.sent,
              receivedcontainer: !message.sent,
            }"
            style="display: grid"
          >
            <div
              :class="{
                'chat-message': true,
                sent: message.sent,
                received: !message.sent,
              }"
            >
              <div style="display: flex">
                {{ message.text }}
                <div style="display: flex">
                  <div class="timestamp">
                    {{ formatTime(message.timestamp) }}
                  </div>
                  <b-icon
                    icon="check2-all"
                    style="position: absolute; bottom: 5px; right: 5px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="fixed-message-input">
            <div class="message-input w-100">
              <div class="d-flex align-items-center">
                <b-icon icon="plus" class="plus-icon" />
              </div>

              <div class="input-group">
                <b-form-input
                  class="textinput"
                  placeholder="Type a message"
                  v-model="textMessage"
                />
                <b-icon icon="emoji-laughing" class="emoji-icon" />
              </div>

              <div class="d-flex align-items-center">
                <b-icon icon="mic-fill" class="mic-icon" />
              </div>
            </div>
          </div>
        </b-col>

        <b-col class="custom-col" cols="7" v-if="isGearIconClicked">
          <div v-if="isGearIconClicked">
            <AddAccount v-if="isAddAccountVisible" @close="closeAddAccount" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SvgFilterBar from "@/components/images/SvgFilterBar.vue";
import AddAccount from "./AddAccount";

export default {
  name: "Chat",
  components: {
    SvgFilterBar,
    AddAccount,
  },
  data() {
    return {
      searchText: "",
      textMessage: "",
      selectedUser: null,
      isGearIconClicked: false,
      isAddAccountVisible: false,
      chatData: [
        {
          name: "John Doe",
          latestChatTime: "5:44 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 1:20pm",
        },

        {
          name: "Ben Affleck",
          latestChatTime: "1:30 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 3:40pm",
        },

        {
          name: "Johny Sua",
          latestChatTime: "12:30 AM",
          latestMessage: "Sorry, when my item will be deliver? ",
          lastSeen: "today at 10:20am",
          unreadMessages: "2",
        },
        {
          name: "John Doe",
          latestChatTime: "5:44 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 1:20pm",
        },

        {
          name: "Ben Affleck",
          latestChatTime: "1:30 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 3:40pm",
        },

        {
          name: "Johny Sua",
          latestChatTime: "12:30 AM",
          latestMessage: "Sorry, when my item will be deliver? ",
          lastSeen: "today at 10:20am",
          unreadMessages: "2",
        },
        {
          name: "John Doe",
          latestChatTime: "5:44 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 1:20pm",
        },

        {
          name: "Ben Affleck",
          latestChatTime: "1:30 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 3:40pm",
        },

        {
          name: "Johny Sua",
          latestChatTime: "12:30 AM",
          latestMessage: "Sorry, when my item will be deliver? ",
          lastSeen: "today at 10:20am",
          unreadMessages: "2",
        },
        {
          name: "John Doe",
          latestChatTime: "5:44 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 1:20pm",
        },

        {
          name: "Ben Affleck",
          latestChatTime: "1:30 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 3:40pm",
        },

        {
          name: "Johny Sua",
          latestChatTime: "12:30 AM",
          latestMessage: "Sorry, when my item will be deliver? ",
          lastSeen: "today at 10:20am",
          unreadMessages: "2",
        },
        {
          name: "John Doe",
          latestChatTime: "5:44 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 1:20pm",
        },

        {
          name: "Ben Affleck",
          latestChatTime: "1:30 AM",
          latestMessage: "Hello, how are you doing today?",
          lastSeen: "today at 3:40pm",
        },

        {
          name: "Johny Sua",
          latestChatTime: "12:30 AM",
          latestMessage: "Sorry, when my item will be deliver? ",
          lastSeen: "today at 10:20am",
          unreadMessages: "2",
        },
        // Add data for 12 people here
      ],
      messages: [
        { text: "Hi there!", sent: true, timestamp: new Date() },
        { text: "Hello!", sent: false, timestamp: new Date() },
        { text: "How are you?", sent: true, timestamp: new Date() },
        // ... add more messages as needed
      ],
      isScreenSmall: false,
      isModalVisible: false,
    };
  },

  mounted: function () {
    window.addEventListener("resize", this.checkScreenSize);
    // Check screen size initially
    this.checkScreenSize();
  },

  computed: {},

  watch: {},

  methods: {
    checkScreenSize() {
      // Update isScreenSmall based on screen width
      this.isScreenSmall = window.innerWidth < 768;
    },

    handleChatClick(user) {
      this.selectedUser = user;
    },

    toggleGearIcon() {
      this.isGearIconClicked = true;
    },

    toggleBackIcon() {
      this.isGearIconClicked = false;
    },

    openAddAccount() {
      this.isAddAccountVisible = true;
    },

    closeAddAccount() {
      this.isAddAccountVisible = false;
    },

    isNewDay(currentTimestamp) {
      if (!this.lastTimestamp) {
        this.lastTimestamp = currentTimestamp;
        return true;
      }

      const timeDiff = currentTimestamp - this.lastTimestamp;
      const tenMinutesInMillis = 10 * 60 * 1000;

      if (timeDiff > tenMinutesInMillis) {
        this.lastTimestamp = currentTimestamp;
        return true;
      }

      return false;
    },
    formatDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(timestamp).toLocaleDateString(undefined, options);
    },
    formatTime(timestamp) {
      const options = { hour: "numeric", minute: "numeric" };
      return new Date(timestamp).toLocaleTimeString(undefined, options);
    },

    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

* {
  font-size: 14px;
}

.container {
  width: 100vw;
  max-width: 100%;
  margin-bottom: 10px;
}

.row {
  width: 100vw;
  max-width: 100%;
}

.page {
  margin-right: 0px;
}

.custom-col {
  border: 1px solid #ccc; /* Add a border to each column */
  padding: 7px; /* Add padding for spacing */
  border-radius: 2px; /* Add border radius for rounded corners */
}

.section {
  border-top: 1px solid #ccc; /* Add a top border */
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  padding: 7px; /* Add padding for spacing */
  border-radius: 0px; /* Add border radius for rounded corners (if needed) */
}

.custom-section {
  border-right: 1px solid #ccc;
}

.lastsection {
  border-top: 1px solid #ccc; /* Add a top border */
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  border-right: 1px solid #ccc; /* Add a bottom border */
  padding: 7px; /* Add padding for spacing */
  border-radius: 0px; /* Add border radius for rounded corners (if needed) */
}

.auto-expand {
  min-height: 30px; /* Set a minimum height to prevent the textarea from being too small */
  overflow: hidden; /* Hide the scrollbar */

  /* Ensure the textarea expands vertically as needed */
  transition: height 0.2s;
}

body {
  overflow-y: hidden;
}

.customcolchat {
  border-radius: 2px; /* Add border radius for rounded corners */
  overflow-y: auto; /* Change to 'scroll' if you always want the scrollbar to be visible */
  height: calc(
    100vh - 130px
  ); /* Adjust the height as needed; 160px is an example */
  border-right: 0.5px solid #cdcccc;
  border-left: 0.5px solid #cdcccc;
  padding: 0px 0px;
}

.userchat {
  overflow-y: auto; /* Change to 'scroll' if you always want the scrollbar to be visible */
  height: calc(
    100vh - 120px
  ); /* Adjust the height as needed; 160px is an example */
  border-right: 0.5px solid #cdcccc;
}

.chat {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
  border-bottom: 1px solid #ccc;
}

.user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchinput {
  height: 32px;
  width: 100%;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  outline: none;
  background-size: 20px 20px; /* Adjust the size of the icon */
  background-position: 10px center; /* Adjust the position of the icon */
  padding-left: 40px; /* Adjust the padding to create space for the icon */
  font-size: 13px;
  margin-right: 10px;
}

.search-bar {
  gap: 10px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left position as needed */
  transform: translate(0, -50%);
}

.search-icon-chat {
  margin-right: 25px;
}

.headerchat {
  border-top: 0.5px solid #7e7e7e;
  border-bottom: 0.5px solid #7e7e7e;
  padding: 9px;
  padding-right: 10px;
  width: 100%;
}

.fixed-message-input {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // z-index: 1000;
  background-color: #fff;
  padding: 10px 10px;
  border-top: 0.5px solid #bfbfbf;
  border-bottom: 0.5px solid #bfbfbf;
}

.user-name {
  font-weight: bold;
  font-size: 16px;
}

.message-time {
  font-size: 13px;
  color: #999;
}

.message-preview {
  margin-left: 5px;
  font-size: 14px;
  color: #333;
}

.lastseen {
  font-size: 12px;
  color: #999;
}

.icons {
  display: flex;
}

.message-input {
  display: flex;
  align-items: center;
  width: 100%;
}

.textinput {
  flex-grow: 1;
  height: 32px;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  outline: none;
  background-size: 20px 20px;
  background-position: 10px center;
  padding-left: 40px;
  font-size: 13px;
  margin-right: 15px;
}

.emoji-icon,
.mic-icon,
.plus-icon {
  font-size: 18px;
  cursor: pointer;
}

.emoji-icon {
  position: absolute;
  left: 12px; /* Adjust the left position as needed */
  top: 50%;
  transform: translateY(-50%);
  color: #999; /* Adjust the color as needed */
  font-size: 18px; /* Adjust the font size as needed */
}

.plus-icon {
  margin-right: 15px;
}

.unread-indicator {
  background-color: #4ac384;
  color: white;
  border-radius: 100%;
  padding: 2px 7px; /* Adjust the padding based on your design */
  font-size: 12px; /* Adjust the font size based on your design */
}

.textSettings {
  margin-left: 20px;
  font-size: 20px;
}

.chat-container {
  display: flex;
  flex-direction: column;
}

.date-divider {
  text-align: center;
  margin: 10px 0;
  color: #888;
}

.chat-message {
  max-width: 70%;

  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  word-wrap: break-word;
  position: relative;
  color: black;
  display: flex;
}

.sent {
  background-color: #dcf7c5;
  padding-right: 70px;
}

.received {
  background-color: #f5f2f2;
  padding-right: 70px;
}

.timestamp {
  font-size: 0.6em;
  color: #888;
  position: absolute;
  bottom: 5px;
  right: 25px;
}

.sentcontainer {
  justify-items: flex-end;
  padding-right: 20px;
}

.receivedcontainer {
  justify-items: flex-start; /* Aligns received messages to the left */
  padding-left: 10px;
}
</style>
