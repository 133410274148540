<template>
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4038 17.3334H12.0962V18.6667H1.30769V5.00002H2.61538V3.66669H0V20H13.4038V17.3334Z"
      fill="#354154"
    />
    <path
      d="M3.92307 0V16H17V5.72388L11.3862 0H3.92307ZM15.6923 14.6667H5.23076V1.33333H9.48076V7.66667H15.6923V14.6667ZM15.6923 6.33333H10.7884V1.33333H10.8445L15.6923 6.27612V6.33333Z"
      fill="#354154"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgCopy",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
