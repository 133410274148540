<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8 4.11429V0.714286C12.7998 0.524912 12.7295 0.343357 12.6045 0.209449C12.4795 0.075542 12.3101 0.000217405 12.1333 0H3.86667C3.68992 0.000217405 3.52047 0.075542 3.39549 0.209449C3.27051 0.343357 3.2002 0.524912 3.2 0.714286V4.11429L0 6.56307V16H16V6.56307L12.8 4.11429ZM14.5893 6.88811L12.8 8.14143V5.51857L14.5893 6.88811ZM4.26667 1.14286H11.7333V8.88868L9.8278 10.2236L8.2752 9.36104L6.58307 10.2363L4.26667 8.72946V1.14286ZM8.25813 10.6389L14.9333 14.3474V14.8571H1.06667V14.3587L8.25813 10.6389ZM1.06667 7.98482L5.43813 10.8286L1.06667 13.0896V7.98482ZM10.9149 10.8277L14.9333 8.01239V13.06L10.9149 10.8277ZM3.2 5.51857V8.03572L1.4222 6.87911L3.2 5.51857Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgEmail",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
