<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9763 14.0642C16.9763 14.3522 16.9123 14.6483 16.7763 14.9363C16.6403 15.2243 16.4643 15.4963 16.2323 15.7523C15.8403 16.1843 15.4083 16.4963 14.9203 16.6963C14.4403 16.8963 13.9202 17.0003 13.3602 17.0003C12.5442 17.0003 11.6722 16.8083 10.7522 16.4163C9.83216 16.0243 8.91215 15.4963 8.00013 14.8323C7.08011 14.1602 6.2081 13.4162 5.37608 12.5922C4.55207 11.7602 3.80805 10.8882 3.14404 9.97617C2.48803 9.06415 1.96002 8.15213 1.57601 7.24812C1.192 6.3361 1 5.46408 1 4.63207C1 4.08806 1.096 3.56805 1.28801 3.08804C1.48001 2.60003 1.78401 2.15202 2.20802 1.75201C2.72003 1.248 3.28004 1 3.87205 1C4.09606 1 4.32006 1.048 4.52007 1.144C4.72807 1.24 4.91207 1.38401 5.05608 1.59201L6.91211 4.20806C7.05611 4.40806 7.16011 4.59207 7.23212 4.76807C7.30412 4.93607 7.34412 5.10408 7.34412 5.25608C7.34412 5.44808 7.28812 5.64009 7.17612 5.82409C7.07211 6.00809 6.92011 6.2001 6.72811 6.3921L6.1201 7.02411C6.03209 7.11211 5.99209 7.21612 5.99209 7.34412C5.99209 7.40812 6.00009 7.46412 6.01609 7.52812C6.04009 7.59212 6.06409 7.64012 6.0801 7.68812C6.2241 7.95213 6.4721 8.29614 6.82411 8.71214C7.18412 9.12815 7.56812 9.55216 7.98413 9.97617C8.41614 10.4002 8.83215 10.7922 9.25615 11.1522C9.67216 11.5042 10.0162 11.7442 10.2882 11.8882C10.3282 11.9042 10.3762 11.9282 10.4322 11.9522C10.4962 11.9762 10.5602 11.9842 10.6322 11.9842C10.7682 11.9842 10.8722 11.9362 10.9602 11.8482L11.5682 11.2482C11.7682 11.0482 11.9602 10.8962 12.1442 10.8002C12.3282 10.6882 12.5122 10.6322 12.7122 10.6322C12.8642 10.6322 13.0242 10.6642 13.2002 10.7362C13.3762 10.8082 13.5602 10.9122 13.7602 11.0482L16.4083 12.9282C16.6163 13.0722 16.7603 13.2402 16.8483 13.4402C16.9283 13.6402 16.9763 13.8402 16.9763 14.0642Z"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M14.1999 6.60013C14.1999 6.12012 13.8239 5.38411 13.2639 4.7841C12.7519 4.23209 12.0719 3.80008 11.3999 3.80008"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 6.6001C17 3.50405 14.496 1 11.3999 1"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgPhone",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
