import Vue from "vue";

import VueRouter from "vue-router";
import VCalendar from "v-calendar";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/assets/_custom.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/scss/bootstrap";

import Cookies from "js-cookie";

import App from "@/App.vue";
import { routes } from "@/utils/routes";
import store from "@/utils/store";
import "./registerServiceWorker";
import VueSmoothScroll from "vue2-smooth-scroll";

Vue.use(VueSmoothScroll);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VCalendar, {
  componentPrefix: "v",
});

import ElementUI from "element-ui";
import { ElementTiptapPlugin } from "element-tiptap";
import "element-tiptap/lib/index.css";

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
  // lang: "zh",
  // spellcheck: false,
});

Vue.config.productionTip = false;

export const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const jwtToken = Cookies.get("jwtToken");
  const user_type = Cookies.get("user_type");
  var path = [];

  routes.forEach((r) => path.push(r.name));

  if (!path.includes(to.name))
    next({ name: "Dashboard", query: { unauthorised: true } });
  else if (to.name !== "Login" && !jwtToken) {
    var vm = new Vue();
    vm.$bvToast.toast("Please sign in to proceed", {
      title: "Unauthorised",
      autoHideDelay: 3000,
      variant: "danger",
    });
    next({ name: "Login" });
  } else if (
    (to.name === "Request" || to.name === "Automatic") &&
    user_type == "agent"
  )
    next({ name: "Dashboard", query: { unauthorised: true } });
  else next();
});

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
