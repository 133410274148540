<template>
  <b-modal
    ref="mapping-modal"
    class="mapping-modal"
    title="Excel Field Mapping"
    centered
    scrollable
    size="xl"
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #default>
      <div class="mapping-modal-container">
        <b-form-checkbox-group
          class="checkbox-group"
          v-model="checkboxes"
          :options="checkbox_option"
          stacked
        >
        </b-form-checkbox-group>
        <div class="right-container">
          <div
            v-for="(item, index) of mapping_headers"
            :key="index"
            style="display: flex; margin: 10px"
          >
            <p class="mapping-middle">
              <i class="fa fa-angle-double-right"></i>
            </p>
            <b-form-select
              class="mapping-right"
              v-model="mappings[index + 1]"
              :options="option"
              text-field="value"
              :state="mappings[index + 1] != undefined"
            ></b-form-select>
          </div>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <div class="w-100">
        <b-checkbox
          style="display: inline"
          @change="(e) => checkAll(e)"
          v-model="selectAll"
        ></b-checkbox>

        <b-button variant="secondary" size="sm" @click="hideModal">
          Cancel
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="removeCol"
          style="margin-left: 15px"
          :disabled="mapping_headers.length === 0"
        >
          Remove Selected Columns
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="confirmMapping"
          :disabled="!validateMapping"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from "./../../utils/api";
export default {
  name: "ImportExcelMapping",
  components: {},
  props: {
    display: Boolean,
    headers: Array,
    option: Array,
    source: String,
  },
  data() {
    return {
      keys: [],
      checkboxes: [],
      checkbox_option: [],
      mapping_headers: [],
      mappings: [],
      selectAll: false,
    };
  },
  methods: {
    removeCol: function () {
      if (this.mapping_headers.length === this.checkboxes.length) {
        this.checkbox_option = [];
        this.mapping_headers = [];
        this.checkboxes = [];
        this.selectAll = false;
        return;
      } else {
        var mapping_headers = [];
        for (var i = 0; i < this.mapping_headers.length; i++) {
          if (
            !this.checkboxes.includes(this.mapping_headers[i].toLowerCase())
          ) {
            mapping_headers.push(this.mapping_headers[i]);
          }
        }

        this.mapping_headers = mapping_headers;

        var checkbox_option = [];
        for (var j = 0; j < this.checkbox_option.length; j++) {
          if (
            !this.checkboxes.includes(
              this.checkbox_option[j].value.toLowerCase()
            )
          ) {
            checkbox_option.push(this.checkbox_option[j]);
          }
        }

        this.checkbox_option = checkbox_option;

        while (this.mapping_headers.length < this.mappings.length) {
          this.mappings.splice(this.mappings.length - 1, 1);
        }

        this.checkboxes = [];
        this.selectAll = false;
      }
    },
    hideModal: function () {
      this.$emit("closeModal");
    },
    confirmMapping: function () {
      this.$emit("closeModal");

      var mapping = this.mappings.slice(1); // prefered immutable way
      var diff = mapping.length - this.mapping_headers.length;

      for (var j = 0; j < diff; j++) {
        mapping.splice(mapping.length - 1, 1);
      }

      axios({
        method: "post",
        url: "/upload/upload_data",
        data: {
          spreadsheets_field: mapping,
          settings_field: this.mapping_headers,
          filename: this.source,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.$emit("refreshModal");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    hasDuplicates: function (arr) {
      return new Set(arr).size !== arr.length;
    },
    checkAll: function (input) {
      this.mapping_headers.forEach((e) => {
        if (input) {
          this.checkboxes.push(e.toLowerCase());
        } else {
          this.checkboxes = [];
        }
      });
    },
  },
  watch: {
    display(current) {
      if (current) {
        this.keys = [];
        this.checkboxes = [];
        this.checkbox_option = [];
        this.mapping_headers = [];
        this.mappings = [];
        this.selectAll = false;
        this.$refs["mapping-modal"].show();
      } else {
        this.$refs["mapping-modal"].hide();
      }
    },

    headers(current) {
      if (current.length > 0) {
        this.mapping_headers = this.headers.filter((e) => e.state != 0);

        this.mapping_headers.forEach((e) => {
          this.checkbox_option.push({ text: e, value: e.toLowerCase() });
        });
      }
    },

    option(current) {
      if (current.length > 0) {
        this.option.forEach((e) => {
          this.mappings[e.column] = e.value;
        });
      }
    },
  },
  mounted: function () {},
  computed: {
    validateMapping() {
      if (this.mapping_headers.length === 0) {
        return false;
      }

      for (var i = 1; i < this.mappings.length; i++) {
        if (this.mappings[i] === undefined || this.mappings[i] == "") {
          return false;
        }

        if (this.hasDuplicates(this.mappings)) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>
