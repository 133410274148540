<template>
  <div class="dashboard">
    <div class="dashboard-form mx-4 position-relative mt-2">
      <h6 class="position-absolute bg-white px-4">
        Performance Report Builder
      </h6>
      <div class="dashboard-form-column pt-4 d-flex">
        <span class="px-3 my-auto">From</span>
        <b-form-datepicker
          v-model="from_date"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
        ></b-form-datepicker>
        <span class="px-3 my-auto">To</span>
        <b-form-datepicker
          v-model="to_date"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
        ></b-form-datepicker>
      </div>
      <div class="dashboard-form-column d-flex">
        <b-form-select
          v-model="lead_source_selected"
          :options="lead_source_option"
          @change="addLeadSource"
          text-field="name"
          value-field="id"
        ></b-form-select>
        <b-button
          v-for="(source, i) in lead_source_array"
          :key="'lead_source_' + i"
          v-show="source != null"
          @click="removeLeadSource(source)"
        >
          <span v-show="source != null">
            {{ source.name }}
            <i class="fa fa-times"></i>
          </span>
        </b-button>
      </div>
      <div class="dashboard-form-column d-flex">
        <b-form-select
          v-model="member_selected"
          :options="member_option"
          @change="addMember"
          text-field="name"
          value-field="id"
        ></b-form-select>
        <b-button
          v-for="(member, i) in member_array"
          :key="'member_' + i"
          v-show="member != null"
          @click="removeMember(member)"
        >
          <span v-show="member != null">
            {{ member.name }}
            <i class="fa fa-times"></i>
          </span>
        </b-button>
      </div>
      <div class="dashboard-form-column d-flex">
        <b-form-select
          v-model="status_selected"
          :options="status_option"
          @change="addStatus"
        ></b-form-select>
        <b-button
          v-for="(status, i) in status_series"
          :key="'status_' + i"
          v-show="status != null"
          @click="removeStatus(status)"
        >
          <span v-show="status != null">
            {{ status }}
            <i class="fa fa-times"></i>
          </span>
        </b-button>
      </div>
      <div class="dashboard-form-column">
        <b-button @click="generateReport" class="mb-3" :disabled="validate"
          >Generate Report</b-button
        >
      </div>
    </div>

    <div class="d-flex justify-content-between p-4" ref="summary-container">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="d-flex px-2 py-3 rounded justify-content-around summary-card"
        :style="{ border: `1.5px solid` + card['color'] }"
      >
        <h4 class="m-auto">{{ card["header"] }}</h4>
        <div :style="{ border: `1.5px solid` + card['color'] }"></div>
        <p class="m-auto">{{ card["count"] }}</p>
      </div>
    </div>

    <div id="chart" class="mx-4"></div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import axios from "./../utils/api";

export default {
  name: "PerformanceReport",
  data: function () {
    return {
      lead_source_option: [{ id: null, name: "Please select a lead source" }],
      member_option: [{ id: null, name: "Please select a member" }],
      status_option: [{ value: null, text: "Please select a status" }],
      lead_source_selected: null,
      member_selected: null,
      status_selected: null,
      lead_source_series: [],
      member_series: [],
      status_series: [],
      lead_source_array: [],
      member_array: [],
      categories: [],
      series: [],
      generateGraph: false,
      frequency_selected: null,
      from_date: null,
      to_date: null,
      cards: [],
      colors: ["#008FFB", "#00E396", "#FEB019"],
      frequency_option: [
        { value: null, name: "Please select an option" },
        { value: "day", name: "Daily", disabled: false },
        { value: "month", name: "Monthly" },
      ],
      chartOptions: {
        series: [],
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
        },
        xaxis: {
          categories: [],
          type: "datetime",
        },
        yaxis: {
          title: {
            text: "Leads",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " lead counts";
            },
          },
        },
      },
    };
  },
  methods: {
    addLeadSource: function () {
      if (
        (this.member_series.length > 1 || this.status_series.length > 1) &&
        this.lead_source_series.length === 1
      ) {
        this.lead_source_series = [];
        this.member_array = [];
      }
      if (!this.lead_source_series.includes(this.lead_source_selected)) {
        var obj = this.lead_source_option.find(
          (element) => element.id === this.lead_source_selected
        );

        if (
          this.lead_source_series.length == 3 &&
          this.lead_source_array.length == 3
        ) {
          this.lead_source_series.pop();
          this.lead_source_array.pop();
        }
        this.lead_source_series.push(this.lead_source_selected);
        this.lead_source_array.push(obj);
      }
      this.lead_source_selected = null;
    },
    addMember: function () {
      if (
        (this.lead_source_series.length > 1 || this.status_series.length > 1) &&
        this.member_series.length === 1
      ) {
        this.member_series = [];
        this.member_array = [];
      }
      if (!this.member_series.includes(this.member_selected)) {
        var obj = this.member_option.find(
          (element) => element.id === this.member_selected
        );

        if (this.member_series.length == 3 && this.member_array.length == 3) {
          this.member_series.pop();
          this.member_array.pop();
        }

        this.member_series.push(this.member_selected);
        this.member_array.push(obj);
      }
      this.member_selected = null;
    },
    addStatus: function () {
      if (
        (this.member_series.length > 1 || this.lead_source_series.length > 1) &&
        this.status_series.length === 1
      ) {
        this.status_series = [];
      }
      if (!this.status_series.includes(this.status_selected)) {
        if (this.status_series.length == 3) {
          this.status_series.pop();
        }

        this.status_series.push(this.status_selected);
      }
      this.status_selected = null;
    },
    removeLeadSource: function (value) {
      this.lead_source_series = this.lead_source_series.filter(function (v) {
        return v != value.id;
      });
      this.lead_source_array = this.lead_source_array.filter(function (v) {
        return v.id != value.id;
      });
    },
    removeMember: function (value) {
      this.member_series = this.member_series.filter(function (v) {
        return v != value.id;
      });
      this.member_array = this.member_array.filter(function (v) {
        return v.id != value.id;
      });
    },
    removeStatus: function (value) {
      this.status_series = this.status_series.filter(function (v) {
        return v != value;
      });
    },
    // getDays: function (month, year) {
    //   return new Array(31)
    //     .fill("")
    //     .map((v, i) => new Date(year, month - 1, i + 1))
    //     .filter((v) => v.getMonth() === month - 1);
    // },
    getDays: function (s, e) {
      for (
        var a = [], d = new Date(s);
        d <= new Date(e);
        d.setDate(d.getDate() + 1)
      ) {
        a.push(new Date(d));
      }
      return a;
    },
    formatDate: function (date) {
      return [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
    },
    generateReport: function () {
      axios({
        method: "post",
        url: "/dashboard/performance",
        data: {
          sources: this.lead_source_series,
          members: this.member_series,
          status: this.status_series,
          from: this.from_date,
          to: this.to_date,
        },
      }).then((response) => {
        const leads = response.data.data;
        const total = response.data.total;

        var cards = [{ header: "Total", count: total, color: "#000000" }];
        var temp_frequency = {};
        // var days = [];
        var chart = document.querySelector("#chart");
        while (chart.firstChild) chart.removeChild(chart.firstChild);

        let from_date = new Date(this.from_date);
        let to_date = new Date(this.to_date);

        if (from_date.getMonth() === to_date.getMonth()) {
          var total_days = this.getDays(this.from_date, this.to_date);

          for (var day_index = 0; day_index < total_days.length; day_index++) {
            temp_frequency[this.formatDate(total_days[day_index])] = [];
          }

          for (let i = 0; i < leads.length; i++) {
            const lead = leads[i];
            let date = new Date(lead.distributed_dt.replace(/-/g, "/"));
            temp_frequency[this.formatDate(date)].push(lead);
          }

          this.chartOptions = {
            ...this.chartOptions,
            ...{ chart: { type: "area", height: 350 } },
            // ...{ labels: lead_status_label },
          };
        } else {
          this.chartOptions = {
            ...this.chartOptions,
            ...{ chart: { type: "bar", height: 350 } },
            // ...{ labels: lead_status_label },
          };
          for (let i = 0; i < leads.length; i++) {
            const lead = leads[i];
            let date = new Date(lead.distributed_dt.replace(/-/g, "/"));
            var shortMonthName = new Intl.DateTimeFormat("en-US", {
              month: "short",
            }).format;
            let shortName = shortMonthName(date);

            if (shortName != "") {
              if (!(shortName in temp_frequency)) {
                temp_frequency[shortName] = [lead];
              } else {
                temp_frequency[shortName].push(lead);
              }
            }
          }
        }

        var series = [];
        var frequency = [];
        var selected_series = null;
        var series_map = {};
        var field = "";

        if (this.lead_source_series.length > 1) {
          selected_series = this.lead_source_series;
          field = "lead_source_id";
        } else if (this.member_series.length > 1) {
          selected_series = this.member_series;
          field = "member";
        } else {
          selected_series = this.status_series;
          field = "status";
        }

        for (var x = 0; x < selected_series.length; x++) {
          if (field === "status") {
            series_map[selected_series[x]] = 0;
            series.push({ name: selected_series[x], data: [] });
          } else if (field === "lead_source_id") {
            var obj = this.lead_source_option.find(
              (element) => element.id === selected_series[x]
            );
            series_map[obj.name] = 0;
            series.push({ name: obj.name, data: [] });
          } else {
            console.log("tet");
          }
        }

        for (const [fre, leads] of Object.entries(temp_frequency)) {
          for (var l = 0; l < leads.length; l++) {
            if (field === "status") {
              series_map[leads[l].status]++;
            } else if (field === "lead_source_id") {
              var source = this.lead_source_option.find(
                (element) => element.id == leads[l].lead_source_id
              );
              if (l == 0) {
                series_map[source.name] = 0;
              }
              series_map[source.name]++;
            } else {
              console.log("test");
            }
          }
          for (var s = 0; s < series.length; s++) {
            Object.entries(series_map).forEach(([field, count]) => {
              if (field === series[s]["name"]) {
                series[s]["data"].push(count);
                count = 0;
                series_map[field] = 0;
              }
            });
          }
          frequency.push(fre);
        }

        for (var _s = 0; _s < series.length; _s++) {
          cards.push({
            header: series[_s]["name"],
            count: series[_s]["data"].reduce((acc, val) => {
              return acc + val;
            }, 0),
            color: this.colors[_s],
          });
        }

        console.log(JSON.stringify(series));

        this.chartOptions = {
          ...this.chartOptions,
          ...{ xaxis: { categories: frequency } },
          ...{ series: series },
          // ...{ labels: lead_status_label },
        };

        var _chart = new ApexCharts(
          document.querySelector("#chart"),
          this.chartOptions
        );

        _chart.render();

        this.cards = cards;

        const myEl = this.$refs["summary-container"];

        this.$smoothScroll({
          scrollTo: myEl,
        });
      });
    },
  },
  computed: {
    validate() {
      return (
        this.from_date == null ||
        this.to_date == null ||
        this.lead_source_series.length == 0 ||
        this.member_series.length == 0 ||
        this.status_series == 0
      );
    },
  },
  mounted: function () {
    axios({
      method: "get",
      url: "/dashboard/performance",
    }).then((response) => {
      this.member_option = this.member_option.concat(response.data.agents);
      this.lead_source_option = this.lead_source_option.concat(
        response.data.sources
      );
      this.status_option = this.status_option.concat(response.data.status);
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #efefef;

  .dashboard-form-column {
    margin: 10px 0px;

    @media screen and (min-width: 768px) {
      select {
        max-width: 25%;
      }

      .b-form-datepicker {
        max-width: 25%;
      }
    }

    button {
      width: 80%;
      font-size: 14px;
      max-width: 200px;
      margin-left: 10px;
    }
  }

  h6 {
    top: -10px;
  }
}

.fa .fa-times {
  margin: auto;
  pointer-events: all;
}

.summary-card {
  min-width: 200px;

  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
  }
}
</style>
