export const setCache = (key, value, hour = 1) => {
  try {
    let expiry = new Date();
    expiry.setHours(expiry.getHours() + hour);
    value["expiry"] = expiry.getTime();

    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error set cache:", error);
    throw error;
  }
};

export const validateCache = (key, expiry) => {
  try {
    var now = new Date();

    if (now.getTime() > expiry) {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.error("Error validate cache:", error);
    throw error;
  }
};
