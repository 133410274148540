<template>
  <div class="settings my-4 mx-1">
    <div class="title">
      <h2 class="texttable">Table Settings</h2>
      <h2 class="textremember">
        Remember to save your settings before exiting!
      </h2>
    </div>

    <draggable v-model="settings">
      <transition-group>
        <b-row
          v-for="(setting, setting_index) in settings"
          :key="setting_index"
          class="py-2"
        >
          <div v-for="setting in settings" :key="setting.settings_id"></div>

          <div>
            <!-- Button and SVG -->
            <div class="input-container" v-if="setting.value != null">
              <!-- Input and delete button -->
              <div class="position-relative">
                <input
                  class="boxsetvalue selectForm"
                  v-model="setting.value"
                  :readonly="
                    [
                      'name',
                      'email',
                      'phone',
                      'lead_source_id',
                      'status',
                      'action_time',
                      'date_added',
                      'last_distributed_dt',
                      'last_distributed',
                    ].includes(setting.key)
                  "
                  :class="{
                    'readonly-input': [
                      'name',
                      'email',
                      'phone',
                      'lead_source_id',
                      'status',
                      'action_time',
                      'date_added',
                      'last_distributed_dt',
                      'last_distributed',
                    ].includes(setting.key),
                  }"
                />

                <b-button
                  class="delete-btn"
                  @click="showDeleteModal(setting_index)"
                  v-show="
                    setting.key != 'name' &&
                    setting.key != 'email' &&
                    setting.key != 'phone' &&
                    setting.key != 'lead_source_id' &&
                    setting.key != 'status' &&
                    setting.key != 'action_time' &&
                    setting.key != 'date_added' &&
                    setting.key != 'last_distributed_dt' &&
                    setting.key != 'last_distributed'
                  "
                >
                  <b-icon icon="X" />
                </b-button>

                <a
                  href="#"
                  class="box-btn"
                  @click="(e) => reorderSetting('down', e, setting.column)"
                  ><SvgLogoBox
                /></a>
              </div>

              <!-- More button with SVG -->
              <a
                href="#"
                class="moreButton"
                v-on:click="moreSettings(setting_index)"
                :class="{ invisible: setting.data_type !== '6' }"
                ><SvgPencil
              /></a>

              <b-button
                class="btn-visible"
                :class="{ 'red-background': !setting.state }"
                type="button"
                v-model="setting.state"
                @click="setting.state = !setting.state"
              >
                <SvgEyes />
              </b-button>
            </div>
          </div>

          <!--dropdown setting-->
          <b-modal
            :id="'dropdown_' + setting_index"
            hide-footer
            hide-header
            centered
            scrollable
            title="Edit DropDown"
            v-if="setting.data_type == '6'"
            size="sm"
          >
            <div>
              <h4 class="textmodalfield">Edit DropDown: {{ setting.value }}</h4>
              <div
                v-for="(attr, index) in setting.sub_values"
                :key="index"
                class="d-flex"
              >
                <input class="modalText" v-model="attr.data_value" disabled />
                <button
                  class="button--default redcolor"
                  variant="danger"
                  @click="removeOption(setting_index, index)"
                >
                  <b-icon icon="dash-lg" />
                </button>
              </div>

              <div class="d-flex">
                <input
                  class="modalText"
                  placeholder="New Field"
                  v-model="new_option"
                />
                <button
                  class="button--default greencolor"
                  v-on:click="addOption(setting_index)"
                >
                  <b-icon icon="plus-lg" />
                </button>
              </div>
              <div class="text-center">
                <b-button
                  variant="success"
                  class="buttondone"
                  @click="closeMoreSettings(setting_index)"
                  >Done</b-button
                >
              </div>
            </div>
          </b-modal>

          <b-modal
            :id="'delete_modal_' + setting_index"
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            centered
            scrollable
          >
            <h4 class="notesreminder1">Are you sure you want to remove?</h4>
            <h4 class="notesreminder2">
              Retrieving data is not possible after removal.
              <br />
              Kindly export your data if you still need it.
            </h4>

            <div class="mt-4 text-center">
              <b-button
                variant="success"
                class="buttonyes"
                @click="deleteSetting(setting_index)"
              >
                Yes
              </b-button>
              <b-button
                variant="secondary"
                class="buttoncancel"
                @click="$bvModal.hide('delete_modal_' + setting_index)"
              >
                Cancel
              </b-button>
            </div>
          </b-modal>
        </b-row>
      </transition-group>
    </draggable>

    <b-modal
      hide-header
      hide-footer
      centered
      scrollable
      title="Add New Field"
      id="new-field"
    >
      <h4 class="textmodalfield">New Field Name:</h4>
      <div>
        <b-form-input class="NewFieldForm" v-model="new_field.name" />
      </div>

      <div class="formposition">
        <h4 class="textmodalfield mr-2">Type:</h4>
        <!-- Form select -->
        <b-form-select
          class="TypeModalDropdown"
          :options="[
            { value: '', text: 'Please select an option' },
            { value: '1', text: 'Text Field' },
            { value: '6', text: 'Drop Down' },
          ]"
          v-model="new_field.type"
        ></b-form-select>
      </div>

      <div class="mt-4 text-center">
        <b-button variant="primary" class="saveNewField" v-on:click="addRow"
          >Save</b-button
        >
      </div>
    </b-modal>
    <div class="d-flex flex-column">
      <b-button variant="primary" class="addField" v-b-modal.new-field>
        Add Field
      </b-button>

      <b-button
        variant="primary"
        class="saveSettings"
        v-on:click="handleSave"
        :disabled="!validate"
      >
        Save Settings
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from "../utils/api";
import SvgPencil from "@/components/images/SvgPencil.vue";
import SvgLogoBox from "@/components/images/SvgLogoBox.vue";
import SvgEyes from "@/components/images/SvgEyes.vue";
import draggable from "vuedraggable";

export default {
  name: "Settings",
  components: {
    SvgPencil,
    SvgLogoBox,
    SvgEyes,
    draggable,
  },
  data: function () {
    return {
      settings: [],
      orderlist: [],
      new_option: null,
      isUp: true,
      isButtonClicked: false, // Add this property
      new_field: {
        name: "",
        type: "",
      },
    };
  },
  methods: {
    addRow: function () {
      const { new_field } = this;

      this.settings.push({
        column: this.settings.length + 1,
        new_value: new_field.name,
        value: new_field.name,
        state: 1,
        var_type: 1,
        key: null,
        sub_values: [],
        data_type: new_field.type,
      });

      console.log(this.settings);

      this.$bvModal.hide("new-field");
    },

    toggleSettingState() {
      this.setting.state = this.setting.state === 0 ? 1 : 0;
    },

    showDeleteModal(index) {
      if ("settings_id" in this.settings[index]) {
        this.$bvModal.show("delete_modal_" + index);
      } else {
        this.settings.splice(index, 1);
      }
    },
    deleteSetting: function (index) {
      if (this.settings[index].key) {
        axios({
          method: "delete",
          url: "/table_settings/delete",
          data: {
            setting_id: this.settings[index].settings_id,
          },
        });
      }
      this.settings.splice(index, 1);
    },

    handleSave: function () {
      // Destructure settings from data
      let { settings } = this;

      // Loop through each setting
      for (const i in settings) {
        // Set the 'column' property based on the index
        settings[i]["column"] = parseInt(i) + 1;

        // Assuming there is an input field with v-model="setting.value"
        const originalValue = settings[i]["value"];
        settings[i]["new_value"] = originalValue;
      }

      // Validate the settings
      const valid = this.validate();

      // If the settings are valid, send a request to update settings
      if (valid) {
        axios({
          method: "post",
          url: "/table_settings/update_settings",
          data: {
            settings: this.settings,
          },
        }).then((response) => {
          // If the request is successful, reload the settings
          if (response.status === 200) {
            this.loadSetting();
          }
        });
      }
    },

    moreSettings: function (idx) {
      this.$bvModal.show("dropdown_" + idx);
    },
    addOption(index) {
      this.settings[index].sub_values.push({ data_value: this.new_option });
      this.new_option = null;
    },
    // removeOption(setting_index, index) {
    //   this.settings[setting_index].sub_values.splice(index, 1);
    // },
    removeOption(index, subvalue_index) {
      const subvalue = this.settings[index].sub_values[subvalue_index];

      if (!("sub_value_id" in subvalue)) {
        this.settings[index].sub_values.splice(subvalue_index, 1);
        return;
      }
      axios({
        method: "delete",
        url: "/table_settings/delete/option",
        data: {
          subvalue_id: subvalue.sub_value_id,
        },
      }).then((response) => {
        if (response.status == 200) {
          this.settings[index].sub_values.splice(subvalue_index, 1);
        }
      });
    },
    closeMoreSettings(setting_index) {
      this.$bvModal.hide("dropdown_" + setting_index);
    },
    validate() {
      var settings = this.settings;
      var existing = [];

      for (var i = 0; i < settings.length; i++) {
        if (existing.includes(settings[i].column)) {
          this.$bvToast.toast("Duplicate Order", {
            title: "Warning",
            autoHideDelay: 3000,
            variant: "warning",
          });
          return false;
        } else {
          existing.push(settings[i].column);
        }

        if (
          !settings[i].settings_id &&
          (settings[i].data_type == "" || settings[i].new_value == "")
        ) {
          this.$bvToast.toast("Please fill all the information", {
            title: "Warning",
            autoHideDelay: 3000,
            variant: "warning",
          });
          return false;
        }
      }
      return true;
    },

    loadSetting: function () {
      axios({
        method: "get",
        url: "/table_settings/settings",
      }).then((response) => {
        const { settings } = response.data;
        for (var i = 0; i < settings.length; i++) {
          settings[i]["new_value"] = "";
          settings[i]["state"] = !!+settings[i]["state"];

          if (settings[i].column > settings.length) {
            settings[i].column = null;
          }
        }

        // sort setting by column order
        settings.sort(function (a, b) {
          return a.column - b.column;
        });

        this.settings = settings;
      });
    },
  },
  beforeMount() {
    //fetch columns information here
    this.loadSetting();
  },

  watch: {
    "setting.state": function (newValue) {
      console.log("New setting.state:", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.settings {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  width: 100%;
  margin: 0px 0px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.texttable {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.textremember {
  font-size: 12px;
  font-weight: bold;
  color: #eb3e42;
}

.moreButton {
  text-decoration: none;
  padding: 6px;
  border-radius: 5px;
  background: #0095b5;
  margin-left: 10px;
  height: 35px;
  width: 35px;
}

.btn-visible {
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  background: #0e930e;
  margin-left: 10px;
  height: 35px;
  width: 35px;
}

.box-btn {
  padding: 4px 6px;
  border-radius: 5px;
  background: transparent;
  margin-left: 2px;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &:hover {
    cursor: move;
  }
}

.modalText {
  width: 100%;
  height: 35px;
  background-color: #e3f4f8;
  border-radius: 4px;
  padding: 10px;
  border: 0px;
}
.modalTextdropdown {
  margin-top: 10px;
  width: 120%;
  height: 35px;
  background-color: #e3f4f8;
  border-radius: 4px;
  padding: 10px;
  border: 0px;
}

.greencolor {
  background-color: #0e930e;
}

.redcolor {
  background-color: #eb3e42;
}

.button--default {
  width: 40px;
  height: 35px;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  margin-left: 0px;
  padding: 0px 0px 0px;
  // padding-bottom: 2px;
  margin-top: 0px;
}

.buttondone {
  width: 100px;
  height: 38px;
  font-weight: 500;
  background-color: #fb6639;
  border: 0px;
}

.buttonyes {
  width: 100px;
  height: 38px;
  font-weight: 500;
  background-color: #0e930e;
  padding-top: 3px;
  border: 0px;
}

.buttoncancel {
  width: 100px;
  height: 38px;
  font-weight: 500;
  background-color: #eb3e42;
  padding-top: 3px;
  border: 0px;
  margin-left: 10px;
}

.delete-btn {
  background-color: transparent; /* Make the background transparent */
  border: 2px solid red; /* Add a red border */
  border-radius: 80%; /* Make the button circular */
  width: 20px; /* Adjust the width and height to your preference */
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s; /* Add a transition for hover effect */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.delete-btn:hover {
  background-color: rgb(
    245,
    197,
    197
  ); /* Change the background to red on hover */
}

.delete-btn .b-icon {
  color: $red; /* Set the color of the X icon to red */
}

.boxsetvalue {
  background-color: #e1f0f7;
  border-radius: 4px;
  padding: 10px;
  padding-left: 35px;
  padding-right: 20px;
  border: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 218px;

  &:hover {
    cursor: move;
  }
}

.addField {
  margin-top: 12px;
  height: 35px;
  width: 310px;
  background-color: #e1f0f7;
  color: #032743;
  font-size: 17px;
  border: 0px;
}

.saveSettings {
  margin-top: 5px;
  height: 35px;
  width: 310px;
  background-color: #fb6639;
  color: #ffff;
  font-size: 17px;
  border: 0px;
}

.formposition {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textmodalfield {
  font-size: 15px;
  font-weight: 600;
}

.saveNewField {
  margin-top: 5px;
  height: 35px;
  width: 100px;
  background-color: #fb6639;
  color: #ffff;
  font-size: 17px;
  font-weight: 600;
  border: 0px;
  padding: 2px;
}

.TypeModalDropdown {
  border-radius: 10px;
  border: 1px solid #7e7e7e;
}

.NewFieldForm {
  background-color: #e3f4f8;
  border: 0px;
  width: 100%;
}

.notesreminder1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.notesreminder2 {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #eb3e42;
}

.red-background {
  background-color: red;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.readonly-input {
  background-color: #e8edef;
}

.py-2 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
