<template>
  <b-modal
    id="whatsapp_blasting_result_modal"
    title="Message Sent Successfully"
    centered
    scrollable
    size="xl"
  >
    <div class="d-flex justify-content-around flex-wrap">
      <div
        v-for="contact in blast_contacts"
        :key="contact.number"
        @click="onSelectResponse(contact)"
      >
        <div
          :class="[{ active: contact.active }]"
          v-if="contact.status != 'Failed'"
          class="box box__green"
        ></div>
        <div
          :class="[{ active: contact.active }]"
          v-else
          class="box box__red"
        ></div>
      </div>
    </div>
    <div v-if="selected_number != '' && selected_status != ''">
      <p class="mt-4 text-center" v-if="selected_status != 'Failed'">
        Whatsapp message sent to
        <strong>{{ selected_number }}</strong> successfully
      </p>
      <p class="mt-4 text-center" v-else>
        Failed to send Whatsapp message to
        <strong>{{ selected_number }}</strong>
      </p>
    </div>
    <template #modal-footer>
      <b-button
        variant="secondary"
        size="sm"
        @click="$bvModal.hide('whatsapp_blasting_result_modal')"
        class="m-1"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "WhatsappBlastResultModal",
  props: {},
  data: function () {
    return {
      blast_contacts: [],
      selected_number: "",
      selected_status: "",
    };
  },
  methods: {
    onSelectResponse: function (contact) {
      const { blast_contacts } = this;
      const { number, status } = contact;

      this.selected_number = number;
      this.selected_status = status;

      for (const i in blast_contacts) {
        if (number === blast_contacts[i]["number"]) {
          this.blast_contacts[i]["active"] = true;
        } else {
          this.blast_contacts[i]["active"] = false;
        }
      }
    },
  },
  computed: {},
  created() {},
  mounted: function () {
    this.$root.$on("bv::modal::show", () => {
      let contacts = this.$store.getters.getBlastContacts;
      let _contacts = [];

      this.selected_number = "";
      this.selected_status = "";

      for (const i in contacts) {
        const key = Object.keys(contacts[i])[0];
        const value = contacts[i][key];

        _contacts.push({
          number: key,
          status: value,
          active: false,
        });
      }

      this.blast_contacts = _contacts;
    });
  },
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}

.box {
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &__red {
    background: red;
  }

  &__green {
    background: green;
  }

  &.active {
    opacity: 0.5;
  }
}
</style>
