<template>
  <a @click="viewDetails"><SvgInfo></SvgInfo></a>
</template>

<script>
import SvgInfo from "./../images/SvgInfo";

export default {
  name: "Details",
  components: { SvgInfo },
  props: [],
  data: function () {
    return {};
  },
  methods: {
    viewDetails: function () {
      this.$root.$emit("view_lead_details", this.params.data);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  max-width: 20px;
}
</style>
