<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 10px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      "
    >
      <div
        @click="changeTab('account')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'account' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'account' ? 'white' : 'black' }"
          >Account Settings</a
        >
      </div>

      <div
        @click="changeTab('profile')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'profile' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'profile' ? 'white' : 'black' }"
          >Profile Settings</a
        >
      </div>

      <div
        @click="changeTab('payment')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'payment' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'payment' ? 'white' : 'black' }"
          >Payment Settings</a
        >
      </div>
    </div>

    <div v-if="activeTab === 'account'">
      <LeadTableSetting />
    </div>

    <div v-if="activeTab === 'profile'">
      <Account />
    </div>

    <div v-if="activeTab === 'payment'">
      <PaymentSettings />
    </div>

    <!-- Other components go here -->
  </div>
</template>

<script>
import Account from "./Account";
import LeadTableSetting from "./LeadTableSetting";
import PaymentSettings from "./PaymentSettings";

export default {
  name: "Settings",
  components: { Account, LeadTableSetting, PaymentSettings },
  data() {
    return {
      activeTab: "account",
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
</style>
