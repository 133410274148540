<template>
  <svg
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1916 0H12.8083C17.8084 0.0261288 22.191 2.64542 24.6826 6.58062L24.7169 6.63849L25 7.07751L24.7168 7.51647C22.1909 11.5096 17.8084 14.1289 12.8122 14.1549L12.8084 14.155H12.4682C9.92483 14.155 7.53249 13.5083 5.44663 12.3706L5.52353 12.4089C3.36823 11.2203 1.60298 9.56704 0.317342 7.57516L0.283202 7.51871L0 7.07967L0.283153 6.64066C2.80853 2.64672 7.19119 0.0266218 12.1876 0H12.1916H12.1916ZM12.5 1.64616H12.1916C7.95829 1.66877 4.22725 3.79893 1.99171 7.04004L1.96444 7.08184C4.30544 10.3848 8.11317 12.5145 12.4183 12.5145C12.447 12.5145 12.4757 12.5144 12.5044 12.5142L12.5 12.5142H12.8083C17.0412 12.4926 20.7722 10.3636 23.0083 7.12358L23.0355 7.08187C20.7731 3.79908 17.0425 1.66899 12.813 1.64616H12.5198H12.5ZM12.5 0.00325387C16.4094 0.00325387 19.5786 3.17248 19.5786 7.08184C19.5786 10.9912 16.4094 14.1604 12.5 14.1604C8.59061 14.1604 5.42143 10.9912 5.42143 7.08184C5.42143 3.17246 8.59061 0.00327832 12.5 0.00327832L12.5 0.00325387ZM12.5 12.5143C15.5002 12.5143 17.9324 10.0821 17.9324 7.08184C17.9324 4.08162 15.5002 1.64944 12.5 1.64944C9.49977 1.64944 7.0676 4.08164 7.0676 7.08184C7.0676 10.0821 9.4998 12.5142 12.5 12.5142L12.5 12.5143ZM12.5 4.17361C14.1062 4.17361 15.4083 5.47569 15.4083 7.08187C15.4083 8.68805 14.1062 9.99015 12.5 9.99015C10.8938 9.99015 9.59174 8.68803 9.59174 7.08184C9.59174 5.47566 10.8938 4.17366 12.5 4.17366L12.5 4.17361ZM12.5 8.34392C13.197 8.34392 13.762 7.77887 13.762 7.08184C13.762 6.38482 13.197 5.81977 12.5 5.81977C11.803 5.81977 11.2379 6.38482 11.2379 7.08184C11.2379 7.77887 11.803 8.34392 12.5 8.34392Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgEyes",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
