<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.19954 0H12.8005V4.60093H8.19954V0ZM11.8459 0.954536H9.15407V3.64635H11.8459V0.954536ZM0 0H4.60093V4.60093H0V0ZM3.64637 0.954536H0.954557V3.64635H3.64637V0.954536ZM16.3991 0H21V4.60093H16.3991V0ZM20.0455 0.954536H17.3536V3.64635H20.0455V0.954536ZM8.19954 8.19954H12.8005V12.8005H8.19954V8.19954ZM11.8459 9.15409H9.15407V11.8459H11.8459L11.8459 9.15409ZM0 8.19954H4.60093V12.8005H0V8.19954ZM3.64637 9.15409H0.954557V11.8459H3.64637V9.15409ZM16.3991 8.19954H21V12.8005H16.3991L16.3991 8.19954ZM20.0455 9.15409H17.3536V11.8459H20.0455L20.0455 9.15409ZM8.19956 16.3991H12.8005V21H8.19956V16.3991ZM11.8459 17.3536H9.15407V20.0454H11.8459V17.3536ZM0 16.3991H4.60093V21H0V16.3991ZM3.64637 17.3536H0.954557V20.0454H3.64637V17.3536ZM16.3991 16.3991H21V21H16.3991L16.3991 16.3991ZM20.0455 17.3536H17.3536V20.0454H20.0455L20.0455 17.3536Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgLogoBox",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
