<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.61201 14.3346C4.55334 13.2759 5.30313 11.4658 6.80032 11.4658H15.0401C15.0401 11.4658 15.0402 11.4658 15.0402 11.4657V10.0327C15.0402 10.0327 15.0401 10.0327 15.0401 10.0327H6.80032C5.30314 10.0327 4.55334 8.22254 5.61201 7.16387L6.10421 6.67167C6.10422 6.67166 6.10422 6.67165 6.10422 6.67164C6.10422 6.67162 6.10422 6.67161 6.10421 6.6716L5.8074 6.37482C5.41168 5.97913 4.77012 5.97914 4.37441 6.37485L1.3048e-05 10.7492C5.76407e-06 10.7492 5.86766e-06 10.7492 1.31238e-05 10.7492L2.9036e-05 10.7492C3.48569e-05 10.7493 3.07023e-05 10.7493 2.24389e-05 10.7493C1.41584e-05 10.7493 1.00115e-05 10.7493 1.58667e-05 10.7493L4.37438 15.1236C4.7701 15.5194 5.41169 15.5194 5.80741 15.1237L6.10421 14.8269C6.10422 14.8269 6.10422 14.8269 6.10422 14.8268C6.10422 14.8268 6.10422 14.8268 6.10421 14.8268L5.61201 14.3346Z"
      fill="#9197B3"
    />
    <path
      d="M7.1697 0C6.77398 0 6.45319 0.320795 6.45319 0.716516C6.45319 1.11224 6.77398 1.43303 7.1697 1.43303H18.067C19.1716 1.43303 20.067 2.32846 20.067 3.43303V18.0624C20.067 19.167 19.1716 20.0624 18.067 20.0624H7.1697C6.77398 20.0624 6.45319 20.3832 6.45319 20.779C6.45319 21.1747 6.77398 21.4955 7.1697 21.4955H19.5C20.6046 21.4955 21.5 20.6 21.5 19.4955V2C21.5 0.89543 20.6046 0 19.5 0H7.1697Z"
      fill="#9197B3"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgNotice",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
