<template>
  <!-- Upload File Button Start -->
  <div class="upload-button">
    <b-form-file
      v-model="leadFile"
      :state="Boolean(leadFile)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      plain
      id="upload-button"
      class="d-none"
      @input="uploadFile"
    ></b-form-file>
    <label for="upload-button" :class="[showText ? '' : 'custom']"
      ><SvgCloud></SvgCloud><span v-if="showText">Import Leads</span></label
    >

    <!-- <b-button @click="uploadFile" variant="primary">Upload</b-button> -->
  </div>
  <!-- Upload File Button End -->
</template>

<script>
import axios from "@/utils/api";
import SvgCloud from "../images/SvgCloud.vue";

export default {
  name: "UploadLeadButton",
  components: {
    SvgCloud,
  },
  data() {
    return {
      display: false,
      mapping_headers: [],
      leadFile: null,
      option: [],
      source: "",
    };
  },
  props: {
    initMappingModal: Function,
    showText: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    uploadFile: function () {
      let formData = new FormData();

      formData.append("file", this.leadFile);
      axios
        .post("/upload/upload_file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const { headers, settings, filename } = response.data;
            this.initMappingModal(headers, settings, filename);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  color: #344054;
  padding-bottom: 5px;
}

label {
  width: max-content;
  border: 1px solid #7e7e7e;
  border-radius: 10px;
  padding: 3px 15px;
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &.custom {
    padding: 3px 7px;
    svg {
      margin: 0px 0 2px 0;
    }
  }

  &:hover {
    background: #7e7e7e;
    color: #fff;
  }
}
</style>
