<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.02988 19.9626L2.22724 14.2522L14.9936 1.44348C15.5771 0.862734 16.3816 0.502773 17.2703 0.5H17.276C19.0566 0.500293 20.5 1.94066 20.5 3.71738C20.5 4.60658 20.1385 5.41152 19.5541 5.99387L6.75376 18.7678L1.02988 19.9626ZM3.82733 15.1087L3.28179 17.7174L5.88926 17.173L18.3219 4.76084C18.5889 4.4935 18.754 4.12465 18.754 3.71734C18.754 2.90219 18.0928 2.24113 17.2763 2.23908H17.2709C16.8621 2.24012 16.4925 2.40611 16.2251 2.67385H16.2251L3.82733 15.1087ZM20.457 20.5H0.5V18.7609H20.457V20.5ZM16.5205 7.79305L13.1914 4.47131L14.4237 3.24174L17.7528 6.56348L16.5205 7.79305Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgPencil",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
