import Login from "./../components/Login.vue";
import Dashboard from "./../components/Dashboard.vue";
import WhatsappChat from "./../components/WhatsappChat.vue";
import Calendar from "./../components/Calendar.vue";
import Notice from "./../components/Notice.vue";
import Request from "./../components/Request.vue";
import Account from "./../components/Account.vue";
import Leads from "./../components/Leads.vue";
import Settings from "./../components/Settings.vue";
import PerformanceReport from "@/components/PerformanceReport";
import Automation from "@/components/Automation";

export const routes = [
  { name: "Login", path: "*", component: Login },
  { name: "Dashboard", path: "/dashboard", component: Dashboard },
  { name: "Whatsapp", path: "/whatsapp", component: WhatsappChat },
  { name: "Calendar", path: "/calendar", component: Calendar },
  { name: "Notice", path: "/notice", component: Notice },
  { name: "Request", path: "/request", component: Request },
  { name: "Account", path: "/account", component: Account },
  { name: "Leads", path: "/leads", component: Leads },
  { name: "Performance", path: "/performance", component: PerformanceReport },
  { name: "Settings", path: "/settings", component: Settings },
  { name: "Automation", path: "/automation", component: Automation },
  {
    name: "*",
    path: "*",
    beforeEnter: (to, from, next) => {
      next("/");
    },
  },
];
