<template>
  <div class="account" style="margin: 0 20px 0 20px">
    <div class="userprofile">
      <h2 class="textuser">User Profile</h2>
    </div>

    <div class="center">
      <div>
        <h4 class="text">Email Address</h4>
        <b-input class="textbox" v-model="form.email" disabled> </b-input>
      </div>

      <div>
        <h4 class="text">Password</h4>
        <b-input
          class="textbox"
          v-model="form.password"
          :disabled="edit"
          type="password"
          @input="validatePassword"
        >
        </b-input>
        <p class="error-message" v-if="passwordError">
          {{ passwordError }}
        </p>
      </div>

      <div>
        <h4 class="text">Confirm Password</h4>
        <b-input
          class="textbox"
          v-model="form.confirm_password"
          :disabled="edit"
          type="password"
          @input="validateConfirmPassword"
        >
        </b-input>
        <p class="error-message" v-if="confirmPasswordError">
          {{ confirmPasswordError }}
        </p>
      </div>

      <div>
        <h4 class="text">Full Name</h4>
        <b-input class="textbox" v-model="fullName" :disabled="edit"> </b-input>
      </div>

      <div>
        <h4 class="text">Mobile Number</h4>
        <b-input class="textbox" v-model="form.phone" :disabled="edit">
        </b-input>
      </div>

      <div v-if="!isCompany">
        <h4 class="text">Company Name</h4>
        <b-input
          class="textbox"
          v-model="form.company"
          :disabled="edit"
        ></b-input>
      </div>

      <div v-if="!isCompany">
        <h4 class="text">Company Code</h4>
        <div class="input-container">
          <b-input
            class="textbox"
            v-model="form.company_code"
            :disabled="edit"
            style="width: 80%"
          >
          </b-input>
          <b-button :disabled="edit" :class="{ 'disabled-button': edit }"
            >Regenerate</b-button
          >
        </div>
      </div>

      <div v-if="isCompany">
        <h4 class="text">Join Company Code</h4>
        <div class="input-container">
          <b-input
            class="textbox"
            v-model="form.company_code"
            :disabled="true"
            style="width: 80%"
          >
          </b-input>
          <b-button :disabled="true" :class="{ 'disabled-button-join': true }"
            >Join</b-button
          >
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center">
      <b-button
        class="buttonclick"
        v-on:click="editProfile"
        v-show="edit"
        variant="primary"
      >
        Edit Profile
      </b-button>

      <!--<button class="buttoncancel" v-on:click="editProfile" v-show="!edit">
        Cancel
      </button>-->

      <!--<b-button
        target="_blank"
        href="https://raion-technologies.com/payment/"
        class="ml-1"
        variant="primary"
      >
        Proceed to Raion Payment
        <b-icon icon="box-arrow-up-right"></b-icon>
      </b-button>-->
    </div>

    <div class="d-flex justify-content-center">
      <button class="buttonsave" v-on:click="saveProfile" v-show="!edit">
        Save Settings
      </button>
    </div>

    <div class="d-flex justify-content-center" v-if="isCompany">
      <button
        class="buttonsave"
        @click="showCompanyConfirmationModal"
        v-show="!edit"
      >
        Convert to Company
      </button>
    </div>

    <b-modal
      id="convertToCompanyModal"
      v-model="showModal"
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
    >
      <h4 class="notesreminder1">
        Are you sure you want to convert to company?
      </h4>
      <h4 class="notesreminder2">
        You can't change to the personal account after you convert to company
        account.
      </h4>
      <div class="mt-4 text-center">
        <b-button
          class="buttonyes"
          @click="showCompanyFormModal"
          variant="success"
          >Yes</b-button
        >
        <b-button class="buttoncancel" @click="closeModal" variant="danger"
          >No</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="companyFormModal"
      v-model="showFormModal"
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
    >
      <label class="customtext" for="mobile-number">Company Name: </label>
      <b-form-input v-model="form.company" placeholder="Company Name" />

      <div class="mt-4 text-center">
        <b-button class="buttonyes" @click="saveCompanyData" variant="success"
          >Save</b-button
        >
        <b-button class="buttoncancel" @click="closeFormModal" variant="danger"
          >Close</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "../utils/api.js";
// import VFacebookLogin from "vue-facebook-login-component";

export default {
  name: "Account",
  props: {},
  // components: { VFacebookLogin },
  data: function () {
    return {
      data: [],
      form: {
        email: "",
        //company: "",
        first_name: "",
        last_name: "",
        phone: "",
        company_code: "",
        password: "",
        confirm_password: "",
        company: null,
      },
      state: {
        company: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        //companyName: "",
      },
      passwordError: "",
      confirmPasswordError: "",
      edit: true,
      showModal: false,
      showFormModal: false,
      isCompany: false,
    };
  },
  methods: {
    validatePassword() {
      const password = this.form.password;

      // Define a regular expression to match the required format
      const formatRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (!formatRegex.test(password)) {
        this.passwordError =
          "Must include uppercase, lowercase, numbers, and symbols.";
      } else {
        this.passwordError = ""; // Clear the error message if the format is valid.
      }
    },

    validateConfirmPassword() {
      const password = this.form.password;
      const confirmPassword = this.form.confirm_password;

      if (password !== confirmPassword) {
        this.confirmPasswordError =
          "Password is different. Please check again.";
      } else {
        this.confirmPasswordError = ""; // Clear the error message if they match.
      }
    },

    editProfile: function () {
      this.edit = !this.edit;
    },

    saveProfile: function () {
      console.log("Data to be sent:", this.form);

      axios({
        method: "post",
        url: "/user/details",
        data: this.form,
      })
        .then((response) => {
          console.log("Response from server:", response.data);
          // You can handle the response data as needed
        })
        .catch((error) => {
          console.error("Error:", error);
          // You can handle errors here
        });
    },

    closeFormModal() {
      this.showFormModal = false; // Close the second modal
    },
    saveCompanyData() {
      this.closeFormModal();
      this.saveProfile();
      this.isCompany = true;
    },
    closeModal() {
      this.showModal = false; // Close the first modal
    },

    showCompanyConfirmationModal() {
      this.showModal = true; // Open the confirmation modal
    },

    showCompanyFormModal() {
      this.showModal = false; // Close the confirmation modal
      this.showFormModal = true; // Open the form modal
    },
  },
  computed: {
    fullName() {
      // Combine the first name and last name to form the full name
      return this.form.first_name + " " + this.form.last_name;
    },

    companyNameState() {
      if (this.form.companyName === null) return null;
      else return this.form.companyName !== "";
    },
  },
  created() {
    // Fetch user data here
    axios({
      method: "get",
      url: "/user/details",
      data: {},
    }).then((response) => {
      // Assuming your server response has a property named 'company'
      this.form = response.data.data;
    });
  },

  mounted: function () {},
};
</script>

<style scoped>
.account {
  color: black;
}

.textuser {
  font-size: 20px;
}

.textbox {
  height: 35px;
  width: 310px;
  padding: 10px;
  font-size: 15px;
  font-weight: 550;
  border-radius: 5px;
  border: none;
}

.text {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
}

.textbox:not([disabled]) {
  background-color: #e6f5f8;
}

.textbox:y([disabled]) {
  background-color: #c4caca;
}

.center {
  display: flex; /* Add this to make the elements inside the container a flex container */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center children horizontally */
}

.row h4 {
  width: 15%;
}

.userprofile {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.error-message {
  font-size: 11px;
  font-weight: 600;
  color: #eb3e42;
  margin-bottom: 0px;
}

.errorpass {
  font-size: 12px;
  font-weight: 600;
  color: #eb3e42;
  margin-bottom: 0px;
  display: block;
  justify-content: center;
  margin-left: 34px;
}

.disabled-button {
  background-color: #6e6e6e; /* Change this to the desired color for the disabled button */
  border-color: #6e6e6e;
  color: #ffff; /* Change this to the desired text color for the disabled button */
  cursor: not-allowed; /* Change cursor style to not-allowed for disabled state */
  border-radius: 5px;
  height: 35px;
}

.disabled-button-join {
  background-color: #6e6e6e; /* Change this to the desired color for the disabled button */
  border-color: #6e6e6e;
  color: #ffff; /* Change this to the desired text color for the disabled button */
  cursor: not-allowed; /* Change cursor style to not-allowed for disabled state */
  width: 40%;
  border-radius: 5px;
  height: 35px;
  padding: 2px;
}

.buttonclick {
  margin-top: 5px;
  height: 35px;
  width: 310px;
  background-color: #fb6639;
  color: #ffff;
  font-size: 17px;
  border: 0px;
  margin-bottom: 10px;
}

.buttonsave {
  padding: 3px;
  width: 310px;
  height: 35px;
  border: 0px;
  background-color: #fb6639;
  border-color: #fb6639;
  border-radius: 5px;
  font-size: 17px;
  color: #ffff;
  margin-bottom: 10px;
  margin-top: 10px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
}

@media (max-width: 768px) {
  .errorpass {
    font-size: 12px;
    font-weight: 600;
    color: #eb3e42;
    margin-bottom: 0px;
    display: block;
    justify-content: center;
    margin-left: 10px;
  }
}

.buttonyes {
  width: 100px;
  height: 38px;
  font-weight: 500;
  background-color: #0e930e;
  padding-top: 3px;
  border: 0px;
}

.buttoncancel {
  width: 100px;
  height: 38px;
  font-weight: 500;
  background-color: #eb3e42;
  padding-top: 3px;
  border: 0px;
  margin-left: 10px;
}

.notesreminder1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.notesreminder2 {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #eb3e42;
}

.customtext {
  display: flex;
  font-weight: 600; /* Make the text bold */
  font-size: 15px;
  margin-top: 10px;
}
</style>
