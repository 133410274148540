<template>
  <nav
    id="header"
    :class="this.$store.getters.getActiveMenu ? 'open' : ''"
    v-if="this.$route.name != 'Login'"
  >
    <div @mouseenter="onMouseEnterHeader" @mouseleave="onMouseLeaveHeader">
      <div class="logo-section">
        <a @click="toggleMenu" @dblclick="toggleMenuDoubleClick"
          ><img id="logo-img" src="@/assets/logo@2x.png"
        /></a>
        <h1>Raion CRM</h1>
        <span>v2.02</span>
      </div>

      <ul>
        <!--<li :class="currentRouteName == 'dashboard' ? 'active' : ''" v-if="admin">
        <a id="dashboard" href="/dashboard">
          <SvgDashboard></SvgDashboard>
          <span>Dashboard</span>
        </a>
      </li>-->

        <li :class="currentRouteName == 'leads' ? 'active' : ''">
          <a id="leads" href="/leads">
            <SvgLead></SvgLead>
            <span>Leads</span>
          </a>
        </li>

        <!-- <li :class="currentRouteName == 'whatsapp' ? 'active' : ''">
          <a id="whatsapp" href="/whatsapp">
            <SvgWhatsappHeader></SvgWhatsappHeader>
            <span>Whatsapp</span>
          </a>
        </li> -->

        <!-- <li
          :class="currentRouteName == 'performance' ? 'active' : ''"
          v-if="admin"
        >
          <a id="performance" href="/performance">
            <SvgReport></SvgReport>
            <span>Performance</span>
          </a>
        </li> -->

        <li
          :class="currentRouteName == 'automation' ? 'active' : ''"
          v-if="admin"
        >
          <a id="automation" href="/automation">
            <SvgSchedule></SvgSchedule>
            <span>Automation</span>
          </a>
        </li>

        <li :class="currentRouteName == 'request' ? 'active' : ''" v-if="admin">
          <a id="request" href="/request">
            <SvgUser></SvgUser>
            <span>Members</span>
          </a>
        </li>
        <!--<li :class="currentRouteName == 'notice' ? 'active' : ''" v-if="admin">
        <a id="notice" href="/notice">
          <SvgNotice></SvgNotice>
          <span>Notice</span>
        </a>
      </li>-->

        <li
          :class="currentRouteName == 'settings' ? 'active' : ''"
          v-if="admin"
        >
          <a id="settings" href="/settings" title="Settings">
            <SvgSetting></SvgSetting>
            <span>Settings</span>
          </a>
        </li>
        <li>
          <a id="logout" href="#" v-on:click="logOut">
            <SvgLogout></SvgLogout>
            <span>Log Out</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import Cookies from "js-cookie";
import SvgUser from "./images/SvgUser.vue";
import SvgLead from "./images/SvgLead.vue";
import SvgLogout from "./images/SvgLogout.vue";
//import SvgNotice from "./images/SvgNotice.vue";
// import SvgReport from "./images/SvgReport.vue";
import SvgSchedule from "./images/SvgSchedule.vue";
import SvgSetting from "./images/SvgSetting.vue";
//import SvgDashboard from "./images/SvgDashboard.vue";
// import SvgWhatsappHeader from "./images/SvgWhatsappHeader.vue";

export default {
  name: "Header",
  components: {
    SvgUser,
    SvgLead,
    SvgLogout,
    //SvgNotice,
    // SvgReport,
    SvgSchedule,
    SvgSetting,
    // SvgDashboard,
    // SvgWhatsappHeader,
  },
  data: function () {
    return {
      admin: false,
      isLogin: false,
      doubleClicked: true,
    };
  },
  methods: {
    logOut: function () {
      Cookies.remove("jwtToken");
      Cookies.remove("user_type");
      localStorage.clear();

      this.$router.push("/");
      this.$bvToast.toast("Log Out Successfully", {
        title: "Success",
        autoHideDelay: 3000,
        variant: "success",
      });
    },

    toggleMenu: function () {
      this.doubleClicked = false;
      // Only toggle the menu if not double-clicked
      var active_menu = this.$store.getters.getActiveMenu;
      this.$store.commit("setActiveMenu", !active_menu);
      this.$emit("toggle", { toggle: !active_menu });
    },

    toggleMenuDoubleClick: function () {
      // Set the flag to true on double-click
      this.doubleClicked = true;

      // Toggle the menu and emit the toggle event for double click
      this.$store.commit("setActiveMenu", true);
      this.$emit("toggle", { toggle: true });
    },

    windowResize: function () {
      if (window.innerWidth < 1024) {
        this.$store.commit("setActiveMenu", false);
        this.$emit("toggle", { toggle: false });
      } else {
        this.$store.commit("setActiveMenu", true);
        this.$emit("toggle", { toggle: true });
      }
    },

    onMouseEnterHeader() {
      if (!this.doubleClicked && !this.$store.getters.getActiveMenu) {
        this.$store.commit("setActiveMenu", true);
      }
    },

    onMouseLeaveHeader() {
      if (!this.doubleClicked && this.$store.getters.getActiveMenu) {
        this.$store.commit("setActiveMenu", false);
        this.$emit("toggle", { toggle: false });
      }
    },
  },
  mounted: function () {
    this.admin = Cookies.get("user_type") !== "agent";
    window.addEventListener("resize", this.windowResize);
  },

  unmounted() {
    window.removeEventListener("resize", this.windowResize);
  },

  watch: {
    $route() {
      this.admin = Cookies.get("user_type") !== "agent";
    },
  },
  
  computed: {
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

#header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  padding: 0px 30px;
  text-align: left;
  transition: all 0.3s ease-out;
  z-index: 10;
  background: #fff;
  transform: translateX(-100%);
  width: 100%;
  position: fixed;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);

  .logo-section {
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;

    span,
    img {
      margin: auto 0 0;
    }

    h1 {
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
      letter-spacing: 0.01em;
      color: $black;
      margin-bottom: 0px;
      transition: all 0.3s ease-in-out;
    }

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.01em;
      padding-bottom: 5px;
      color: #838383;
    }

    img {
      width: 37px;
      height: 37px;
    }
  }

  ul {
    list-style: none;
    padding: 0px;

    li {
      display: flex;
      margin-bottom: 20px;
      transition: all 0.2s ease-in-out;
      border-radius: 8px;
      width: max-content;

      a {
        display: block;
        padding: 10px;
        margin: auto;
        white-space: nowrap;
        transition: all 0.2s ease-in-out;

        svg {
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }

      &:hover,
      &.active {
        background: $primary;

        a {
          color: $white;
        }
      }
    }
  }

  &.open {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateX(0%);

    .logo-section {
      h1,
      span {
        opacity: 1;
        visibility: visible;
      }

      h1 {
        margin-left: 0px;
      }
    }

    li {
      width: 100%;

      a {
        width: 100%;

        svg {
          margin-right: 15px;
        }

        span {
          position: relative;
          left: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      min-width: 300px;
      width: unset;
      position: fixed;

      .logo-section {
        h1,
        span {
          opacity: 1;
          visibility: visible;
        }

        h1 {
          margin-left: 0px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100px;
    transform: translateX(0%);

    .logo-section {
      h1,
      span {
        white-space: nowrap;
        margin-left: 30px;
        opacity: 0;
        visibility: hidden;
      }
    }

    li a span {
      position: absolute;
      display: inline-block;
      right: 99999px;
      opacity: 0;
      visibility: hidden;
      white-space: nowrap;
    }
  }
}
</style>
