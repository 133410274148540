<template>
  <b-container fluid class="vertical-center-container">
    <div class="custom-container">
      <b-row class="justify-content-center">
        <b-col cols="12" md="9" lg="7" class="custom-col mx-auto ml-1 mr-1">
          <div class="logo">
            <img
              src="@/assets/logoraion.png"
              style="width: 80px; height: auto"
            />
          </div>
          <div class="logo">
            <img src="@/assets/textraion.png" />
          </div>

          <div class="login-form" v-if="isLoginView">
            <!-- Login form content -->
            <div class="newusertext">
              <div class="newuser">
                {{ isLoginView ? "New user?" : "" }}
              </div>
              <a href="#" class="link-style" @click="toggleView"
                >Create an account</a
              >
            </div>

            <!--form for login email-->
            <label class="emailtext" for="email">Email Address:</label>
            <b-form-input
              v-model="username"
              size="sm"
              class="form-control"
              @keyup.enter="login"
            />

            <label class="passwordtext" for="password">Password:</label>
            <b-form-input
              v-model="password"
              size="sm"
              class="form-control"
              type="password"
              @keyup.enter="login"
            />

            <a href="/" @click="showPasswordModal" id="forgot-password"
              ><span class="link-style-pass">Forgot Password?</span></a
            >
            <span v-if="error" :class="{ 'error-message': error }">
              Invalid email address or password. Please try again.
            </span>

            <div class="login-button-group">
              <b-button variant="primary" class="loginbutton" @click="login"
                >Log In</b-button
              >
            </div>

            <b-modal ref="reset-password-modal" title="Forgot Password">
              <b-form-group>
                <b-form-input
                  v-model="forgotPasswordForm.email"
                  placeholder="Email"
                  type="email"
                  :state="
                    forgotPasswordForm.email != null
                      ? validEmail(forgotPasswordForm.email)
                      : null
                  "
                />
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="forgotPasswordForm.password1"
                  placeholder="New Password"
                  type="password"
                  :state="
                    forgotPasswordForm.password1 != null
                      ? validPassword(forgotPasswordForm.password1)
                      : null
                  "
                />
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="forgotPasswordForm.password2"
                  placeholder="Confirm Password"
                  type="password"
                  :state="
                    forgotPasswordForm.password2 != null
                      ? validPassword(forgotPasswordForm.password2) &&
                        forgotPasswordForm.password1 ===
                          forgotPasswordForm.password2
                      : null
                  "
                />
              </b-form-group>

              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="hidePasswordModal"
                  >
                    Cancel
                  </b-button>

                  <b-button
                    variant="primary"
                    size="sm"
                    @click="resetPassword"
                    :disabled="
                      forgotPasswordForm.email == '' ||
                      !validPassword(forgotPasswordForm.password1) ||
                      !validPassword(forgotPasswordForm.password2) ||
                      forgotPasswordForm.password1 !==
                        forgotPasswordForm.password2
                    "
                  >
                    Save
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>

          <div v-else centered>
            <!-- Registration form content -->
            <!-- Rest of the registration form content -->
            <div class="d-flex justify-content-center">
              <span class="textaccount">Have an account?</span>
              <a href="#" class="link-style" @click="toggleView"
                >Back to login</a
              >
            </div>

            <label class="customtext" for="email">Email Address:</label>
            <b-form-input
              id="input-none"
              v-model="form.email"
              :state="emailState"
              placeholder="Email"
              type="email"
            />

            <span v-if="emailState === false" class="error-message">
              Invalid Email. Please check again.
            </span>
            <span v-if="emailAlreadyRegistered" class="error-message">
              Email is already registered.
            </span>

            <label class="customtext" for="password">Password:</label>
            <b-form-input
              id="input-none"
              v-model="form.password1"
              :state="passwordState"
              placeholder="Password"
              type="password"
              @input="() => validPassword(form.password1)"
            />

            <span v-if="form.password1 && !passwordState" class="error-message">
              Must include uppercase, lowercase, numbers, and symbols.
            </span>

            <label class="customtext" for="confirm-password"
              >Confirm Password:</label
            >
            <b-form-input
              id="input-none"
              v-model="form.password2"
              :state="confirmPasswordState"
              placeholder="Confirm Password"
              type="password"
              @input="confirmPasswordState"
            />
            <span v-if="confirmPasswordState === false" class="error-message">
              Passwords do not match. Please check again.
            </span>

            <label class="customtext" for="full-name">Full Name:</label>
            <b-form-input
              id="input-none"
              v-model="form.fullName"
              :state="fullNameState"
              placeholder="Full Name"
            />

            <!-- <div style="text-align: left; margin-bottom: 1rem">
                  <div style="width: 70%">
                    <b-form-input
                      id="input-none"
                      v-model="form.registrationCode"
                      :state="registrationCodeState"
                      placeholder="Click to Generate"
                      readonly
                      style="width: 70%; display: inline"
                    />
                  </div>
                  <div style="width: 30%"><b-button> Generate </b-button></div>
                </div> -->

            <label class="customtext" for="mobile-number">Mobile Number:</label>
            <b-form-input
              id="input-none"
              v-model="form.phone"
              :state="contactState"
              placeholder="Contact"
              type="tel"
            />

            <div class="login-button-wrapper">
              <b-button
                variant="primary"
                class="loginbutton"
                :disabled="!isFilled"
                @click="register"
              >
                Sign Up
              </b-button>
            </div>

            <b-modal
              ref="payment-modal"
              class="payment-modal"
              title="Registration Success"
              centered
              >Please make payment on BillPlz to activate your account
              <b-button variant="primary" :disabled="isFilled" target="">
                Proceed to BillPlz
              </b-button>
              <!-- Rest of the payment modal content -->
            </b-modal>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import Cookies from "js-cookie";
import axios from "../utils/api";

export default {
  name: "Login",
  components: {},
  props: {},
  data: function () {
    return {
      username: "",
      password: "",
      error: false,
      form: {
        password1: null,
        email: null,
        package: "agent",
        fullName: null,
        password2: null,
        phone: null,
        showRegistrationContent: false,
      },
      forgotPasswordForm: {
        email: null,
        password1: null,
        password2: null,
      },
      isLoginView: true,
      emailAlreadyRegistered: false,
    };
  },
  computed: {
    nameState() {
      if (this.form.name === null) return null;
      else return this.form.name !== "";
    },
    emailState() {
      if (this.form.email === null) return null;
      else {
        return this.validEmail(this.form.email);
      }
    },
    passwordState() {
      if (this.form.password1 === null) return null;
      else return this.validPassword(this.form.password1);
    },
    fullNameState() {
      if (this.form.fullName === null) return null;
      else return this.form.fullName !== "";
    },

    confirmPasswordState() {
      if (!this.form.password2) {
        return null; // No input yet
      } else if (this.form.password2 === this.form.password1) {
        return true; // Passwords match
      } else {
        return false; // Passwords do not match
      }
    },

    contactState() {
      if (this.form.phone === null) return null;
      else return this.form.phone !== "";
    },
    isFilled() {
      if (
        this.emailState &&
        this.passwordState &&
        this.confirmPasswordState &&
        this.fullNameState &&
        this.contactState
        // this.registrationCodeState
      )
        return true;
      // Set to true when the form is filled
      else return false; // Set to false when the form is not completely filled
    },
  },
  methods: {
    toggleView() {
      this.isLoginView = !this.isLoginView;
    },

    login: function () {
      const { username, password } = this;

      if (!username || !password) {
        this.error = true;
        return;
      }

      axios({
        method: "post",
        url: "/auth/login",
        data: {
          email: username,
          password: password,
        },
      }).then((response) => {
        if (!response.data.error) {
          Cookies.set("user_type", response.data.user_type);
          Cookies.set("jwtToken", response.data.jwt);
          localStorage.clear();

          this.$router.push("/leads");
        } else {
          this.error = true;
        }
      });
    },
    showModal: function () {
      // Set the flag to true to display the registration content
      this.showRegistrationContent = true;
      this.$refs["register-modal"].show();
    },
    showPasswordModal: function (e) {
      e.preventDefault();
      this.$refs["reset-password-modal"].show();
    },
    hidePasswordModal: function () {
      this.$refs["reset-password-modal"].hide();
    },
    register: function () {
      axios({
        method: "post",
        url: "/auth/signup",
        data: this.form,
      }).then((response) => {
        if (response.status === 200) {
          this.$refs["register-modal"].hide();
          for (const key in this.from) {
            this.form[key] = null;
          }

          // this.$refs["payment-modal"].show();
        }
      });
    },

    validateEmail() {
      this.emailState = this.validEmail(this.form.email);
      // You may want to add additional logic to check if email is already registered.
      this.emailAlreadyRegistered = false; // For now, assuming email is not already registered.
    },

    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validPassword: function (password) {
      const containsUppercase = /[A-Z]/.test(password);
      const containsLowercase = /[a-z]/.test(password);
      const containsNumber = /[0-9]/.test(password);
      const containsSpecial = /[#?!@$%^&*-]/.test(password);
      const minimum8Characters = /.{8,}/.test(password);
      const maximum20Characters = /.{20,}/.test(password);

      this.passwordValid =
        containsUppercase &&
        containsLowercase &&
        containsNumber &&
        containsSpecial &&
        minimum8Characters &&
        !maximum20Characters;

      return this.passwordValid;
    },
    resetPassword: function () {
      this.$refs["reset-password-modal"].hide();

      axios({
        method: "post",
        url: "/auth/resetpassword",
        data: this.forgotPasswordForm,
      }).then(() => {});
      for (const key in this.forgotPasswordForm) {
        this.forgotPasswordForm[key] = null;
      }
    },
  },
  mounted: function () {
    var token = this.$route.query.token;
    const jwtToken = Cookies.get("jwtToken");

    if (token) {
      axios({
        method: "get",
        url: "/auth/resetpassword",
        params: { token: token },
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.message) {
            this.$bvToast.toast(response.data.message, {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
            });
            this.$router.replace("/");
          }
        }
      });
    }
    if (jwtToken) {
      this.$router.push("/leads");
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  img {
    width: 150px;
    height: auto;
  }
}

.custom-col {
  width: 100%; /* Make the column take full width on smaller screens */
  max-width: 450px;
  border: 1px solid #ececec;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 3px 5px 5px rgba(36, 35, 35, 0.2);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.vertical-center-container {
  height: 100vh; /* Set the container height to 100% of viewport height */
  display: grid;
  align-items: center; /* Vertically center the content */
  padding: 35px;
  //margin-left: 5px;
}

.link-style {
  color: $blue; /* Set the link color */
  text-decoration: underline; /* Add underline */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 12px;
  font-weight: 600; /* Make the text bold */
}

.link-style-pass {
  margin-top: 5px;
  color: $blue; /* Set the link color */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 12px;
  font-weight: 600; /* Make the text bold */
}

.login-form {
  transition: all 0.2s ease-in-out;
}
.newusertext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.newuser {
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
}

.customtext {
  display: flex;
  font-weight: 600; /* Make the text bold */
  font-size: 15px;
  margin-top: 10px;
}

.emailtext {
  display: flex;
  font-weight: 600; /* Make the text bold */
  font-size: 15px;
}

.passwordtext {
  display: flex;
  font-weight: 600; /* Make the text bold */
  font-size: 15px;
  margin-top: 5px;
}

.form-control {
  background-color: #e3f4f8;
  /* Adjust the top margin as needed */
  height: 35px;
}

.textaccount {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
.login-button-group {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  width: 100%;
}

.login-button-wrapper {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  width: 100%;
}

.loginbutton {
  padding: 5px 10px; /* Adjust padding for the desired button size */ /* Adjust margin between buttons */
  margin-top: 20px;
  margin-bottom: 25px;
  background-color: #fb6639; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  cursor: pointer; /* Change cursor on hover */
  flex-grow: 1; /* Allow the button to grow and fill its container */
  height: 40px; /* Adjust the height here */
  font-size: 20px;
  font-weight: 500;

  &:hover {
    background-color: #fb6639da;
  }
}

.login-button-wrapper:disabled {
  background-color: #fb6639; /* Your desired color for the disabled wrapper */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
}

.error-message {
  display: block; /* Display the error message as a block element */
  margin-top: 5px; /* Adjust the top margin to create space between the link and error message */
  color: $red; /* Set the color of the error message */
  font-weight: 600; /* Make the text bold */
  font-size: 12px; /* Adjust the font size of the error message */
}
</style>
