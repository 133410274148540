<template>
  <b-overlay :show="this.$store.getters.loading" class="overlay">
    <div id="app">
      <Header @toggle="toggle"></Header>
      <div id="mobile-top-bar" v-if="this.$route.name != 'Login'">
        <div class="logo-section">
          <img src="@/assets/logo@2x.png" />
          <h1>Raion CRM</h1>
          <span>v2.02</span>
        </div>
        <a href="javascript:void(0);" @click="toggleMobileMenu"
          ><SvgCollapse></SvgCollapse
        ></a>
      </div>
      <router-view :class="active ? 'page' : 'page-fluid'"></router-view>
    </div>
    <template #overlay>
      <div class="text-center">
        <atom-spinner :animation-duration="1000" :size="100" color="#0B2F4B" />
      </div>
    </template>
  </b-overlay>
</template>

<style src="ag-grid-community/styles/ag-grid.css"></style>
<style src="ag-grid-community/styles/ag-theme-alpine.css"></style>

<script>
import Header from "./components/Header.vue";
import { AtomSpinner } from "epic-spinners";
import SvgCollapse from "./components/images/SvgCollapse.vue";

export default {
  name: "App",
  components: { Header, AtomSpinner, SvgCollapse },
  data: function () {
    return {
      active: this.$store.getters.getActiveMenu,
    };
  },
  methods: {
    toggleMobileMenu: function () {
      var active_menu = this.$store.getters.getActiveMenu;
      this.active = !active_menu;
      this.$store.commit("setActiveMenu", !active_menu);
    },
    toggle: function (eventData) {
      if (eventData) {
        this.active = eventData.toggle;
      } else {
        this.active = !this.active;
      }
    },
  },
  created: function () {},
  watch: {},
};
</script>

<style src="ag-grid-community/styles/ag-grid.css"></style>
<style src="ag-grid-community/styles/ag-theme-alpine.css"></style>

<style lang="scss">
.page-fluid,
.page {
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 1024px) {
  .page {
    transition: all 0.2s ease-in-out;
    overflow-x: hidden;
    margin-left: 350px;
    margin-right: 50px;
    padding: 40px 10px 0 10px;

    &-fluid {
      transition: all 0.2s ease-in-out;
      overflow-x: hidden;
      margin-left: 350px;
      margin-right: 50px;
      padding-top: 40px;
      margin-left: 150px;
    }
  }
}

.atom-spinner {
  z-index: 9999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  min-height: 100vh;
}

#mobile-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;

  .logo-section {
    width: 100%;
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      max-width: 37px;
      max-height: 37px;
    }

    h1 {
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 0;
    }

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.01em;
      color: #838383;
      margin: auto;
      margin-left: 0;
    }
  }

  a {
    margin: auto;
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
</style>

<style lang="scss">
@import "assets/_custom.scss";
@import "assets/_font.scss";
</style>
