<template>
  <b-modal
    id="manual_distribution_modal"
    title="Manual Distribution"
    centered
    scrollable
    size="xl"
    @ok="distributeLeads"
    :ok-disabled="!validate"
    ok-title="Distribute"
  >
    <div class="content">
      <b-row style="margin-bottom: 20px">
        <strong style="margin: auto 0px">Lead Source</strong>
        <b-form-select
          text-field="lead_source_name"
          value-field="lead_source_id"
          :options="masterOption"
          v-model="selectedSheet"
          style="width: 50%; margin: auto 10px"
        ></b-form-select>
      </b-row>

      <b-row style="margin-bottom: 20px">
        <strong style="margin: auto 0px">Agents</strong>
        <b-form-select
          text-field="name"
          value-field="id"
          :options="masterAgents"
          v-model="selectedAgent"
          style="width: 50%; margin: auto 10px"
        ></b-form-select>
        <b-button
          variant="outline-primary"
          @click="searchSpreadsheet"
          :disabled="selectedSheet == null || selectedAgent == null"
        >
          Search
        </b-button>
      </b-row>

      <b-row v-show="rows.length > 0" style="margin-bottom: 20px">
        <strong style="margin: auto 10px; margin-left: 0px">Assignee</strong>
        <b-col cols="3"
          ><b-form-select
            :options="allAssignee"
            text-field="name"
            value-field="id"
            v-model="selectedAssignee"
            @change="addAssignee"
          ></b-form-select
        ></b-col>

        <span style="margin: auto 10px">Selected:</span>
        <b-button
          v-for="a in assignee"
          :key="a.id"
          v-show="a.id != null"
          style="margin: 0 5px"
          @click="removeAssignee(a.id)"
        >
          <span v-show="a.id != null">
            {{ a.first_name }}
            <i
              class="fa fa-times"
              style="margin: auto; pointer-events: all"
            ></i>
          </span>
        </b-button>
      </b-row>
      <!-- table content  -->
      <div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :select-options="{ enabled: rows.length > 0 }"
          :search-options="{ enabled: true }"
          max-height="300px"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 10,
          }"
          @on-selected-rows-change="rowOnChange"
        >
        </vue-good-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import axios from "./../../utils/api";
import { manualDistribute } from "@/utils/ajax";

export default {
  name: "ManualDistributionModal",
  props: {},
  components: {
    VueGoodTable,
  },
  data: function () {
    return {
      masterOption: [],
      masterAgents: [],
      selectedSheet: null,
      selectedAgent: null,
      assignee: [],
      selectedAssignee: null,
      columns: [],
      rows: [],
      allAssignee: [{ id: null, name: "Select an Option:", disabled: true }],
      checkedRows: [],
    };
  },
  methods: {
    removeAssignee: function (id) {
      const selectedAssignee = this.assignee.find(
        (element) => element.id == id
      );
      this.allAssignee.push(selectedAssignee);

      this.assignee = this.assignee.filter(function (value) {
        return value.id != id;
      });
    },
    addAssignee: function (input) {
      const selectedAssignee = this.allAssignee.find(
        (element) => element.id == input
      );
      this.assignee.push(selectedAssignee);
      this.allAssignee = this.allAssignee.filter(function (value) {
        return value.id != input;
      });

      this.selectedAssignee = null;
    },
    searchSpreadsheet: function () {
      var url = "";
      var hasSheet = false;
      var hasAgent = false;

      if (this.selectedSheet !== "" && this.selectedSheet !== null) {
        hasSheet = true;
      }

      if (this.selectedAgent !== "" && this.selectedAgent !== null) {
        hasAgent = true;
      }

      if (hasAgent && hasSheet) {
        url =
          "?source=" + this.selectedSheet + "&agent_id=" + this.selectedAgent;
      } else if (hasSheet) {
        url = "?source=" + this.selectedSheet;
      } else if (hasAgent) {
        url = "?agent_id=" + this.selectedAgent;
      }

      axios
        .get("/table/list" + url)
        .then((response) => {
          this.rows = response.data.leads;
          this.columns = [];
          var headers = response.data.headers;
          headers = headers.filter(
            (e) => e.state != 0 && e.key != "lead_source_id"
          );
          headers.forEach((h) => {
            this.columns.push({
              label: h.value,
              field: h.key,
              tdClass: "text-center",
              thClass: "text-center",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    distributeLeads: function () {
      var lead_ids = [];
      var agent_ids = [];

      this.checkedRows.forEach((e) => {
        lead_ids.push(e.lead_id);
      });

      this.assignee.forEach((a) => {
        agent_ids.push(a.id);
      });

      manualDistribute(
        lead_ids,
        agent_ids,
      ).then((status) => {
        if (status) {
          this.searchSpreadsheet();
        }
      });
    },
    rowOnChange: function (params) {
      this.checkedRows = params.selectedRows;
    },
  },
  computed: {
    validate() {
      return this.checkedRows.length > 0 && this.assignee.length > 0;
    },
  },
  created() {},
  mounted: function () {
    axios
      .get("/distribution/sources")
      .then((response) => {
        this.masterOption = [
          {
            lead_source_id: null,
            lead_source_name: "Select an Option:",
            disabled: true,
          },
          {
            lead_source_id: "",
            lead_source_name: "All",
          },
        ];
        this.masterOption = this.masterOption.concat(response.data.sources);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get("/distribution/agent_list")
      .then((response) => {
        if (response.status === 200) {
          this.masterAgents = [
            {
              id: null,
              name: "Select an Option:",
              disabled: true,
            },
          ];
          response.data.agents.forEach((element) => {
            this.allAssignee.push({
              name: element.last_name + " " + element.first_name,
              id: element.id,
              first_name: element.first_name,
            });

            this.masterAgents.push({
              name: element.last_name + " " + element.first_name,
              id: element.id,
              first_name: element.first_name,
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
