var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding-right":"20px","padding-left":"20px","margin-bottom":"10px","border-radius":"20px","box-shadow":"0 4px 8px rgba(0, 0, 0, 0.1)"}},[_c('div',{staticClass:"tab",style:({
        backgroundColor: _vm.activeTab === 'account' ? '#0095B5' : 'white',
      }),on:{"click":function($event){return _vm.changeTab('account')}}},[_c('a',{style:({ color: _vm.activeTab === 'account' ? 'white' : 'black' })},[_vm._v("Account Settings")])]),_c('div',{staticClass:"tab",style:({
        backgroundColor: _vm.activeTab === 'profile' ? '#0095B5' : 'white',
      }),on:{"click":function($event){return _vm.changeTab('profile')}}},[_c('a',{style:({ color: _vm.activeTab === 'profile' ? 'white' : 'black' })},[_vm._v("Profile Settings")])]),_c('div',{staticClass:"tab",style:({
        backgroundColor: _vm.activeTab === 'payment' ? '#0095B5' : 'white',
      }),on:{"click":function($event){return _vm.changeTab('payment')}}},[_c('a',{style:({ color: _vm.activeTab === 'payment' ? 'white' : 'black' })},[_vm._v("Payment Settings")])])]),(_vm.activeTab === 'account')?_c('div',[_c('LeadTableSetting')],1):_vm._e(),(_vm.activeTab === 'profile')?_c('div',[_c('Account')],1):_vm._e(),(_vm.activeTab === 'payment')?_c('div',[_c('PaymentSettings')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }