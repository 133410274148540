<template>
  <div class="lead-table">
    <div class="d-flex w-100 filter-bar">
      <div  class="d-flex align-items-center filter-options" style="justify-content:fit-content">
        <b-button
          v-for="(btn, index) in filter_buttons"
          :key="`filter-btn` + index"
          @click="filterLead(btn)"
          variant="outline-primary"
          size="sm"
          class="buttons"
          :class="{ active: btn.status }"
          style="margin-right: 12px; border: 1px solid #155eef;  
           "
        >
          {{ btn.text }}</b-button
        >
      </div>

      <!-- <div class="search-bar d-flex">
        <div class="position-relative w-100 h-100">
          <b-icon icon="search" style="left: 13px" />
          <b-form-input
            class="search-box w-100 ml-1"
            placeholder="Search All"
            size="sm"
            v-model="search_temp"
          >
          </b-form-input>
        </div>

        <a class="display-btn" style="margin-left: 15px"
          ><b-icon icon="search" /><span class="d-flex align-items-center"
            >Display</span
          ></a
        >
      </div> -->
    </div>

    <div class="leads-action-bar__mobile">
      <a
        class="add-source-btn"
        v-b-modal.new-lead-modal
        style="display: block; white-space: nowrap"
        ><SvgPlusCircle></SvgPlusCircle
      ></a>
      <UploadLeadButton
        :initMappingModal="initMappingModal"
        :showText="false"
      ></UploadLeadButton>
      <b-form-select
        v-model="action"
        :options="action_list"
        class="custom-select__green"
      ></b-form-select>
      <a class="action-btn" @click="confirmAction()"
        ><SvgChevronRight stroke="#FFF"></SvgChevronRight
      ></a>
    </div>

    <div class="leads-action-bar">
      <div class="filter-col" :class="{ active: filter }"></div>
      <div class="w-100">
        <div class="d-flex align-items-center">
          <a class="filter-toggle d-none" @click="filter = !filter">
            <div class="position-relative align-items-center d-flex">
              <SvgFilter></SvgFilter>
              <SvgChevronLeft v-if="!filter"></SvgChevronLeft>
              <SvgChevronRight v-if="filter"></SvgChevronRight>
            </div>
          </a>
          <b-form-select
            v-model="action"
            :options="action_list"
            class="custom-select__green"
          ></b-form-select>
          <a class="action-btn" @click="confirmAction()"
            ><SvgChevronRight stroke="#FFF"></SvgChevronRight
          ></a>
          <a
            class="add-source-btn mobile-only"
            v-b-modal.new-lead-modal
            style="display: block; white-space: nowrap"
            ><SvgPlusCircle></SvgPlusCircle>Add New Leads</a
          >
          <a
            class="add-source-btn"
            style="display: block; white-space: nowrap"
            @click="addRow"
            ><SvgPlusCircle></SvgPlusCircle>Add New Leads</a
          >
          <UploadLeadButton
            :initMappingModal="initMappingModal"
          ></UploadLeadButton>
          <ImportExcelMapping
            :display="display"
            @closeModal="display = false"
            @refreshModal="loadLeads()"
            :headers="mapping_headers"
            :option="option"
            :source="source"
          />
        </div>
      </div>
    </div>

    <div class="w-100">
      <ag-grid-vue
        style="width: 100%"
        :style="{ height: gridHeight + 'px' }"
        class="ag-theme-alpine"
        :columnDefs="customColumn"
        :rowData="rows"
        @cell-value-changed="cellUpdated"
        @grid-ready="onGridReady"
        :pagination="true"
        @row-selected="onRowSelected"
        rowSelection="multiple"
        :rowClassRules="rowClassRules"
        :suppressRowClickSelection="true"
        :animateRows="true"
      >
      </ag-grid-vue>
      <LeadsDetails
        :selectedLeads="selectedLeads"
        :customColumns="custom_cols"
        @close_container="restoreGridHeight"
      />
    </div>

    <!-- Start View Lead Detail Modal -->
    <b-modal id="detail_modal" size="lg" :title="selected_lead.name">
      <b-card
        v-for="(lead_detail, index) in lead_details"
        no-body
        class="mb-1"
        :key="index"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle="'accordion-' + lead_detail.agent_id"
            variant="primary"
            >{{ lead_detail.name }}</b-button
          >
        </b-card-header>
        <b-collapse :id="'accordion-' + lead_detail.agent_id" role="tabpanel">
          <b-card-body>
            <b-table hover :items="lead_detail.changes"></b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-modal>
    <!-- End View Lead Detail Modal -->

    <WhatsappBlastModal
      :whatsapp_accounts="whatsapp_accounts"
      :target_num="selected_phones"
      :target_name="selected_names"
    ></WhatsappBlastModal>

    <WhatsappBlastResultModal></WhatsappBlastResultModal>
    <ManualDistributionModal
      v-if="user_type !== 'agent'"
    ></ManualDistributionModal>
    <AddModal
      :headers="new_lead_headers"
      :sources="spreadsheet_list"
    ></AddModal>
  </div>
</template>

<script>
import ImportExcelMapping from "./common/ImportExcelMapping.vue";
import AddModal from "./modal/AddModal.vue";
import WhatsappBlastModal from "@/components/modal/WhatsappBlastModal";
import WhatsappBlastResultModal from "@/components/modal/WhatsappBlastResultModal";
import ManualDistributionModal from "./modal/ManualDistributionModal.vue";
import LeadsDetails from "@/components/LeadsDetails.vue";
import UploadLeadButton from "@/components/common/UploadLeadButton";
import Actions from "./common/Actions.vue";
import Details from "./common/Details.vue";
// import Link from "./common/Link.vue";
import axios from "@/utils/api";
import { AgGridVue } from "ag-grid-vue";
import Cookies from "js-cookie";
import SvgFilter from "@/components/images/SvgFilter.vue";
import SvgChevronLeft from "@/components/images/SvgChevronLeft.vue";
import SvgChevronRight from "@/components/images/SvgChevronRight.vue";
import SvgPlusCircle from "@/components/images/SvgPlusCircle.vue";
import { fetchWhatsappAccounts, validateWhatsapp } from "@/utils/ajax";

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2].split(",")[0])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2030,
};

export default {
  name: "Leads",
  components: {
    AgGridVue,
    WhatsappBlastModal,
    WhatsappBlastResultModal,
    ManualDistributionModal,
    AddModal,
    ImportExcelMapping,
    // eslint-disable-next-line vue/no-unused-components
    Actions,
    // eslint-disable-next-line vue/no-unused-components
    Details,
    // eslint-disable-next-line vue/no-unused-components
    // Link,
    UploadLeadButton,
    SvgFilter,
    SvgChevronLeft,
    SvgChevronRight,
    SvgPlusCircle,
    LeadsDetails,
  },
  data() {
    return {
      customColumn: [],
      headers: [],
      new_lead_headers: [],
      column: [],
      rows: [],
      raw_rows: [],
      spreadsheet_list: [],
      source: null,
      display: false,
      mapping_headers: [],
      settings: [],
      option: [],
      selectedRow: [],
      selected_phones: [],
      selected_names: [],
      rowClassRules: null,
      showArchived: true,
      timer: null,
      action: null,
      action_list: [
        { value: null, text: "Choose Action" },
        { value: "archive", text: "Archive" },
        { value: "unarchive", text: "Unarchive" },
        { value: "delete", text: "Delete Lead" },
        { value: "export", text: "Export Spreadsheet" },
        { value: "manual", text: "Manual Distribute" },
        { value: "blast", text: "Whatsapp Blasting" },
      ],
      selected_lead: "",
      lead_details: [],
      search_temp: "",
      filter: false,
      filter_buttons: [
        //{ text: "Today's Follow Up", value: "followup", status: false },
        // { text: "New Leads", value: "new", status: false },
        { text: "Hide Archived", value: "archived", status: false },
      ],
      whatsapp_accounts: [],
      selectedLeads: [],
      gridHeight: 500,
      selectedLeadId: null,
      originalGridHeight: 0,
      custom_cols: [],
      user_type: Cookies.get("user_type"),
      showTable: false,
      currentSelectedLeadName: null,
    };
  },
  mounted: function () {
    this.loadLeads();
    this.gridHeight = window.innerHeight - 150;
    this.timer = setInterval(() => {
      let map = this.$store.state.cached_leads;

      if (map.size > 0) {
        for (let [key, value] of map) {
          map.delete(key);
          this.cellUpdated(value);
        }
      }
    }, 5000);

    this.$root.$on("add_selected_leads", this.handleAddSelectedLeads);
    this.$root.$on("update_show_table", this.updateShowTable);

    // prompt lead details modal
    this.$root.$on("view_lead_details", (data) => {
      this.selected_lead = data;
      axios({
        method: "get",
        url: "/table/view?lead_id=" + data.lead_id,
      }).then((response) => {
        if (response.status == 200) {
          this.$bvModal.show("detail_modal");
          this.lead_details = response.data;
        }
      });
    });

    // remove whatsapp blasting from option if not
    validateWhatsapp().then((data) => {
      const { action_list } = this;

      if (!data) {
        this.action_list = action_list.filter(function (e) {
          return e.value != "blast";
        });
      }
    });
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  updated: function () {
    if (this.gridColumnApi && this.gridColumnApi.getColumns().length > 0) {
      this.autoSizeAll();
      this.gridApi.redrawRows();
    }
  },

  watch: {
    showArchived(current) {
      var { rows } = this;
      if (current) {
        this.rows = this.raw_rows;
      } else {
        this.rows = rows.filter((r) => r.archived != true);
      }
    },
  },
  methods: {
    handleOpenDistributionDetails(lead) {
      this.viewLeadDetails(lead);
      // Add any additional logic related to Distribution Details here
    },
    cellUpdated(event) {
      const { data } = event;
      const { spreadsheet_list } = this;

      if (!data["lead_source_id"] || !data["phone"]) {
        this.$bvToast.toast("Phone and Source must not be empty", {
          title: "Warning",
          autoHideDelay: 3000,
          variant: "warning",
        });

        // flash whole row, so leave column selection out
        this.gridApi.flashCells({ rowNodes: [event.node] });

        return;
      }

      if (!data["lead_id"] && !data["created"]) {
        let new_lead = [];

        for (let [key, value] of Object.entries(data)) {
          if (key === "lead_source_id") {
            for (let i = 0; i < spreadsheet_list.length; i++) {
              if (spreadsheet_list[i]["lead_source_name"] === value) {
                value = spreadsheet_list[i]["lead_source_id"];
                break;
              }
            }
          }
          new_lead.push({ key: key, new_value: value });
        }

        axios({
          method: "post",
          url: "/table/create",
          data: {
            lead: new_lead,
          },
        }).then((res) => {
          const { lead_id } = res.data;
          data["lead_id"] = lead_id;
          data["created"] = true;

          event.node.updateData(data);
        });
      } else {
        delete event.data.created;
        axios({
          method: "post",
          url: "/table/update",
          data: event.data,
        })
          .then((res) => {
            if (res === undefined) {
              this.$store.commit("setCachedLeads", event);
            }
          })
          .catch(() => {
            this.$store.commit("setCachedLeads", event);
          });
      }
    },
    updateShowTable(value) {
      this.showTable = value;
    },

    restoreGridHeight() {
      if (this.heightReduced) {
        // Restore the original grid height
        this.gridHeight = this.originalGridHeight;
        this.heightReduced = false; // Set the flag to false to indicate height restoration
      }
    },

    // Update this method to use selectedLeadId
    onRowSelected(event) {
      console.log("onRowSelected");
      const { lead_id } = event.data;
      const { selected_phones, selected_names } = this;

      if (!lead_id) {
        return;
      }

      const leadId = lead_id.toString();

      if (event.node.isSelected()) {
        this.selected_phones.push(event.data.phone);
        this.selected_names.push(event.data.name);
        this.selectedRow.push(lead_id)
      } else {
        this.selected_phones = selected_phones.filter(
          (e) => e !== event.data.phone
        );
        this.selected_names = selected_names.filter(
          (e) => e !== event.data.name
        );

        if (this.selectedLeadId === leadId) {
          // Lead is unselected, remove it from selectedLeads
          const leadIndex = this.selectedLeads.findIndex(
            (lead) => lead.lead_id.toString() === leadId
          );

          if (leadIndex !== -1) {
            this.selectedLeads.splice(leadIndex, 1);
          }

          // Remove the lead_id and phone from their respective arrays
          this.selectedRow = this.selectedRow.filter((e) => e !== leadId);

          // Update the selected lead data to null when unselected
          this.selected_lead = null;

          // Clear the selectedLeadId
          this.selectedLeadId = null;
        }
      }
    },

    confirmAction() {
      const { whatsapp_accounts, selected_phones } = this;

      switch (this.action) {
        case "archive":
          axios({
            method: "post",
            url: "/table/archive",
            data: { ids: this.selectedRow },
          }).then((res) => {
            if (res.status === 200) {
              this.loadLeads();
            }
          });
          break;
        case "unarchive":
          axios({
            method: "post",
            url: "/table/unarchive",
            data: { ids: this.selectedRow },
          }).then((res) => {
            if (res.status === 200) {
              this.loadLeads();
            }
          });
          break;

        case "delete":
          axios({
            method: "post",
            url: "/table/delete",
            data: { ids: this.selectedRow },
          }).then((res) => {
            if (res.status === 200) {
              this.loadLeads();
            }
          });
          break;

        case "blast":
          if (whatsapp_accounts.length === 0) {
            fetchWhatsappAccounts()
              .then((data) => {
                this.whatsapp_accounts = data;

                if (selected_phones.length === 0) {
                  this.$bvToast.toast("No lead selected", {
                    title: "Warning",
                    autoHideDelay: 3000,
                    variant: "warning",
                  });
                } else {
                  this.$bvModal.show("whatsapp_blasting_modal");
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            if (selected_phones.length === 0) {
              this.$bvToast.toast("No lead selected", {
                title: "Warning",
                autoHideDelay: 3000,
                variant: "warning",
              });
            } else {
              this.$bvModal.show("whatsapp_blasting_modal");
            }
          }
          break;

        case "export":
          this.exportExcel();
          break;

        case "manual":
          this.$bvModal.show("manual_distribution_modal");
          break;

        default:
      }
    },

    variableOnChange: function (value, section, index, event) {
      let new_value = "";

      if (value === "custom") {
        new_value = "";
      } else if (value != "") {
        new_value = `{{${value}}}`;
      } else {
        new_value = event;
      }

      if (new_value != "") {
        if (section === "header") {
          this.header_content = this.header_content.replace(
            `{{${index}}}`,
            new_value
          );
        } else if (section === "body") {
          this.body_content = this.body_content.replace(
            `{{${index}}}`,
            new_value
          );
        }
      }
    },
    onGridReady: function (params) {
      this.gridApi = params.api;

      // Add a check to ensure columnApi is defined before calling getColumns
      if (params.columnApi) {
        this.gridColumnApi = params.columnApi;

        const allColumnIds = [];
        params.columnApi.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds, true);
      }
    },

    handleAddSelectedLeads(data) {
      if (!this.heightReduced) {
        // Store the original grid height
        this.originalGridHeight = this.gridHeight;
        // Reduce the grid height by 30%
        this.gridHeight = 0.5 * this.gridHeight; // 50% of the original height
        this.heightReduced = true; // Set the flag to true to indicate height reduction
      } else if (this.currentSelectedLeadName === data.name) {
        // If the same lead name is clicked again, close the container
        this.$root.$emit("close_container_in_leads_details");
        this.currentSelectedLeadName = null; // Reset the currently selected lead name
        return;
      }

      // Update the selectedLeads array with the clicked lead
      this.selectedLeads = [data];
      // Update the showTable status in LeadsDetails.vue
      this.$root.$emit("update_show_table", true);
      // Update the currently selected lead name
      this.currentSelectedLeadName = data.name;
    },

    exportCsv() {
      this.gridApi.exportDataAsCsv();
    },
    initMappingModal(headers, settings, filename) {
      this.display = true;
      this.mapping_headers = headers;
      this.option = settings;
      this.source = filename;
    },
    autoSizeAll: function () {
      const allColumnIds = [];
      this.gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    filterLead: function (btn) {
      var { raw_rows } = this;

      if (btn.value === "archived" && !btn.status) {
        this.rows = raw_rows.filter((r) => r.archived != true);
      } else {
        this.rows = raw_rows
      }

      btn.status = !btn.status;

    },
    exportExcel: function () {
      axios({
        method: "get",
        url: "/table/download",
        data: {},
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "download.csv");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    addRow: function () {
      this.gridApi.applyTransaction({
        add: [{}],
        addIndex: 0,
      });
    },
    loadLeads: function () {
      axios({
        method: "get",
        url: "/table/list",
      }).then((response) => {
        let { headers, leads } = response.data;

        let sourceMap = {};
        let sources = [];

        this.headers = [];
        this.customColumn = [];
        this.selectedRow = [];

        const non_editable_cols = [
          "action",
          "action_time",
          "date_added",
          "tel",
          "wa",
          "last_distributed",
          "last_distributed_dt",
        ];
        const date_cols = ["action_time", "date_added", "last_distributed_dt"];
        const add_cols = ["last_distributed", "last_distributed_dt"];
        const static_cols = ["name", "email", "phone", "lead_source_id"];

        axios.get("/distribution/sources").then((response) => {
          this.spreadsheet_list = this.spreadsheet_list.concat(
            response.data.sources
          );

          this.spreadsheet_list.forEach((s) => {
            sourceMap[s.lead_source_id] = s.lead_source_name;
            sources.push(s.lead_source_name);
          });

          leads.forEach((data) => {
            data["lead_source_id"] = sourceMap[data.lead_source_id];
            data["action"] = "#";
          });

          headers = headers.filter((e) => e.state != 0);

          headers.forEach((h) => {
            var attr = {
              sortable: true,
              headerName: h.value,
              field: h.key,
              filter: true,
              editable: true,
              resizable: true,
              flex: 1,
              wrapText: true,
              minWidth: 200,
              cellStyle: {
                "text-overflow": "ellipsis",
                "white-space": "nowrap",
                overflow: "hidden",
              },

              // autoHeight: true,
            };

            var subvalues = [];

            if (date_cols.includes(h.key)) {
              attr["filter"] = "agDateColumnFilter";
              attr["filterParams"] = filterParams;
            }

            if (non_editable_cols.includes(h.key)) {
              attr["editable"] = false;
            } else {
              if (static_cols.includes(h.key)) {
                attr["editable"] = function (param) {
                  if (param.data.lead_id && !param.data.created) {
                    return false;
                  } else {
                    return true;
                  }
                };
              } else {
                attr["editable"] = true;
              }
            }

            // if (h.key === "name") {
            //   attr["cellRenderer"] = "Link";
            // }

            if (h.sub_value) {
              attr["cellEditor"] = "agSelectCellEditor";

              h.sub_value.forEach((s) => {
                subvalues.push(s.data_value);
              });

              subvalues.unshift("");
              h["sub_values"] = subvalues;
              attr["cellEditorParams"] = { values: subvalues };
              attr["valueFormatter"] = (params) => {
                for (var i = 0; i < h.sub_value.length; i++) {
                  if (h.sub_value[i]["sub_value_id"] === params.value) {
                    return h.sub_value[i]["data_value"];
                  }
                }
              };
            }

            if (h.key === "lead_source_id") {
              attr["cellEditorSelector"] = function (param) {
                if (!param.data.lead_id) {
                  return {
                    component: "agSelectCellEditor",
                    params: {
                      values: sources,
                    },
                  };
                }
              };
            }
            this.customColumn.push(attr);

            if (h.key === "phone") {
              this.customColumn.unshift({
                sortable: false,
                headerName: "Actions",
                field: "phone",
                flex: 1,
                cellRenderer: "Actions",
                pinned: "left",
                lockPosition: "left",
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                maxWidth: 150,
              });
            }

            h["new_value"] = null;

            if (
              !date_cols.includes(h.key) &&
              !non_editable_cols.includes(h.key) &&
              !add_cols.includes(h.key)
            ) {
              this.custom_cols.push({
                key: h.value,
                option: h.sub_value || [],
              });
            }
          });

          this.rowClassRules = {
            // row style expression
            archived: function (params) {
              return params.data.archived;
            },
            "lead-error": function (params) {
              if (!params.data.lead_id) {
                return !params.data.lead_source_id || !params.data.phone;
              }
            },
          };

          this.headers = headers;
          this.new_lead_headers = headers.filter(
            (e) => !add_cols.includes(e.key)
          );
          this.raw_rows = leads;
          this.rows = leads;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables";

.filter-bar {
  margin-bottom: 5px;
  //gap: 10px;
  flex-direction: column;

  .filter-options {
    width: 100%;
    flex-direction: row;
    order: 1;
    //gap: 10px;

    @media screen and (min-width: 1100px) {
      width: fit-content;
      order: 1;
    }

    button {
      @media screen and (max-width: 1100px) {
        width: 100%;
      }

      padding: 5px 15px;
    }
  }

  .search-bar {
    flex-direction: row;
    order: 1;
    gap: 10px;
    width: 100%;

    @media screen and (min-width: 1100px) {
      flex: 1 1 0;
      width: 60%;
      gap: 10px;
      order: 2;
    }
  }

  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
}

.leads-action-bar {
  gap: 20px;
  display: none;

  .filter-col {
    background: white;
    width: 20%;
    box-shadow: 0px 10px 60px #e2ecf9;
    transition: all 0.2s ease-in-out;
    &.active {
      width: 0%;
    }
  }

  .filter-toggle {
    border: 1px solid #7e7e7e;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-right: 20px;

    .filter {
      margin: 10px;
    }

    .chevron-left,
    .chevron-right {
      margin: 0px 15px 0 5px;
    }
  }

  .action-btn {
    margin: 0px 20px 0px 10px;
  }

  @media screen and (min-width: 1025px) {
    display: block;
  }

  &__mobile {
    display: flex;

    svg {
      margin: 0px 0 2px 0;
    }

    .add-source-btn {
      padding: 3px 7px;
    }

    .custom-select {
      margin: auto 0 auto 10px;
    }

    .action-btn {
      margin: auto 0px auto 10px;
    }

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }
}

.col {
  padding-left: 0;

  .btn {
    width: 100%;
  }
}
.lead-table {
  text-align: left;
}

.ag-cell-value {
  font-size: 12px;
}

.box {
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
  display: inline-block;
  background-color: #e4e4e4;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.display-btn {
  position: relative;
  border: 1px solid black;
  padding: 5px 5px 5px 30px;
  border-radius: 4px;

  svg {
    position: absolute;
    left: 9px;
    top: 9px;
  }
}

.search-bar svg {
  position: absolute;
  left: 9px;
  top: 0px;
  height: 100%;
}

.side-panel {
  height: 100%;
  width: 600px;
  background: white;
  top: 0;
  right: 0;
  border-left: 1px solid;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #155eef;

  &:hover {
    background-color: #155eef;
  }
}

.buttons {
  &:hover {
    background-color: #155eef;
  }
}
</style>
