<template>
  <div class="dashboard">
    <h3>{{ welcome_text }}</h3>
    <b-card-group deck>
      <b-card
        header="Total Leads"
        align="center"
        @click="redirectLead"
        class="total-leads"
      >
        <b-card-text>{{ lead_count }}</b-card-text>
      </b-card>

      <b-card
        border-variant="secondary"
        header="Total Members"
        header-border-variant="secondary"
        align="center"
        @click="redirectMember"
        class="total-members"
      >
        <b-card-text>{{ agent_count }}</b-card-text>
      </b-card>
    </b-card-group>

    <b-card class="notice-board" header="Notice Board" align="center">
      <b-card-text
        v-for="(n, index) in notices"
        :key="'notice_' + index"
        class="notice"
      >
        <!-- <span class="notice--datetime">{{ n.datetime }}</span -->
        <span class="notice--message">{{ n.message }}</span>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import axios from "./../utils/api";

export default {
  name: "Dashboard",
  data: function () {
    return {
      welcome_text: "",
      agent_count: 0,
      lead_count: 0,
      notices: [],
    };
  },
  methods: {
    blockAccess() {
      this.$bvToast.toast(`You don't have permission to access that page`, {
        title: "Unauthorised",
        autoHideDelay: 5000,
        variant: "danger",
      });
    },
    redirectMember: function () {
      this.$router.push("/request");
    },
    redirectLead: function () {
      this.$router.push("/leads");
    },
  },
  mounted: function () {
    axios({
      method: "get",
      url: "/dashboard/info",
    }).then((response) => {
      this.welcome_text = "Hello " + response.data.user_firstname + " 👋🏼,";
      this.lead_count = response.data.lead_count;
      this.agent_count = response.data.agent_count;
      this.notices = response.data.notices;

      var lead_status_label = [],
        lead_status_value = [];

      for (var i = 0; i < response.data.lead_status_count.length; i++) {
        if (response.data.lead_status_count[i]["key"]) {
          lead_status_label.push(response.data.lead_status_count[i]["key"]);
          lead_status_value.push(response.data.lead_status_count[i]["value"]);
        }
      }

      this.chartOptions = {
        ...this.chartOptions,
        ...{ series: lead_status_value },
        ...{ labels: lead_status_label },
      };
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query["unauthorised"]) {
        vm.blockAccess();
        // next({ name: "Dashboard" });
        next();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.notice-board {
  margin-top: 50px;
  min-height: 300px;
  border: 1px solid $primary;

  .card-header {
    background: $white;
    color: $primary;
    font-weight: 700;
    border-bottom: 1px solid $primary;
  }

  .card-body {
    font-weight: 700;
    color: $primary;
  }

  .notice {
    // display: flex;

    &--datetime {
      flex: 1;
      text-align: left;
      font-size: 12px;
    }

    &--message {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      text-transform: capitalize;
    }
  }
}

h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.total-leads,
.total-members {
  cursor: pointer;
}

.total-leads {
  border: 1px solid $primary;

  .card-header {
    background: $primary;
    color: $white;
    font-weight: 700;
  }

  .card-body {
    font-weight: 700;
    color: $primary;
  }
}

.total-members {
  border: 1px solid $second;

  .card-header {
    background: $second;
    color: $white;
    font-weight: 700;
  }

  .card-body {
    font-weight: 700;
    color: $primary;
  }
}
</style>
