<template>
  <div id="history">
    <div class="header">
      <h4>Distribution History</h4>
    </div>
    <hr />
    <div class="content">
      <b-input-group class="history-filter-group">
        <b-form-input
          id="history-filter"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        ></b-form-input>

        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table
        striped
        hover
        :items="items"
        :filter="filter"
        id="my-table"
        :per-page="perPage"
        :current-page="currentPage"
      ></b-table>
      <hr />
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "./../../utils/api";

export default {
  name: "History",
  data() {
    return {
      items: [],
      filter: null,
      rows: 100,
      perPage: 10,
      currentPage: 1,
    };
  },
  methods: {},
  mounted: function () {
    axios
      .get("/distribution/history")
      .then((response) => {
        this.rows = response.data.summary.length;
        this.items = response.data.summary;
        // response.data.agents.forEach((e) => {
        //   this.agent_map[e["user_id"]] = e["last_name"] + " " + e["first_name"];
        // });
        // this.rows.forEach((e) => {
        //   e["caller_id"] = this.agent_map[e["caller_id"]];
        //   e["closer_id"] = this.agent_map[e["closer_id"]] || e["closer_id"];
        //   e["blacklist_id"] =
        //     this.agent_map[e["blacklist_id"]] || e["blacklist_id"];
        // });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.header {
  margin: 30px;
  color: black;
}

.history-filter-group {
  width: 40%;
  margin: 15px 0 15px auto;
}

.pagination {
  justify-content: center;
  padding-bottom: 65px;
  padding-top: 20px;
}
</style>
