import axios from "./api";
import { setCache, validateCache } from "./cache";

export const fetchSources = async (force = false) => {
  try {
    let _sources = localStorage.getItem("sources");
    let spreadsheets = [];

    if (!_sources || force) {
      const response = await axios.get("/distribution/sources");
      let { sources } = response.data;
      spreadsheets = [
        {
          lead_source_id: null,
          lead_source_name: "Select a Source:",
          disabled: "true",
        },
      ];

      spreadsheets = spreadsheets.concat(sources);

      setCache("sources", {
        data: spreadsheets,
      });
    } else {
      _sources = JSON.parse(_sources);
      validateCache("sources", _sources.expiry);
      spreadsheets = _sources.data;
    }

    return spreadsheets;
  } catch (error) {
    console.error("Error fetching sources:", error);
    throw error;
  }
};

export const fetchWhatsappAccounts = async () => {
  try {
    let _whatsapp_accounts = localStorage.getItem("whatsapp_accounts");
    let accounts = [];

    if (!_whatsapp_accounts) {
      const response = await axios.get("/whatsapp/accounts");
      let { pages } = response.data;

      accounts = [
        {
          name: "Please select an account",
          id: null,
          disabled: "true",
        },
      ];

      for (const i in pages) {
        accounts = accounts.concat({
          name: pages[i].page_name,
          id: pages[i].page_id,
        });
      }

      setCache("whatsapp_accounts", {
        data: accounts,
      });
    } else {
      _whatsapp_accounts = JSON.parse(_whatsapp_accounts);
      validateCache("whatsapp_accounts", _whatsapp_accounts.expiry);
      accounts = _whatsapp_accounts.data;
    }

    return accounts;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchWhatsappTemplates = async (id, create_template = false) => {
  try {
    const cache_key = `whatsapp_templates_${id}`;

    let _whatsapp_templates = localStorage.getItem(cache_key);
    var whatsapp_templates = [
      {
        name: "Please select an template",
        id: null,
        disabled: "true",
      },
    ];

    if (!_whatsapp_templates) {
      const response = await axios.get(
        "/whatsapp/whatsapp_template?WABA_ID=" + id
      );

      let { templates } = response.data;

      if (create_template) {
        whatsapp_templates.push({
          name: "Create New Template",
          id: "new",
        });
      }

      for (const i in templates) {
        let template = templates[i];

        if (templates[i].status === "PENDING") {
          template["disabled"] = true;
          template["name"] = template["name"] + " (PENDING)";
        }

        whatsapp_templates.push(template);
      }

      setCache(
        cache_key,
        {
          data: whatsapp_templates,
        },
        24
      );
    } else {
      _whatsapp_templates = JSON.parse(_whatsapp_templates);
      validateCache(cache_key, _whatsapp_templates.expiry);
      whatsapp_templates = _whatsapp_templates.data;
    }

    return whatsapp_templates;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchWhatsappContacts = async (id) => {
  try {
    const cache_key = `whatsapp_contacts_${id}`;

    let _whatsapp_contacts = localStorage.getItem(cache_key);
    var whatsapp_phone_num = [
      {
        name: "Please select a number",
        id: null,
        disabled: "true",
      },
    ];

    if (!_whatsapp_contacts) {
      const response = await axios.get(
        "/whatsapp/get_phone_number_id?WABA_ID=" + id
      );

      let { phone_num } = response.data;

      for (const i in phone_num) {
        let num = phone_num[i];

        whatsapp_phone_num.push({
          name: num["display_phone_number"],
          id: num["id"],
        });
      }

      setCache(
        cache_key,
        {
          data: whatsapp_phone_num,
        },
        24
      );
    } else {
      _whatsapp_contacts = JSON.parse(_whatsapp_contacts);
      validateCache(cache_key, _whatsapp_contacts.expiry);
      whatsapp_phone_num = _whatsapp_contacts.data;
    }

    return whatsapp_phone_num;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const blastMessage = async (whatsapp_blast) => {
  let payload = {
    WABA_ID: whatsapp_blast.account,
    template_id: whatsapp_blast.template,
    template_name: whatsapp_blast.template_name,
    PHONE_NUM_ID: whatsapp_blast.contact,
    target_num: whatsapp_blast.target_num,
    type: whatsapp_blast.type,
    template_language: whatsapp_blast.template_language,
    content: {
      header: whatsapp_blast.header,
      body: whatsapp_blast.body,
    },
  };

  if (whatsapp_blast["type"] === "text" && whatsapp_blast["content"]) {
    payload["content"] = {
      body: whatsapp_blast["content"],
    };
  }

  try {
    return await axios.post("/whatsapp/send_message", payload);
  } catch (error) {
    console.error("Error blast message:", error);
    throw error;
  }
};

// export const fetchLeads = async (id) => {};

export const manualDistribute = async (lead_ids, agent_ids) => {
  const payload = { lead_id: lead_ids, agent_id: agent_ids };
  axios.post("/distribution/manual", payload).then((res) => {
    if (res.status === 200) return true;
    else return false;
  });
};

export const postSource = async (source) => {
  const payload = { source: source };

  try {
    return await axios.post("/distribution/sources", payload);
  } catch (error) {
    console.error("Error post source:", error);
    throw error;
  }
};

export const validateWhatsapp = async () => {
  let _whatsapp_setup = localStorage.getItem("whatsapp_setup");
  var whatsapp_setup = false;

  if (!_whatsapp_setup) {
    try {
      const response = await axios.get("/whatsapp/token/validate");

      if (response) {
        whatsapp_setup = true;
      }
    } catch (err) {
      console.log(err);
      return;
    }

    setCache("whatsapp_setup", {
      data: whatsapp_setup,
    });
  } else {
    _whatsapp_setup = JSON.parse(_whatsapp_setup);
    validateCache("whatsapp_setup", _whatsapp_setup.expiry);
    whatsapp_setup = _whatsapp_setup.data;
  }

  return whatsapp_setup;
};

export const uploadWhatsappMedia = async (media, phone) => {
  const formData = new FormData();
  formData.append("file", media);

  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    return await axios.post(
      `/whatsapp/${phone}/upload_media`,
      formData,
      headers
    );
  } catch (error) {
    console.error("Error post source:", error);
    throw error;
  }
};
