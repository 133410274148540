<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="filter"
  >
    <path
      d="M8.60185 12.8777C8.60185 13.2989 8.3256 13.8512 7.97347 14.0653L7.00001 14.6936C6.09553 15.2528 4.83889 14.6245 4.83889 13.506V9.81212C4.83889 9.3219 4.56271 8.69359 4.27962 8.34837L1.62829 5.55896C1.27616 5.20683 1 4.58544 1 4.16426V2.56242C1 1.72698 1.62832 1.09868 2.39472 1.09868H11.6052C12.3716 1.09868 13 1.72698 13 2.49338V4.02617C13 4.58543 12.6479 5.28279 12.3026 5.62801"
      stroke="#344054"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.8098 11.1173C11.03 11.1173 12.0192 10.1281 12.0192 8.90787C12.0192 7.68764 11.03 6.69844 9.8098 6.69844C8.58958 6.69844 7.60037 7.68764 7.60037 8.90787C7.60037 10.1281 8.58958 11.1173 9.8098 11.1173Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4335 11.5316L11.7431 10.8411"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgFilter",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
