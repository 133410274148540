import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";
import store from "@/utils/store";
import { router } from "@/main";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: "http://localhost:5000",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 50000,
  params: {},
});

api.interceptors.request.use(
  (config) => {
    try {
      const token = Cookies.get("jwtToken");
      if (token) {
        config.headers.common["x-access-tokens"] = token;
      }
    } catch (error) {
      console.log(error);
    }

    if (config.url !== "/table/update") {
      store.commit("loading", true);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const vm = new Vue();

    if (response.status === 200 || response.status === 201) {
      setTimeout(() => {
        store.commit("loading", false);

        if (response.data.message) {
          vm.$bvToast.toast(response.data.message, {
            title: "Success",
            variant: "success",
            solid: true,
            autoHideDelay: 2000,
          });
        }
        return Promise.resolve(response);
      }, 1000);
      return Promise.resolve(response);
    } else {
      store.commit("loading", false);
      return Promise.reject(response);
    }
  },
  (error) => {
    store.commit("loading", false);
    const vm = new Vue();
    const { config, data } = error.response;

    if (data && !config.url.includes("/whatsapp/token/validate")) {
      vm.$bvToast.toast(error.response.data.error, {
        title: "Error",
        variant: "danger",
        solid: true,
      });
      switch (error.response.status) {
        case 400:
          break;

        case 401:
          Cookies.remove("jwtToken");
          Cookies.remove("user_type");
          setTimeout(() => {
            router.push({
              path: "/",
            });
          }, 1000);
          break;
        // case 403:
        //   router.replace({
        //     path: "/login",
        //     query: { redirect: router.currentRoute.fullPath },
        //   });
        //   break;
        case 404:
          alert("page not exist");
          break;
        //   case 502:
        //     setTimeout(() => {
        //       router.replace({
        //         path: "/login",
        //         query: {
        //           redirect: router.currentRoute.fullPath,
        //         },
        //       });
        //     }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);

export default api;
