<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" md="9" lg="7" class="custom-col mx-auto text-center">
          <div class="paymenttext">Payment Settings</div>
          <div class="plantext" style="display: flex">Plan</div>
          <input
            type="text"
            class="form-control textbox mr-1"
            v-model="textboxplan"
            placeholder="-"
            readonly
          />
          <div class="startdatetext mr-1" style="display: flex">Start Date</div>
          <input
            type="text"
            class="form-control textbox mr-1"
            v-model="textboxstartdate"
            placeholder="-"
            readonly
          />

          <div class="enddatetext mr-1" style="display: flex">End Date</div>
          <input
            type="text"
            class="form-control textbox mr-1"
            v-model="textboxenddate"
            placeholder="-"
            readonly
          />

          <div class="notestext">
            Check the plans, pricing and payment here:
          </div>
          <div style="display: flex; justify-content: center">
            <a class="paybutton" style="display: flex">Pay Here</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PaymentSettings",
  props: {},

  data: function () {
    return {
      isScreenSmall: false,
      textboxplan: null,
      textboxstartdate: null,
      textboxenddate: null,
    };
  },
  methods: {
    checkScreenSize() {
      // Update isScreenSmall based on screen width
      this.isScreenSmall = window.innerWidth < 768;
    },
  },
  computed: {},
  created() {
    // Call checkScreenSize when the component is created
    this.checkScreenSize();

    // Listen for window resize events
    window.addEventListener("resize", this.checkScreenSize);
  },

  destroyed() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener("resize", this.checkScreenSize);
  },

  mounted: function () {},
};
</script>

<style scoped>
.custom-col {
  width: 100%; /* Make the column take full width on smaller screens */
  max-width: 450px;
  border: 1px solid #ececec; /* Add a border to each column */
  border-radius: 10px; /* Add border radius for rounded corners */
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.paymenttext {
  display: flex;
  justify-content: center;
  font-size: 20px; /* Adjust the font size as needed */

  margin-top: 25px;
}

.plantext {
  display: flex;
  font-weight: bold; /* Make the text bold */
  margin-top: 10px;
}

.startdatetext {
  display: flex;
  font-weight: bold; /* Make the text bold */
  margin-top: 10px;
}

.enddatetext {
  display: flex;
  font-weight: bold; /* Make the text bold */
  margin-top: 10px;
}

.form-control {
  margin-top: 10px; /* Adjust the top margin as needed */
}

.notestext {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 15px;
  color: #0095b5;
}

.paybutton {
  padding: 5px 10px; /* Adjust padding for the desired button size */
  margin-right: 10px; /* Adjust margin between buttons */
  margin-top: 10px;
  margin-bottom: 25px;
  background-color: #0095b5; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  cursor: pointer; /* Change cursor on hover */
  width: 100%; /* Adjust the width here */
  height: 35px; /* Adjust the height here */
  justify-content: center;

  &:hover {
    background-color: #2980b9;
  }
}
</style>
