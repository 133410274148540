<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="chevron-left"
  >
    <path
      d="M6.25 1.5L1.75 6L6.25 10.5"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgChevronLeft",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
