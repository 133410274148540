import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    facebook_status: false,
    cached_leads: new Map(),
    active_menu: window.innerWidth > 1024 ? true : false,
    blast_contacts: [],
  },
  mutations: {
    loading(state, loading) {
      state.loading = loading;
    },
    setFacebookStatus(state, facebook_status) {
      state.facebook_status = facebook_status;
    },
    setCachedLeads(state, lead) {
      state.cached_leads.set(lead.data["lead_id"], lead);
    },
    setActiveMenu(state, active_menu) {
      state.active_menu = active_menu;
    },
    setBlastContacts(state, blast_contacts) {
      state.blast_contacts = blast_contacts;
    },
  },
  getters: {
    loading: (state) => {
      return state.loading;
    },
    getFacebookStatus: (state) => {
      return state.facebook_status;
    },
    getCachedLeads: (state) => {
      return state.cached_leads;
    },
    getActiveMenu: (state) => {
      return state.active_menu;
    },
    getBlastContacts: (state) => {
      return state.blast_contacts;
    },
  },
});

export default store;
