<template>
  <b-modal
    id="whatsapp_blasting_modal"
    title="Whatsapp Blasting"
    no-close-on-esc
    no-close-on-backdrop
    centered
    scrollable
    size="xl"
  >
    <div>
      <label>Account</label>
      <b-form-select
        :options="whatsapp_accounts"
        text-field="name"
        value-field="id"
        v-model="whatsapp_blast.account"
        @change="getWhatsappTemplates()"
      />
      <label>Type</label>
      <b-form-select
        :options="message_types"
        text-field="name"
        value-field="id"
        v-model="whatsapp_blast.type"
        @change="typeOnChange"
      />
      <label
        v-show="
          whatsapp_blast.account != null && whatsapp_blast.type === 'template'
        "
        >Template</label
      >
      <b-form-select
        :options="whatsapp_templates"
        text-field="name"
        value-field="id"
        v-model="whatsapp_blast.template"
        v-show="
          whatsapp_blast.account != null && whatsapp_blast.type === 'template'
        "
        @change="onChangeWhatsappTemplate"
      />
      <label v-show="whatsapp_blast.account != null">Phone Numbers</label>
      <b-form-select
        :options="whatsapp_contacts"
        text-field="name"
        value-field="id"
        v-model="whatsapp_blast.contact"
        v-show="whatsapp_blast.account != null"
      />
      <div class="d-flex justify-content-between flex-row">
        <div class="d-flex w-100 flex-column">
          <label v-show="whatsapp_blast.template != null">Header Mapping</label>

          <div
            v-if="header_variable_count > 0"
            v-show="whatsapp_blast.template != null"
            class="d-flex flex-column"
          >
            <div
              v-for="index in header_variable_count"
              :key="index"
              class="w-100 d-flex flex-row"
            >
              <label class="m-auto" v-show="whatsapp_blast.template != null"
                ><span v-pre>{{</span>{{ index }}<span v-pre>}}</span></label
              >
              <b-form-select
                :options="blasting_variables"
                text-field="text"
                value-field="value"
                v-model="header_variables[index - 1]"
                v-show="whatsapp_blast.template != null"
              />
              <b-form-input
                v-show="
                  header_variables[index - 1] === 'custom' &&
                  whatsapp_blast.template != null
                "
                v-model="header_customs[index - 1]"
              ></b-form-input>
            </div>
          </div>
          <p v-else v-show="whatsapp_blast.template != null">
            No mapping required
          </p>
        </div>

        <div class="d-flex w-100 flex-column">
          <label v-show="whatsapp_blast.template != null">Body Mapping</label>
          <div v-if="body_variable_count > 0" class="d-flex flex-column">
            <div
              v-for="index in body_variable_count"
              :key="index"
              class="w-100 d-flex flex-row"
            >
              <label class="m-auto" v-show="whatsapp_blast.template != null"
                ><span v-pre>{{</span>{{ index }}<span v-pre>}}</span></label
              >

              <b-form-select
                :options="blasting_variables"
                text-field="text"
                value-field="value"
                v-model="body_variables[index - 1]"
                v-show="whatsapp_blast.template != null"
              />
              <b-form-input
                v-show="
                  body_variables[index - 1] === 'custom' &&
                  whatsapp_blast.template != null
                "
                v-model="body_customs[index - 1]"
              ></b-form-input>
            </div>
          </div>
          <p v-else v-show="whatsapp_blast.template != null">
            No mapping required
          </p>
        </div>
      </div>

      <label v-show="whatsapp_blast.type === 'media'">File</label>
      <b-form-file
        v-show="whatsapp_blast.type === 'media'"
        accept="image/*, video/*, audio/*"
        v-model="mediaFile"
        :state="Boolean(mediaFile)"
        @input="uploadFile"
      ></b-form-file>

      <label v-show="whatsapp_blast.type === 'text'">Content</label>
      <ElementTiptap
        lang="en"
        :extensions="extensions"
        placeholder="Write something..."
        :tooltip="false"
        v-model="whatsapp_blast.content"
        v-show="whatsapp_blast.type == 'text'"
      ></ElementTiptap>

      <label v-show="whatsapp_blast.template != null">Preview</label>
      <ElementTiptap
        lang="en"
        :extensions="extensions"
        placeholder="Write something..."
        :tooltip="false"
        :readonly="true"
        :content="
          header_content + '<br>' + body_content + '<br>' + footer_content
        "
        :showMenubar="false"
        v-show="whatsapp_blast.template != null"
      ></ElementTiptap>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex flex-row justify-content-between">
        <span class="my-auto">{{ target_num.length }} leads selected</span>
        <div>
          <b-button
            variant="secondary"
            size="sm"
            @click="hideModal"
            class="m-1"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="blastMessage"
            :disabled="sending || whatsapp_blast.type === 'media'"
          >
            <span v-show="!sending">Send</span>
            <Spinner :show="sending"></Spinner>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  fetchWhatsappTemplates,
  fetchWhatsappContacts,
  blastMessage,
  uploadWhatsappMedia,
} from "@/utils/ajax";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags
import Spinner from "../Spinner.vue";
import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  CodeView,
  Preview,
} from "element-tiptap";
export default {
  name: "WhatsappBlastModal",
  props: {
    whatsapp_accounts: Array,
    target_name: Array,
    target_num: Array,
  },
  components: {
    ElementTiptap,
    Spinner,
  },
  data: function () {
    return {
      whatsapp_templates: [
        {
          name: "Please select an template",
          id: null,
          disabled: "true",
        },
      ],
      whatsapp_contacts: [
        {
          name: "Please select a number",
          id: null,
          disabled: "true",
        },
      ],
      message_types: [
        { name: "Please select an option", id: null, disabled: true },
        { name: "Template", id: "template" },
        { name: "Text", id: "text" },
        { name: "Media", id: "media" },
      ],
      whatsapp_blast: {
        account: null,
        template: null,
        contact: null,
        type: null,
        content: "",
      },
      header_variable_count: 0,
      body_variable_count: 0,
      header_variables: [],
      body_variables: [],
      blasting_variables: [
        { text: "Please select an option", value: null, disabled: true },
        { text: "Name", value: "name" },
        { text: "Custom", value: "custom" },
      ],
      header_content: "",
      body_content: "",
      footer_content: "",
      sending: false,
      mediaFile: null,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold(),
        new Underline(),
        new Italic(),
        new Strike(),
        new Link(),
        new Image(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule(),
        new Preview(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
        new History(),
      ],
    };
  },
  methods: {
    getWhatsappTemplates: function () {
      const { whatsapp_blast } = this;

      fetchWhatsappTemplates(whatsapp_blast.account)
        .then((data) => {
          this.whatsapp_templates = data;

          // reset the form
          this.whatsapp_blast["type"] = null;
          this.whatsapp_blast["template"] = null;
          this.whatsapp_blast["contact"] = null;
        })
        .catch((error) => {
          console.error(error);
        });

      fetchWhatsappContacts(whatsapp_blast.account)
        .then((data) => {
          this.whatsapp_contacts = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onChangeWhatsappTemplate: function () {
      const { whatsapp_templates, whatsapp_blast } = this;
      const selected_template = whatsapp_templates.filter(
        (w) => w.id == whatsapp_blast.template
      )[0];

      this.selected_template_name = selected_template.name;
      const { components } = selected_template;

      for (const c in components) {
        const text = components[c].text;

        if (components[c].type === "HEADER") {
          const header_variable_count = this.regexChecker(text);
          this.header_variable_count = header_variable_count;
          this.header_variables = Array.from(
            { length: header_variable_count },
            () => null
          );

          this.header_customs = Array.from(
            { length: header_variable_count },
            () => null
          );
        }

        if (components[c].type === "BODY") {
          const body_variable_count = this.regexChecker(text);
          this.body_variable_count = body_variable_count;
          this.body_variables = Array.from(
            { length: body_variable_count },
            () => null
          );

          this.body_customs = Array.from(
            { length: body_variable_count },
            () => null
          );
        }

        if (components[c].type === "FOOTER") {
          if (text) this.footer_content = text;
        }
      }
    },
    typeOnChange: function () {
      this.whatsapp_blast.template = null;
    },
    regexChecker: function (text) {
      if (!text) {
        return 0;
      }

      const pattern = /\{\{\d+\}\}/g;
      const matches = text.match(pattern);

      if (matches) {
        return matches.length;
      } else {
        return 0;
      }
    },
    blastMessage: function () {
      let {
        whatsapp_blast,
        header_variables,
        body_variables,
        footer_content,
        header_customs,
        body_customs,
        target_num,
        target_name,
      } = this;

      let _body = {};
      let _header = {};

      /**
       * "body": {
       *  "{{1}}": ["123", "456", "789"], # name - multiple names
       *  "{{2}}": ["custom string"],     # custom - single string
       * }
       */

      for (let i in body_variables) {
        if (body_variables[i] === "custom") {
          _body[`{{${parseInt(i) + 1}}}`] = [body_customs[i]];
        } else {
          _body[`{{${parseInt(i) + 1}}}`] = target_name;
        }
      }

      for (let j in header_variables) {
        if (header_variables[j] === "custom") {
          _header[`{{${parseInt(j) + 1}}}`] = [header_customs[j]];
        } else {
          _header[`{{${parseInt(j) + 1}}}`] = target_name;
        }
      }

      whatsapp_blast["template_language"] = "en";
      whatsapp_blast["template_name"] = this.selected_template_name;
      whatsapp_blast["target_num"] = target_num;
      whatsapp_blast["header"] = _header;
      whatsapp_blast["body"] = _body;
      whatsapp_blast["footer"] = footer_content;

      let validate = this.validateBlast();

      if (!validate) {
        this.$bvToast.toast("Please complete the form", {
          title: "Warning",
          autoHideDelay: 3000,
          variant: "warning",
        });
        return;
      }

      this.sending = true;
      blastMessage(whatsapp_blast)
        .then((resp) => {
          const { response } = resp.data;

          if (response.length > 0) {
            this.$store.commit("setBlastContacts", response);
            this.sending = false;
            this.$bvModal.hide("whatsapp_blasting_modal");
            this.$bvModal.show("whatsapp_blasting_result_modal");
          }
        })
        .catch((error) => {
          this.sending = false;

          console.error(error);
        });
    },
    uploadFile: function () {
      uploadWhatsappMedia(this.mediaFile, 104507009023123)
        .then((resp) => {
          const { response } = resp.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hideModal: function () {
      this.$bvModal.hide("whatsapp_blasting_modal");
    },
    validateBlast() {
      let has_value = false;
      let { whatsapp_blast, target_num } = this;

      const exclusion = ["template", "content"];
      const mandatory = ["type", "account", "contact"];

      // no lead selected
      if (target_num.length === 0) {
        return false;
      }

      for (const j in exclusion) {
        if (whatsapp_blast[exclusion[j]]) {
          has_value = true;
          break;
        }
      }

      for (const k in mandatory) {
        if (!whatsapp_blast[mandatory[k]]) {
          return false;
        }
      }

      return has_value;
    },
  },
  computed: {},
  created() {},
  mounted: function () {},
};
</script>
