<template>
  <div class="mt-3">
    <div v-for="(lead, index) in selectedLeads" :key="index">
      <div>
        <!-- For small screens (3 rows) -->
        <b-container class="container" v-if="showTable">
          <b-row v-if="isScreenSmall" cols="2">
            <!-- 1st row -->
            <b-col class="custom-col" cols="6">
              <!-- Content for the first column in the first row -->
              <div class="boxnameposition">
                <button
                  @click="closeContainer"
                  class="close-button ml-auto"
                  v-if="showTable"
                >
                  ×
                </button>

                <div class="name">{{ lead.name }}</div>
              </div>

              <div class="icon-container">
                <div style="margin-left: 17px">
                  <a :href="'https://wa.me/' + lead.phone" target="_blank">
                    <SvgWhatsapp />
                  </a>
                </div>
                <a :href="'tel:' + lead.phone">
                  <SvgPhone />
                </a>
                <a :href="'mailto:' + lead.email">
                  <SvgEmail />
                </a>
              </div>
            </b-col>

            <b-col class="custom-col" cols="6">
              <div class="phone mr-5">{{ lead.phone }}</div>
              <div class="email mr-5">{{ lead.email }}</div>
            </b-col>

            <!-- 2nd row -->
            <b-col class="custom-col" cols="6">
              <!-- Content for the first column in the second row -->
              <div style="margin-top: 3px">
                Source: {{ lead.lead_source_id }}
              </div>
              <!--<div>
                <div style="margin-right: 10px">Sales:</div>
                <div class="input-container" style="margin-bottom: 5px">
                  <span class="input-symbol">$</span>
                  <input
                    type="text"
                    class="form-control sales-input"
                    v-model="salestextbox"
                    placeholder="Enter text"
                    dir="rtl"
                  />
                </div>
              </div>-->
            </b-col>

            <b-col class="custom-col" cols="6">
              <!-- Content for the second column in the second row -->
              <div
                class="fieldposition"
                v-if="filteredCustomColumns.length > 0"
                :key="`textboxposition` + 0"
              >
                <b-form-select
                  v-model="lead[filteredCustomColumns[0].key]"
                  :options="filteredCustomColumns[0].option"
                  text-field="data_value"
                  value-field="data_value"
                  size="sm"
                  class="status-dropdown mr-1"
                  style="width: 100%"
                ></b-form-select>
              </div>

              <!--<div>
                <div style="margin-right: 10px">Next Action:</div>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  placeholder="dd/mm/yy"
                  class="datepicker datepicker-input"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                ></b-form-datepicker>
              </div>-->
            </b-col>

            <!-- 3rd row -->
            <b-col class="custom-col" cols="12">
              <!-- Content for the third column -->
              <div
                class="textboxposition"
                v-for="(custom, index) in filteredCustomColumnsTextBox"
                :key="textboxposition + index"
              >
                <div class="textfield mr-1">{{ custom.key }}</div>
                <textarea
                  class="form-control custom-input mr-1 auto-expand"
                  v-model="lead[custom.key]"
                  placeholder="Enter text"
                  rows="1"
                ></textarea>

                <!-- <textarea
                  class="form-control custom-input mr-1"
                  v-model="custom.value"
                  placeholder="Enter text"
                  rows="1"
                ></textarea>-->
              </div>
            </b-col>

            <b-col class="custom-col" cols="12">
              <!-- Display elements starting from index 1 -->
              <div
                class="fieldposition"
                v-for="(custom, index) in filteredCustomColumns.slice(1)"
                :key="`textboxposition` + index"
              >
                <div class="textfield">{{ custom.key }}:</div>
                <b-form-select
                  v-model="lead[custom.key]"
                  :options="custom.option"
                  text-field="data_value"
                  value-field="data_value"
                  size="sm"
                  class="custom-dropdown mr-1"
                ></b-form-select>
              </div>

              <a class="savebutton mt-2">Distribution Details</a>
              <div style="display: flex">
                <a
                  class="savebutton mt-2"
                  href="javascript:void(0)"
                  @click="saveDetails"
                  >Save</a
                >

                <div class="copybutton">
                  <a
                    class="action-copy custom-button3 mr-7"
                    @click="copyLeadInformation(lead)"
                  >
                    <SvgCopy width="15px" height="15px" />
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- For larger screens -->
          <b-row v-else>
            <!-- 1st row -->
            <!-- 1st column in left side -->
            <b-col class="custom-col" cols="3">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: space-between;
                "
              >
                <!-- <button @click="closeContainer" class="close-button mr-2"  v-if="showTable">
                    ×
                  </button>-->
                <a class="name" @click="closeContainer" v-if="showTable">{{
                  lead.name
                }}</a>
              </div>

              <div class="icon-container">
                <div style="margin-left: 5px">
                  <a :href="'https://wa.me/' + lead.phone" target="_blank">
                    <SvgWhatsapp style="width: 22px; height: 22px" />
                  </a>
                </div>
                <a :href="'tel:' + lead.phone">
                  <SvgPhone style="width: 22px; height: 22px" />
                </a>
                <a :href="'mailto:' + lead.email">
                  <SvgEmail style="width: 22px; height: 22px" />
                </a>
              </div>
            </b-col>

            <!-- 1nd Column in the right side -->
            <b-col class="section" cols="3">
              <div style="margin-bottom: 11px; display: flex">
                <div class="phone mr-5">{{ lead.phone }}</div>
              </div>

              <div style="display: flex">
                <div class="email mr-5">{{ lead.email }}</div>
              </div>
            </b-col>

            <b-col class="section" cols="4">
              <div style="margin-bottom: 5px; display: flex">
                <div
                  class="fieldposition"
                  v-if="filteredCustomColumns.length > 0"
                  :key="`textboxposition` + 0"
                >
                  <div class="textfield">
                    {{ filteredCustomColumns[0].key }}:
                  </div>
                  <b-form-select
                    v-model="lead[filteredCustomColumns[0].key]"
                    :options="filteredCustomColumns[0].option"
                    text-field="data_value"
                    value-field="data_value"
                    size="sm"
                    class="custom-dropdown mr-1"
                    style="width: 100%"
                  ></b-form-select>
                </div>
              </div>

              <!--<div class="dateposition">
                <div class="textfield">Next Action:</div>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  placeholder="dd/mm/yy"
                  class="datepicker datepicker-input"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                ></b-form-datepicker>
              </div>-->
            </b-col>

            <b-col class="lastsection" cols="2">
              <div style="margin-bottom: 5px; display: flex">
                <a
                  class="action-copy custom-button3 ml-auto"
                  @click="copyLeadInformation(lead)"
                >
                  <SvgCopy />
                </a>
              </div>
            </b-col>

            <!-- 2nd Column in the left side -->
            <b-col class="custom-col" cols="3">
              <div class="part">
                <div class="source">Source: {{ lead.lead_source_id }}</div>
                <!--<div class="d-flex">
                  <div class="sale mr-2">Sales:</div>
                  <div class="salesboxposition">
                    <span class="input-symbol">$</span>
                    <input
                      type="text"
                      class="form-control sales-input"
                      v-model="salestextbox"
                      placeholder="Enter text"
                      dir="rtl"
                      style="width: 120%"
                    />
                  </div>
                </div>-->
                <div style="display: flex">
                  Action Date: {{ lead.action_time }}
                </div>
                <div style="display: flex">
                  Date Added: {{ lead.date_added }}
                </div>
                <div style="display: flex">
                  Last Distribution: {{ lead.last_distributed_dt }}
                </div>
                <div class="distributionposition">
                  Last Distributed to: {{ lead.last_distributed }}
                </div>
                <div class="action-wrapper">
                  <!-- Inside the template section of LeadsDetails.vue -->
                  <a class="savebutton" @click="openLeadDetails(lead)"
                    >Distribution Details</a
                  >

                  <a
                    class="savebutton"
                    href="javascript:void(0)"
                    @click="saveDetails"
                    >Save</a
                  >
                </div>
              </div>
            </b-col>

            <!--2nd Column in the left side-->

            <b-col
              class="section d-flex flex-wrap"
              :class="{
                'flex-column': filteredCustomColumnsTextBox.length < 8,
              }"
            >
              <!-- Display elements starting from index 5 -->
              <div
                class="fieldposition"
                v-for="(custom, index) in filteredCustomColumnsTextBox.slice(4)"
                :key="`textboxposition` + index"
              >
                <div class="textfield mr-3">{{ custom.key }}:</div>
                <textarea
                  class="form-control textbox mr-1 auto-expand"
                  v-model="lead[custom.key]"
                  placeholder="Enter text"
                  rows="1"
                ></textarea>
              </div>
            </b-col>

            <b-col
              class="section custom-section d-flex flex-wrap"
              :class="{ 'flex-column': filteredCustomColumns.length < 8 }"
            >
              <!-- Display elements starting from index 1 -->
              <div
                class="fieldposition"
                v-for="(custom, index) in filteredCustomColumns.slice(1)"
                :key="`textboxposition` + index"
              >
                <div class="textfield">{{ custom.key }}:</div>
                <b-form-select
                  v-model="lead[custom.key]"
                  :options="custom.option"
                  text-field="data_value"
                  value-field="data_value"
                  size="sm"
                  class="custom-dropdown mr-1"
                ></b-form-select>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import SvgWhatsapp from "@/components/images/SvgWhatsapp.vue";
import SvgPhone from "@/components/images/SvgPhone.vue";
import SvgEmail from "@/components/images/SvgEmail.vue";
import SvgCopy from "@/components/images/SvgCopy.vue";
import axios from "@/utils/api";

export default {
  name: "LeadsDetails",
  components: {
    SvgWhatsapp,
    SvgPhone,
    SvgEmail,
    SvgCopy,
  },
  data() {
    return {
      name: "",
      selectedDate: null,
      formattedDate: "",
      action: null, // The selected date will be stored here
      selectedLead: null,
      dropdown1: null,
      dropdown2: null,
      displayText: "Your text here",
      selectedCategory: null,
      selectedStatus: null,
      field1: null,
      field3: null,
      field2: null,
      salestextbox: null,
      statusOptions: [],
      isScreenSmall: false,
      showTable: true,
    };
  },

  props: {
    selectedLeads: {
      type: Array,
      required: true,
      default: () => [],
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
  },

  mounted: function () {
    window.addEventListener("resize", this.checkScreenSize);
    // Check screen size initially
    this.checkScreenSize();
    this.autoExpandTextarea();
    this.logCustomKeyInfo();

    console.log(this.customColumns);
  },

  updated() {
    this.autoExpandTextarea();
  },

  created() {
    // Listen for the 'update_selected_leads' event from the parent component (Leads.vue)
    this.$root.$on("update_selected_leads", this.handleAddSelectedLeads);

    this.$root.$on("update_show_table", this.handleUpdateShowTable);

    // Listen for the 'revert_grid_height' event to revert the grid height
    this.$root.$on("revert_grid_height", this.revertGridHeight);

    // Listen for the 'close_container_in_leads_details' event
    this.$root.$on("close_container_in_leads_details", this.closeContainer);
  },

  beforeDestroy() {
    // Make sure to remove the event listener when the component is destroyed
    this.$root.$off("update_selected_leads", this.handleAddSelectedLeads);
    this.$root.$off("close_container_in_leads_details", this.closeContainer);
  },

  computed: {
    // Check if any lead is selected
    isLeadSelected() {
      return !!this.selectedLead;
    },

    filteredCustomColumns() {
      // Filter the customColumns array to exclude elements with an empty 'option' array
      return this.customColumns.filter((custom) => custom.option.length > 0);
    },

    filteredCustomColumnsTextBox() {
      // Filter the customColumns array to include elements with an empty 'option' array
      return this.customColumns.filter((custom) => custom.option.length === 0);
    },
  },

  watch: {
    selectedLeads: {
      handler(newSelectedLeads) {
        // Update the selectedLead data based on the first lead in the selectedLeads array
        if (newSelectedLeads.length > 0) {
          this.selectedLead = newSelectedLeads[0];
        } else {
          // If no lead is selected, reset the selectedLead data
          this.selectedLead = null;
        }
      },
      immediate: true, // Call the handler immediately to set the initial selectedLead
    },

    // "lead[custom.key]": {
    //   handler() {
    //     // Ensure the textarea expands automatically
    //     this.$nextTick(() => {
    //       this.autoExpandTextarea();
    //     });
    //   },
    //   deep: true,
    // },
  },

  methods: {
    saveDetails: function () {
      const { selectedLeads } = this;

      axios({
        method: "post",
        url: "/table/update",
        data: selectedLeads[0],
      })
        .then((res) => {
          if (res === undefined) {
            this.$store.commit("setCachedLeads", selectedLeads[0]);
          }
        })
        .catch(() => {
          this.$store.commit("setCachedLeads", selectedLeads[0]);
        });
    },
    confirmAction() {
      // Implement your confirmAction logic here
      console.log("confirmAction method called");
    },

    openLeadDetails(lead) {
      // Assuming this.selectedLead contains the lead details you want to view
      this.$root.$emit("view_lead_details", lead);
    },

    handleUpdateShowTable(value) {
      // Update the showTable status based on the received value
      this.showTable = value;
    },

    logCustomKeyInfo() {
      this.filteredCustomColumns.forEach((custom, index) => {
        console.log(`Custom Key ${index + 1}:`, custom.key);
      });
    },

    checkScreenSize() {
      // Update isScreenSmall based on screen width
      this.isScreenSmall = window.innerWidth < 768;
    },

    updateFormattedDate() {
      if (this.selectedDate) {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        this.formattedDate = this.selectedDate.toLocaleDateString(
          "en-GB",
          options
        );
      } else {
        this.formattedDate = "";
      }
    },

    closeContainer() {
      this.showTable = false;
      this.$emit("close_container");
    },

    copyLeadInformation(lead) {
      let test = "";
      for (let i = 5; i < this.customColumns.length; i++) {
        test +=
          this.customColumns[i].key +
          ": " +
          (lead[this.customColumns[i].key] ?? null) +
          "\n";
      }

      console.log(test);
      console.log(lead);

      const leadInfo = `Name: ${lead.name}\nEmail: ${lead.email}\nPhone: ${lead.phone}\nSource: ${lead.lead_source_id}
Status:${lead.status}\n${test}`;

      // Create a temporary textarea to copy the text to the clipboard
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = leadInfo;
      tempTextArea.style.position = "fixed"; // Ensure it's not visible on the screen
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);

      // Optionally, show a notification or perform any other actions after copying
      alert("Lead information copied to clipboard!");
    },

    autoExpandTextarea() {
      const textareas = this.$el.querySelectorAll(".auto-expand");
      textareas.forEach((textarea) => {
        textarea.style.height = "auto"; // Reset the height to auto to get the correct scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the scrollHeight
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

* {
  font-size: 14px;
}

.container {
  width: 100vw;
  max-width: 100%;
  margin-bottom: 10px;
}

.form-control {
  margin-right: 1px;
  padding: 3px 10px;
}

.custom-dropdown {
  padding: 3px 10px;
  width: 100%; /* Adjust the width as needed */
  max-height: 26px; /* Adjust the max height as needed */
}

.status-dropdown {
  padding: 3px 10px;
  width: 280px; /* Adjust the width as needed */
  max-height: 26px; /* Adjust the max height as needed */
}

.custom-col {
  border: 1px solid #ccc; /* Add a border to each column */
  padding: 7px; /* Add padding for spacing */
  border-radius: 2px; /* Add border radius for rounded corners */
}

.section {
  border-top: 1px solid #ccc; /* Add a top border */
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  padding: 7px; /* Add padding for spacing */
  border-radius: 0px; /* Add border radius for rounded corners (if needed) */
}

.name {
  text-decoration: underline;
  color: #155eef; // Change this to your desired blue color
  margin-left: 5px;
}

.custom-section {
  border-right: 1px solid #ccc;
}

.lastsection {
  border-top: 1px solid #ccc; /* Add a top border */
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  border-right: 1px solid #ccc; /* Add a bottom border */
  padding: 7px; /* Add padding for spacing */
  border-radius: 0px; /* Add border radius for rounded corners (if needed) */
}

.salesboxposition {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.distributionposition {
  margin-bottom: 5px;
  display: flex;
}

.textbox {
  width: 100%; /* Adjust the width as needed */
  // height: 26px; /* Adjust the height as needed */
  border-radius: 5px;
  overflow: hidden; /* Hide the scrollbar */

  textarea {
    border: none; /* Remove textarea border */
    width: 100%; /* Inherit the width from the parent */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    background: transparent; /* Make the background transparent */
    font-size: 12px; /* Adjust the font size as needed */
  }
}

.auto-expand {
  min-height: 30px; /* Set a minimum height to prevent the textarea from being too small */
  overflow: hidden; /* Hide the scrollbar */

  /* Ensure the textarea expands vertically as needed */
  transition: height 0.2s;
}

.fieldposition {
  margin-bottom: 20px;
  display: flex;
  white-space: nowrap;
}

.dateposition {
  display: flex;
  white-space: nowrap;
}

.action-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.savebutton {
  padding: 3px 10px; /* Adjust padding for the desired button size */
  margin-right: 10px; /* Adjust margin between buttons */
  background-color: #53c58a; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  width: 100%; /* Adjust the width here */
  height: 25px; /* Adjust the height here */
  cursor: pointer; /* Change cursor on hover */
  text-align: center; /* Center the text within the button */

  &:hover {
    background-color: #26583e; /* Adjust hover background color */
  }
}

.distributionbutton {
  padding: 3px 10px; /* Adjust padding for the desired button size */
  margin-right: 10px; /* Adjust margin between buttons */
  background-color: #53c58a; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  width: 100%; /* Adjust the width here */
  height: 25px; /* Adjust the height here */
  cursor: pointer; /* Change cursor on hover */
  text-align: center; /* Center the text within the button */
  cursor: none;
  pointer-events: none;
  opacity: 0.5;

  &:hover {
    background-color: #26583e; /* Adjust hover background color */
  }
}
.buttonclose {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.close-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  margin: -6px -6px; /* Adjust the margin values (vertical, horizontal) in pixels */
  /* Other styles for the close button */

  &:hover {
    color: red; /* Change color on hover if needed */
    outline: none; /* Remove the button outline on hover */
  }
}

.action-copy {
  /* Adjust padding for the desired button size */
  margin-right: 10px; /* Adjust margin between buttons */
  background-color: #fff; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  cursor: pointer; /* Change cursor on hover */
  width: 60px; /* Adjust the width here */
  height: 30px; /* Adjust the height here */
  text-align: center; /* Center the text within the button */
}

.custom-button3 {
  padding: 4px 10px; /* Adjust padding for the desired button size */
  border: 2px solid #818181;
  background-color: #fff; /* Button background color */
  color: #fff; /* Button text color */
  border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
  cursor: pointer; /* Change cursor on hover */

  &:hover {
    background-color: #0327438e; /* Adjust hover background color */
  }
}

.sales-input {
  width: 150px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  display: flex;
}

.input-container {
  position: relative;
}

.input-symbol {
  position: absolute;
  left: 10px; /* Adjust the left position as needed */
  top: 50%; /* Adjust the vertical position as needed */
  transform: translateY(-50%);
}

.icon-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.icon-container a {
  margin-right: 6px; /* Adjust the margin between icons */
}

.datepicker-input {
  margin-left: 10px; /* Adjust the horizontal position of the placeholder text as needed */
  width: 205px;
  height: 26px; /* Adjust the height as needed */
  font-size: 11px; /* Adjust the font size */
  padding: 0px 3px;
}

.textfield {
  margin: auto 0;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens */

  .boxnameposition {
    display: flex;
    align-items: center;
  }

  .copybutton {
    margin-left: 5px;
    margin-top: 10px;
  }

  .textboxposition {
    display: flex;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .custom-col {
    width: 100%;
    margin-bottom: 1px;
  }

  .sales-input {
    width: 100%; /* Adjust the width as needed */
    height: 25px; /* Adjust the height as needed */
  }

  .custom-input {
    width: 100%; /* Adjust the width as needed */
    height: 27px; /* Adjust the height as needed */
  }

  .custom-dropdown {
    width: 100%; /* Adjust the width of the dropdowns */
  }

  .status-dropdown {
    width: 100%; /* Adjust the width as needed */
  }

  .distributionbutton {
    padding: 7px 10px; /* Adjust padding for the desired button size */
    margin-right: 5px; /* Adjust margin between buttons */
    background-color: #53c58a; /* Button background color */
    color: #fff; /* Button text color */
    border: none; /* Remove button border */
    border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
    cursor: pointer; /* Change cursor on hover */
    width: 210px; /* Adjust the width here */
    height: 35px; /* Adjust the height here */
    font-size: 13px;

    &:hover {
      background-color: #26583e; /* Adjust hover background color */
    }
  }

  .datepicker-input {
    margin-left: 0px;
    width: 100%;
    height: 25px; /* Adjust the height as needed */
  }

  .action-copy {
    margin-right: 5px; /* Adjust margin between buttons */
    background-color: #fff; /* Button background color */
    color: #fff; /* Button text color */
    border: none; /* Remove button border */
    border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
    cursor: pointer; /* Change cursor on hover */
    width: 100%; /* Adjust the width here */
    height: 25px; /* Adjust the height here */
    text-align: center; /* Center the text within the button */

    &:hover {
      background-color: #fff;
    }
  }

  .custom-button3 {
    padding: 1px 65px; /* Adjust padding for the desired button size */
    margin-right: 5px; /* Adjust margin between buttons */
    border: 2px solid #818181;
    background-color: #fff; /* Button background color */
    color: #fff; /* Button text color */
    border-radius: 50px; /* Make border-radius half of the height to achieve a cylindrical look */
    cursor: pointer; /* Change cursor on hover */

    &:hover {
      background-color: #cfd4d2; /* Adjust hover background color */
    }
  }

  .textfield {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .buttonclose {
    display: flex;
    flex-direction: column;
  }

  .close-button {
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;

    &:hover {
      color: red; /* Change color on hover if needed */
      outline: none; /* Remove the button outline on hover */
    }
  }
}
</style>
