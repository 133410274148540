<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 10px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      "
    >
      <div
        @click="changeTab('memberlist')"
        class="tab"
        :style="{
          backgroundColor: activeTab === 'memberlist' ? '#0095B5' : 'white',
        }"
      >
        <a :style="{ color: activeTab === 'memberlist' ? 'white' : 'black' }"
          >Member List</a
        >
      </div>

      <div
        @click="changeTab('pendingapproval')"
        class="tab"
        :style="{
          backgroundColor:
            activeTab === 'pendingapproval' ? '#0095B5' : 'white',
        }"
      >
        <a
          :style="{
            color: activeTab === 'pendingapproval' ? 'white' : 'black',
          }"
          >Pending Approval</a
        >
      </div>
    </div>

    <div v-if="activeTab === 'memberlist'">
      <vue-good-table
        :columns="approved_columns"
        :rows="approved"
        :select-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        class="request_table"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <b-button
              class="btn-sm"
              variant="outline-primary"
              @click="viewLeads(props.formattedRow.id)"
              >View</b-button
            >
            &nbsp;
            <b-button
              class="btn-sm"
              variant="outline-success"
              @click="promoteUser(props.formattedRow.id)"
              v-if="props.formattedRow.user_type == 'agent' && isMaster"
              >Promote</b-button
            >
            <b-button
              class="btn-sm"
              variant="outline-danger"
              @click="demoteUser(props.formattedRow.id)"
              v-if="props.formattedRow.user_type == 'admin' && isMaster"
              >Demote</b-button
            >
          </span>
        </template>
      </vue-good-table>
      <b-modal
        ref="agent-leads-modal"
        title="Agent Leads"
        centered
        scrollable
        size="xl"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div class="agent-leads-modal-details">
          <span>Total Leads: {{ readonly_lead_count }}</span>
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0 filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <b-table
          class="member_table"
          striped
          hover
          :items="readonly_rows"
          :fields="readonly_columns"
          :filter="filter"
        ></b-table>
      </b-modal>
    </div>

    <div v-if="activeTab === 'pendingapproval'">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :select-options="{ enabled: true }"
        :search-options="{ enabled: true }"
        class="request_table"
        @on-selected-rows-change="selectionChanged"
      >
        <div slot="table-actions">
          <b-button class="btn-sm" variant="outline-dark" @click="setApprove"
            >Approve</b-button
          >
          &nbsp;
          <b-button class="btn-sm" variant="outline-dark" @click="setReject"
            >Reject</b-button
          >
        </div>
      </vue-good-table>
    </div>

    <!-- Other components go here -->
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import axios from "../utils/api";
import Cookies from "js-cookie";

export default {
  name: "Request",
  components: { VueGoodTable },
  data() {
    return {
      requests: [],
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Contact No.",
          field: "contactNo",
        },
      ],
      approved_columns: [
        {
          label: "User ID",
          field: "id",
          hidden: true,
        },
        {
          label: "First Name",
          field: "first_name",
        },
        {
          label: "Last Name",
          field: "last_name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Contact No.",
          field: "phone",
        },
        {
          label: "Type",
          field: "user_type",
          hidden: true,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      approved: [],
      isMaster: false,
      readonly_rows: [],
      readonly_columns: [],
      readonly_lead_count: 0,
      filter: null,
      activeTab: "memberlist",
    };
  },
  methods: {
    setApprove() {
      axios({
        method: "post",
        url: "/user/approve",
        data: { requests: this.requests },
      }).then(() => {
        var approvedIds = [];
        this.requests.forEach((r) => {
          approvedIds.push(r.id);
        });

        this.rows = this.rows.filter((r) => !approvedIds.includes(r.id));
      });
    },
    setReject() {
      axios({
        method: "post",
        url: "/user/reject",
        data: { requests: this.requests },
      }).then(() => {
        var rejectedIds = [];
        this.requests.forEach((r) => {
          rejectedIds.push(r.id);
        });

        this.rows = this.rows.filter((r) => !rejectedIds.includes(r.id));
      });
    },
    selectionChanged(params) {
      this.requests = params.selectedRows;
    },
    viewLeads(id) {
      axios({
        method: "get",
        url: "/table/list?agent_id=" + id,
      }).then((response) => {
        if (response.status === 200) {
          const { headers, leads } = response.data;

          this.readonly_rows = [];
          this.readonly_rows = leads;
          this.readonly_lead_count = this.readonly_rows.length;

          headers.forEach((h) => {
            if (h.key === "lead_source_id") h.key = "lead_source_name";

            this.readonly_columns.push({
              key: h.key,
              label: h.value,
              sortable: true,
            });
          });

          this.$refs["agent-leads-modal"].show();
        }
      });
    },
    promoteUser(id) {
      axios({
        method: "post",
        url: "/user/promote",
        data: { user_id: id },
      }).then((response) => {
        if (response.status == 200) {
          this.approved = this.approved.map((obj) =>
            obj.id === id ? { ...obj, user_type: "admin" } : obj
          );
        }
      });
    },
    demoteUser(id) {
      axios({
        method: "post",
        url: "/user/demote",
        data: { user_id: id },
      }).then((response) => {
        if (response.status == 200) {
          this.approved = this.approved.map((obj) =>
            obj.id === id ? { ...obj, user_type: "agent" } : obj
          );
        }
      });
    },

    changeTab(tab) {
      this.activeTab = tab;
    },
  },

  mounted: function () {
    axios({
      method: "get",
      url: "/user/list",
      data: {},
    }).then((response) => {
      this.rows = response.data;
    });

    axios({
      method: "get",
      url: "/distribution/agent_list",
      data: {},
    }).then((response) => {
      this.approved = response.data.agents;
    });

    this.isMaster = Cookies.get("user_type") === "master";
  },
};
</script>

<style scoped lang="scss">
.request_table {
  margin: 10px;
  margin-bottom: 30px;
}

.member_table {
  font-size: 12px;
}

.agent-leads-modal-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filter {
    flex: 2;
  }
  span {
    font-size: 12px;
  }
}

.tab {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
</style>
