<template>
  <div class="d-flex">
    <a :href="'https://wa.me/' + phone" target="_blank" class="whatsapp">
      <SvgWhatsapp></SvgWhatsapp>
    </a>
    <a :href="'tel:' + phone" target="_blank" class="phone">
      <SvgPhone></SvgPhone>
    </a>
    <a @click="viewDetails" v-if="user_type !== 'agent'"><SvgInfo></SvgInfo></a>
  </div>
</template>

<script>
import SvgWhatsapp from "../images/SvgWhatsapp.vue";
import SvgPhone from "../images/SvgPhone.vue";
import SvgInfo from "./../images/SvgInfo";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      phone: "",
      user_type: "",
    };
  },
  methods: {
    viewDetails: function () {
      this.$root.$emit("view_lead_details", this.params.data);
    },
  },
  beforeMount() {
    this.user_type = Cookies.get("user_type");
    this.phone = this.params.value;
  },
  components: { SvgWhatsapp, SvgPhone, SvgInfo },
};
</script>

<style lang="scss" scoped>
.d-flex {
  gap: 10px;
  flex-direction: row;

  svg {
    width: 22px;
    height: 22px;
  }
}
</style>
