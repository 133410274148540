<template>
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="1" x2="22" y2="1" stroke="black" stroke-width="2" />
    <line x1="4" y1="8" x2="18" y2="8" stroke="black" stroke-width="2" />
    <line x1="8" y1="15" x2="14" y2="15" stroke="black" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: "SvgFilterBar",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
