<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_871_50)">
      <path
        d="M10.8334 5.83335H9.16675V9.16669H5.83342V10.8334H9.16675V14.1667H10.8334V10.8334H14.1667V9.16669H10.8334V5.83335ZM10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.675 3.33341 10C3.33341 6.32502 6.32508 3.33335 10.0001 3.33335C13.6751 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.6751 16.6667 10.0001 16.6667Z"
        fill="#2C2E32"
      />
    </g>
    <defs>
      <clipPath id="clip0_871_50">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SvgCloud",
  components: {},
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
