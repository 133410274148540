<template>
  <div class="notice">
    <b-button class="add-new" variant="primary" v-b-modal.notice-modal
      ><b-icon icon="plus"></b-icon><span>Create</span>
      <b-modal id="notice-modal" centered title="Add New Notice"
        ><b-form-textarea
          placeholder="Enter something..."
          rows="6"
          v-model="message"
        ></b-form-textarea
        ><template #modal-footer>
          <b-button
            class="btn-primary"
            @click="addNotice()"
            :disabled="message == ''"
            >Add</b-button
          >
          <b-button class="btn-plain" @click="$bvModal.hide('notice-modal')"
            >Close</b-button
          >
        </template></b-modal
      >
    </b-button>
    <b-table
      striped
      hover
      :items="notices"
      :fields="fields"
      class="notice-table"
      ref="notice-table"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'message' ? '180px' : '120px' }"
        />
      </template>
      <template #cell(actions)="n">
        <b-button
          variant="primary"
          v-b-modal="'update_' + n.item.id"
          @click="n.item.unsaved = true"
        >
          Update
        </b-button>
        <b-button class="btn-delete" @click="deleteNotice(n.item, n.index)">
          Delete
        </b-button>
        <span v-if="n.item.unsaved">***Unsaved {{ n.item.unsaved }}</span>
        <b-modal :id="'update_' + n.item.id" centered title="Update Notice"
          ><b-form-textarea rows="6" v-model="n.item.message"></b-form-textarea
          ><template #modal-footer>
            <b-button
              variant="primary"
              @click="
                $bvModal.hide('update_' + n.item.id);
                updateNotice(n.item);
                n.item.unsaved = false;
              "
              :disabled="n.item.message == ''"
              >Update</b-button
            >
            <b-button
              class="btn-plain"
              @click="$bvModal.hide('update_' + n.item.id)"
              >Close</b-button
            >
          </template></b-modal
        >
      </template>
    </b-table>

    <div class="notice--mobile" role="tablist">
      <b-card
        class="mb-1"
        v-for="(n, index) in notices"
        :key="'notice_' + index"
      >
        <b-card-body class="p-1 notice-card-body" role="tab">
          <span>{{ n.datetime }}</span>
          <h5 block v-b-toggle="'notice_' + index">{{ n.message }}</h5>
        </b-card-body>
        <b-collapse :id="'notice_' + index" role="tabpanel">
          <b-card-body>
            <div>
              <b-textarea rows="6" v-model="n.message"> </b-textarea>
              <b-button
                class="btn-delete"
                v-b-toggle="'notice_' + index"
                @click="deleteNotice(n, index)"
              >
                Delete
              </b-button>

              <div class="button-group">
                <b-button class="btn-plain" v-b-toggle="'notice_' + index">
                  Close
                </b-button>
                <b-button
                  variant="primary"
                  @click="updateNotice(n)"
                  :disabled="n.message == ''"
                >
                  Update
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/api";

export default {
  data() {
    return {
      notices: [],
      fields: [
        {
          key: "datetime",
          label: "Date Added",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "message",
          label: "Message",
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      message: "",
    };
  },
  methods: {
    addNotice: function () {
      axios
        .post("/dashboard/noticeboard", { message: this.message })
        .then((res) => {
          if (res.status === 200) this.$bvModal.hide("notice-modal");
        });

      this.getNotice();
    },
    updateNotice: function (notice) {
      axios.put("/dashboard/noticeboard?notice_id=" + notice.id, {
        message: notice.message,
      });

      this.$refs["notice-table"].refresh();
    },
    deleteNotice: function (notice, index) {
      axios.delete("/dashboard/noticeboard?notice_id=" + notice.id).then(() => {
        this.notices.splice(index, 1);
      });
    },
    getNotice: function () {
      axios.get("/dashboard/noticeboard").then((res) => {
        this.notices = res.data;

        for (var i = 0; i < this.notices.length; i++) {
          this.notices[i]["unsaved"] = false;
        }
      });
    },
  },
  mounted: function () {
    this.getNotice();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.notice {
  button {
    font-size: 12px;

    @media screen and (min-width: $desktop) {
      font-size: 14px;
    }
  }

  .add-new {
    text-align: left;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    .b-icon.bi,
    span {
      vertical-align: middle;
    }

    .add-new-form {
      display: none;
      position: absolute;
      left: 0;
      top: 50px;
      border: 1px solid $primary;
      z-index: 99;
      width: 250px;
      border-radius: 5px;
    }
  }

  .notice-table {
    display: none;

    @media screen and (min-width: $desktop) {
      display: table;

      button {
        margin-right: 5px;
      }
    }
  }

  .notice--mobile {
    @media screen and (min-width: $desktop) {
      display: none;
    }
  }
  .card {
    .card-body {
      padding: 5px;

      .notice-card-body {
        padding: 5px;
        span {
          font-size: 8px;
          font-style: italic;
        }

        h5 {
          font-size: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      textarea {
        font-size: 10px;
        margin-bottom: 5px;
      }

      .button-group {
        position: absolute;
        right: 10px;
        display: inline-block;

        .btn-plain {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
