<template>
  <div id="integration">
    <b-card class="integration--desktop" no-body>
      <b-tabs pills card vertical>
        <b-tab title="WhatsApp" @click="loadWhatsapp" :active="true" lazy>
          <b-row align-h="end">
            <b-button
              href="#"
              class="integration-signup"
              @click="loginForWhatsapp"
              variant="outline-primary"
            >
              <svg
                fill="#25D366"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="18px"
                height="18px"
              >
                <path
                  d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"
                />
              </svg>
              <span v-show="!has_whatsapp_registered"
                >Connect with WhatsApp</span
              >
              <span v-show="has_whatsapp_registered">Logged in</span>
            </b-button></b-row
          >
          <b-row class="integration-row">
            <b-col cols="4"><h3>Accounts:</h3></b-col>
            <b-col
              ><b-form-select
                :options="whatsapp_accounts"
                text-field="name"
                value-field="id"
                v-model="selected_whatsapp_account"
                @change="getWhatsappTemplates()"
            /></b-col>
          </b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_account != null"
          >
            <b-col cols="4"><h3>Template:</h3></b-col>
            <b-col
              ><b-form-select
                :options="whatsapp_templates"
                text-field="name"
                value-field="id"
                v-model="selected_whatsapp_template.id"
                @change="onChangeWhatsappTemplate()"
              />
            </b-col>
          </b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Name:</h3></b-col>
            <b-col>
              <b-input
                v-model="selected_whatsapp_template.name"
                placeholder="Template name"
              />
            </b-col>
          </b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Category:</h3></b-col>
            <b-col
              ><b-form-select
                :options="category_option"
                text-field="text"
                value-field="value"
                v-model="selected_whatsapp_template.category"
              />
            </b-col>
          </b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Language:</h3></b-col>
            <b-col
              ><b-form-select
                :options="language_option"
                text-field="text"
                value-field="value"
                v-model="selected_language"
                multiple
              />
            </b-col>
          </b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Header:</h3></b-col>
            <b-col class="d-flex position-relative">
              <b-button @click="appendVariable" class="position-absolute"
                >Variable</b-button
              >
              <ElementTiptap
                v-model="whatsapp_content_header"
                lang="en"
                :extensions="extensions"
                placeholder="Write something..."
                :tooltip="false"
              ></ElementTiptap> </b-col
          ></b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Body:</h3></b-col>
            <b-col class="d-flex position-relative">
              <b-button @click="appendVariable" class="position-absolute"
                >Variable</b-button
              >
              <ElementTiptap
                v-model="whatsapp_content_body"
                lang="en"
                :extensions="extensions"
                placeholder="Write something..."
                :tooltip="false"
              ></ElementTiptap> </b-col
          ></b-row>
          <b-row
            class="integration-row"
            v-if="selected_whatsapp_template.id != null"
          >
            <b-col cols="4"><h3>Footer:</h3></b-col>
            <b-col class="d-flex">
              <ElementTiptap
                v-model="whatsapp_content_footer"
                lang="en"
                :extensions="extensions"
                placeholder="Write something..."
                :tooltip="false"
              ></ElementTiptap> </b-col
          ></b-row>
          <b-button
            variant="outline-success"
            :disabled="selected_whatsapp_template.content == ''"
            @click="saveTemplate"
            v-if="selected_whatsapp_template.id != null"
          >
            Save
          </b-button>
        </b-tab>
      </b-tabs>
    </b-card>

    <div class="integration--mobile" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-whatsapp
            variant="primary"
            @click="loadWhatsapp"
            >WhatsApp</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-whatsapp" role="tabpanel">
          <b-card-body>
            <b-row align-h="end">
              <b-button
                href="#"
                class="integration-signup"
                @click="loginForWhatsapp"
                variant="outline-primary"
              >
                <svg
                  fill="#25D366"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="18px"
                  height="18px"
                >
                  <path
                    d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"
                  />
                </svg>
                <span v-show="!has_whatsapp_registered"
                  >Connect with WhatsApp</span
                >
                <span v-show="has_whatsapp_registered">Logged in</span>
              </b-button></b-row
            >
            <b-row>
              <h3>Accounts</h3>
              <b-form-select
                :options="whatsapp_accounts"
                text-field="name"
                value-field="id"
                v-model="selected_whatsapp_account"
                @change="getWhatsappTemplates()"
            /></b-row>

            <b-row v-if="selected_whatsapp_account != null">
              <h3>Template</h3>
              <b-form-select
                :options="whatsapp_templates"
                text-field="name"
                value-field="id"
                v-model="selected_whatsapp_template.id"
                @change="onChangeWhatsappTemplate()"
            /></b-row>

            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Name</h3>
              <b-input
                v-model="selected_whatsapp_template.name"
                placeholder="Template name"
              />
            </b-row>
            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Category:</h3>
              <b-form-select
                :options="category_option"
                text-field="text"
                value-field="value"
                v-model="selected_whatsapp_template.category"
              />
            </b-row>
            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Language:</h3>
              <b-form-select
                :options="language_option"
                text-field="text"
                value-field="value"
                v-model="selected_language"
                multiple
              />
            </b-row>
            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Header:</h3>
              <div class="position-relative">
                <b-button @click="appendVariable" class="position-absolute"
                  >Variable</b-button
                >
                <ElementTiptap
                  v-model="whatsapp_content_header"
                  lang="en"
                  :extensions="extensions"
                  placeholder="Write something..."
                  :tooltip="false"
                ></ElementTiptap>
              </div>
            </b-row>
            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Body:</h3>
              <div class="position-relative">
                <b-button @click="appendVariable" class="position-absolute"
                  >Variable</b-button
                >
                <ElementTiptap
                  v-model="whatsapp_content_body"
                  lang="en"
                  :extensions="extensions"
                  placeholder="Write something..."
                  :tooltip="false"
                ></ElementTiptap>
              </div>
            </b-row>
            <b-row v-if="selected_whatsapp_template.id != null">
              <h3>Footer:</h3>
              <div class="position-relative">
                <ElementTiptap
                  v-model="whatsapp_content_footer"
                  lang="en"
                  :extensions="extensions"
                  placeholder="Write something..."
                  :tooltip="false"
                ></ElementTiptap>
              </div>
            </b-row>

            <b-button
              variant="outline-success"
              :disabled="whatsapp_content_body == ''"
              @click="saveTemplate"
              v-if="selected_whatsapp_template.id != null"
            >
              Save
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/api";
import { fetchWhatsappAccounts, fetchWhatsappTemplates } from "@/utils/ajax";
import { setCache, validateCache } from "../utils/cache";

import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags
import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  CodeView,
  Preview,
} from "element-tiptap";

export default {
  name: "Integration",
  components: { ElementTiptap },
  props: {},
  data: function () {
    return {
      default_option: { name: "Please select an option", id: "" },
      facebook_pages: [],
      selected_page: "",
      facebook_forms: [],
      selected_form: "",
      facebook_fields: [],
      local_storage: [],
      settings: [],
      mappings: [],
      whatsapp_accounts: [],
      whatsapp_templates: [],
      selected_whatsapp_account: null,
      selected_whatsapp_template: {
        id: null,
        name: "",
        category: null,
      },
      selected_language: [],
      selected_category: "",
      selected_name: "",
      has_facebook_registered: false,
      has_whatsapp_registered: false,
      whatsapp_content_header: "",
      whatsapp_content_body: "",
      whatsapp_content_footer: "",
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Preview(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
        new History(),
      ],
      category_option: [
        { text: "Please select an option", value: null, disabled: true },
        {
          text: "Marketing (send promotions or information about your products, services or business)",
          value: "MARKETING",
        },
        {
          text: "Authentication (send messages about an existing order or account)",
          value: "AUTHENTICATION",
        },
        {
          text: "Utility (send codes to verify a transaction or login)",
          value: "UTILITY",
        },
      ],
      language_option: [
        { text: "Please select an option", value: "", disabled: "true" },
        { text: "English(US)", value: "en_US" },
        { text: "Chinese(CHN)", value: "zH_CN" },
      ],
      counter: 0,
    };
  },
  methods: {
    appendVariable: function () {
      const content = this.whatsapp_content_body;
      const { counter } = this;

      this.whatsapp_content_body = content + `{{${counter}}}`;
      this.counter = counter + 1;
    },
    onChangeWhatsappTemplate: function () {
      const { whatsapp_templates } = this;
      const selected_template = whatsapp_templates.filter(
        (w) => w.id == this.selected_whatsapp_template.id
      )[0];

      this.selected_language = this.selected_language.concat(
        selected_template.language
      );
      this.selected_whatsapp_template = selected_template;

      const components = selected_template.components;
      for (const i in components) {
        switch (components[i]["type"]) {
          case "HEADER":
            this.whatsapp_content_header = components[i]["text"];
            break;
          case "BODY":
            this.whatsapp_content_body = components[i]["text"];
            break;
          case "FOOTER":
            this.whatsapp_content_footer = components[i]["text"];
            break;
          default:
            console.log("not in list");
        }
      }
    },
    getForms: function () {
      const form = this.local_storage.filter((f) => f.id == this.selected_page);

      if (form.length > 0) {
        this.facebook_forms = form[0].data;
        return;
      } else if (this.selected_page === "") {
        this.facebook_forms = [];
        return;
      }
      axios
        .get("/facebook/forms?page_id=" + this.selected_page)
        .then((response) => {
          if (response && response.status === 200) {
            this.facebook_forms = [this.default_option];
            this.facebook_forms = this.facebook_forms.concat(
              response.data.forms
            );

            this.local_storage.push({
              id: this.selected_page,
              data: response.data.forms,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getFields: function () {
      this.facebook_fields = [];
      this.mappings = [];

      axios
        .get("/facebook/fields?form_id=" + this.selected_form)
        .then((response) => {
          if (response && response.status === 200) {
            response.data.fields.map((e) => {
              const words = e.split("_");

              for (var i = 0; i < words.length; i++) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
              }
              const word = words.join(" ");

              this.facebook_fields.push(word);

              var obj = response.data.mappings.find(
                (element) => element.field_key === e
              );

              this.mappings.push({
                facebook_key: e,
                setting_key: obj ? obj.settings_id : null,
              });
            });
            this.settings = response.data.settings;

            this.settings.unshift({
              settings_id: null,
              value: "Please select an option",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getWhatsappTemplates: function () {
      fetchWhatsappTemplates(this.selected_whatsapp_account, true)
        .then((data) => {
          this.whatsapp_templates = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveMapping: function () {
      axios.post("/facebook/" + this.selected_form + "/field_mapping", {
        mapping: this.mappings,
        page_id: this.selected_page,
      });
    },
    saveTemplate: function () {
      var {
        selected_whatsapp_account,
        selected_whatsapp_template,
        whatsapp_content_header,
        whatsapp_content_body,
        whatsapp_content_footer,
        selected_language,
      } = this;
      selected_whatsapp_template["message"] = {
        HEADER: {
          CONTENT: whatsapp_content_header,
          FORMAT: "TEXT",
        },
        BODY: {
          TEXT: whatsapp_content_body,
        },
        FOOTER: {
          TEXT: whatsapp_content_footer,
        },
      };
      selected_whatsapp_template["language"] = selected_language;
      selected_whatsapp_template["template_name"] =
        selected_whatsapp_template.name;
      selected_whatsapp_template["template_id"] = selected_whatsapp_template.id;
      selected_whatsapp_template["WABA_ID"] = selected_whatsapp_account;
      console.log(selected_whatsapp_account);
      axios.post("/whatsapp/whatsapp_template", selected_whatsapp_template);
    },

    loginWithFacebook() {
      window.FB.login(
        (response) => {
          if (response && response.authResponse) {
            if (response.status === "connected") {
              axios({
                method: "post",
                url: "/user/facebook/login",
                data: {
                  access_token: response.authResponse.accessToken,
                  user_id: response.authResponse.userID,
                },
              });

              this.$store.commit("setFacebookStatus", true);
            }
            // var userInfo = {
            //   loginType: "fb",
            //   fb: {
            //     auth: response.authResponse,
            //   },
            // };
            // this.$store.commit("setLoginUser", userInfo);
            // window.FB.api(
            //   `/${response.authResponse.userID}`,
            //   (userResponse) => {
            //     if (userResponse) {
            //       console.log(userResponse);
            //       // var userInfo = {
            //       //   loginType: "fb",
            //       //   fb: {
            //       //     auth: response.authResponse,
            //       //     user: userResponse,
            //       //   },
            //       // };
            //       // this.$store.commit("setLoginUser", userInfo);
            //     }
            //   },
            //   this.params
            // );
          }
        },
        {
          scope:
            "email, ads_management, pages_read_engagement, pages_show_list, pages_manage_metadata, leads_retrieval, pages_manage_ads",
        }
      );
    },

    loadWhatsapp: function () {
      let whatsapp_accounts_cache = localStorage.getItem("whatsapp_accounts");

      if (!whatsapp_accounts_cache) {
        axios({
          method: "GET",
          url: "/whatsapp/token/validate",
        }).then((response) => {
          if (response && response.status === 200) {
            this.has_whatsapp_registered = true;
            fetchWhatsappAccounts()
              .then((data) => {
                this.whatsapp_accounts = data;

                setCache("whatsapp_accounts", { data: data }, 24);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
      } else {
        this.has_whatsapp_registered = true;
        whatsapp_accounts_cache = JSON.parse(whatsapp_accounts_cache);
        validateCache("whatsapp_accounts", whatsapp_accounts_cache.expiry);
        this.whatsapp_accounts = whatsapp_accounts_cache.data;
      }
    },
    textChange: function () {
      console.log(this.whatsapp_content_body);
    },
    loginForWhatsapp: function () {
      // Launch Facebook login
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            if (response.status === "connected") {
              console.log(response);
              axios({
                method: "post",
                url: "/user/whatsapp/login",
                data: {
                  access_token: response.authResponse.accessToken,
                  user_id: response.authResponse.userID,
                },
              });
              console.log(this.$store);
              // this.$store.commit("setWhatsappStatus", true);
            }
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope:
            "business_management,whatsapp_business_management, whatsapp_business_messaging",
          // extras: {
          //   feature: "whatsapp_embedded_signup",
          //   setup: {
          //     // ... // Prefilled data can go here
          //   },
          // },
        }
      );
    },
  },
  mounted: function () {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "472798180817683",
        xfbml: true,
        cookie: true, // enable cookies
        version: "v18.0",
      });
      window.FB.AppEvents.logPageView();
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire.
          // var uid = response.authResponse.userID;
          // var accessToken = response.authResponse.accessToken;
          // this.facebookLoggedIn = true;
          // console.log(this);
          this.$store.commit("setFacebookStatus", true);
        } else if (response.status === "not_authorized") {
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
        }
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    let facebook_pages_cache = localStorage.getItem("facebook_pages");

    if (!facebook_pages_cache) {
      axios.get("/facebook/token/validate").then((response) => {
        if (response && response.status === 200) {
          this.has_facebook_registered = true;
          axios.get("/facebook/pages").then((response) => {
            if (response && response.status === 200) {
              this.facebook_pages = [this.default_option];
              this.facebook_pages = this.facebook_pages.concat(
                response.data.pages
              );

              setCache("facebook_pages", { data: this.facebook_pages }, 24);
            }
          });
        }
      });
    } else {
      this.has_facebook_registered = true;
      facebook_pages_cache = JSON.parse(facebook_pages_cache);
      validateCache("facebook_pages", facebook_pages_cache.expiry);
      this.facebook_pages = facebook_pages_cache.data;
    }
  },
  computed: {
    validateMapping() {
      var disabled = false;
      var selected = [];

      this.mappings.forEach((e) => {
        selected.push(e.setting_key);
        if (e.setting_key === null) {
          disabled = true;
          return;
        }
      });

      if (new Set(selected).size !== selected.length) {
        disabled = true;
      }

      return disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.integration--mobile {
  #accordion-facebook,
  #accordion-whatsapp {
    display: flex;
    .card-body {
      padding: 10px 0px;
      flex-direction: column;

      .row {
        margin: 10px;

        h3 {
          font-size: 12px;
          display: inline;
          margin: 0;
          align-self: center;
          flex: 1.5;
        }

        select,
        input {
          flex: 2;
          font-size: 12px;
        }
      }

      .facebook-fields--container {
        margin-top: 20px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.integration--desktop {
  display: none;

  .integration-row,
  .facebook-fields--container {
    align-items: center;
    margin-bottom: 10px;

    h3 {
      font-size: 18px;
    }
  }

  .facebook-fields--container {
    text-align: left;
    margin: 20px;
    margin-top: 30px;
    border-bottom: 1px solid grey;
  }

  .facebook-field {
    margin: 10px 0;
    align-items: center;
    h4 {
      font-size: 14px;
    }
  }

  .facebook-mapping {
    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 14px;
    }
  }

  .mapping-save-btn {
    margin-top: 30px;
  }

  @media screen and (min-width: $desktop) {
    display: block;
  }
}

.integration-signup {
  margin-bottom: 10px;
  margin-right: 20px;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
}

#quillWrapper {
  width: 100%;
}

.ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
}

.btn.position-absolute {
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 42px;
}

.integration-row {
  .btn.position-absolute {
    left: 15px;
  }
}
</style>
